// @flow

import React from 'react'
import ContainerGrid from './ContainerGrid'
import Appear from './Appear'
import type { Image } from 'types/'
import ImageLazy from './ImageLazy'

type Props = {
  image: Image,
  pageName?: string
}

const BigImage = ({ image, pageName }: Props) => {
  return (
    <Appear>
      <ContainerGrid
        cleanLeft={pageName === "home"}
        cleanRight={pageName === "home"}
        >
       {image && <div data-name="BigImage" className={pageName === "activities" ? "hidden md:block md:mt-48 md:mb-5" : "block"}>
          <ImageLazy
            image={image}
            alt=""
            className="w-full col-span-4 md:col-span-10"
          />
        </div>}
      </ContainerGrid>
    </Appear>
  )
}

export default BigImage

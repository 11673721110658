// @flow

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import ButtonSecondary from './ButtonSecondary'
import Svg from './Svg'
import sessionService from '../services/session.service'
import { navigate } from 'gatsby'
import HTMLText from './HTMLText'
import { createEvent } from "ics"

type Props = {
  title: string,
  date: string,
  ctaCalendar?: Object,
  ctaBookings?: Object,
  ctaCancel?: Object,
  ctaConfirmBooking?: Object,
  ctaCancelBooking?: Object,
  literals: Object,
  bookingId?: number,
  price?: string | false,
  alertCancelBooking: string,
}
const BookingInfo = ({
  title,
  date,
  ctaCalendar,
  ctaBookings,
  ctaCancel,
  ctaConfirmBooking,
  ctaCancelBooking,
  literals,
  bookingId,
  price,
  alertCancelBooking,
  }: Props) => {

  const [canCancel, setCanCancel] = useState(true)

  const getRemainingTime = () => {
    const bookingDate = moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
    const futureActivity = moment(bookingDate).isAfter()
    moment.relativeTimeThreshold('h', 24*26)
    const remainingTime = moment(bookingDate).fromNow()
    const splitRemainingTime = remainingTime.split(' ')
    if (!futureActivity) {
      setCanCancel(false)
    }
    if (['hora', 'horas'].includes(splitRemainingTime[2]) && (Number(splitRemainingTime[1]) <= 48 || splitRemainingTime[1] === "una")) {
      setCanCancel(false)
    }
    if (['minuto', 'minutos', 'segundo', 'segundos'].includes(splitRemainingTime[2])) {
      setCanCancel(false)
    }
  }

  useEffect(() => {
    getRemainingTime()
  }, [])
    
  const formatDate = () => {
    const formatted = `${moment(date).format("dddd DD")} ${literals.of} ${moment(date).format("MMMM")} ${literals.of} ${moment(date).format("YYYY")} ${literals.aLas} <strong>${moment(date).format("HH:mm")}</strong> h`
    return formatted.charAt(0).toUpperCase() + formatted.slice(1)
  }

  async function confirmBooking(bookingId, href) {
    const response = await sessionService.confirmBooking(bookingId)
    if (response){
      navigate(href)
    }
  }
  
  async function cancelBooking(bookingId, href) {
    // const response = await sessionService.cancelBooking(bookingId)
    await sessionService.cancelBooking(bookingId)
    // if (response){
      navigate(href)
    // }
  }

  function handleICSFile() {
    const fileName = "arsenal-reserva"

    function download(content) {
      const blob = new Blob([content], { type: "text/calendar;charset=utf8" })
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName)
      } else {
        const el = window.document.createElement("a")
        el.href = window.URL.createObjectURL(blob, { oneTimeOnly: true })
        el.download = fileName
        document.body.appendChild(el)
        el.click()
        document.body.removeChild(el)
        window.URL.revokeObjectURL(blob)
      }
    }

    function format(f) {
      return moment(date).format(f)
    }

    const event = {
      start: [
        format("YYYY"),
        format("MM"),
        format("DD"),
        format("HH"),
        format("mm"),
        format("ss")
      ],
      duration: { hours: 1, minutes: 0 },
      title: `Reserva en Arsenal Barcelona: ${title}`,
      description: "",
      url: "https://www.arsenalbarcelona.com/",
      geo: {lat: 41.3973658, lon: 2.1399026 },
      status: "CONFIRMED"
    }

    createEvent(event, (err, content) => {
      if (err) console.log(err)
      else download(content)
    })
  }


  function handleLink(type, cta) {
    switch (type) {
      case 'calendar':
        return <ButtonSecondary
          cta={{ label: cta.label, href: cta.href }}
          actionButton
          actionButtonOnClick={() => handleICSFile()}
          isBookingInfoCard
        />
      case 'bookings':
        return <ButtonSecondary
          cta={{ label: cta.label, href: cta.href }}
          internalLink={true}
          isBookingInfoCard
        />
      case 'cancelBooking':
        return <ButtonSecondary
          cta={{ label: cta.label, href: cta.href }}
          actionButton={true}
          actionButtonOnClick={() => {
            if (confirm(alertCancelBooking)) {
              //alert_cancel_booking
              cancelBooking(bookingId, cta.href)
            }
          }}
          isBookingInfoCard
        />
      case 'confirmBooking':
        return <ButtonSecondary
          cta={{ label: cta.label }}
          actionButton={true}
          actionButtonOnClick={() => confirmBooking(bookingId, cta.href)}
          isBookingInfoCard
        />
    }
  }

  return (
    <>
      <div className="grid grid-cols-4 gap-4 px-4 py-5 border md:p-6 lg:p-8 md:grid-cols-10 md:gap-6 lg:gap-8 text-gray border-gray">
        <div 
          key="icon-wrapper"
          className="flex justify-start col-span-1 p-2 md:items-center items-left md:justify-center"
        >
          <Svg name="calendar" color="gray" width={24} height={24} />
        </div>
        <div
          key="text-wrapper"
          className="col-span-3 col-start-2 md:col-span-4 md:col-start-2 text-normal font-secondary"
        >
          <h3 className="pb-5 font-light leading-none font-primary text-big1 md:text-big2">{title}</h3>
          <HTMLText className="leading-tight" text={formatDate()}/>
        </div>
        {price && literals.totalPrice && <div className="flex flex-col items-start justify-center col-span-3 col-start-2 md:col-span-2 md:items-center font-secondary">
          <span>{`${literals.totalPrice}:`}</span>
          <span className="text-big2">{`${price}€`}</span>
        </div>}
        {ctaCalendar && 
        <div key="button-wrapper" className="flex items-center justify-start col-span-3 col-start-2 my-2 md:my-0 md:justify-end md:col-start-8 md:col-end-11">
          {handleLink('calendar', ctaCalendar)}
        </div>
        }
        {ctaConfirmBooking && ctaCancelBooking && <div className="flex flex-col items-start justify-center col-span-3 col-start-2 md:items-end md:col-start-8">
          <div className="flex items-center justify-start col-span-3 col-start-2 my-2 md:my-0 md:mb-8 md:justify-end md:col-start-8 md:col-end-11">
            {handleLink('confirmBooking', ctaConfirmBooking)}
          </div>
          <div className="flex items-center justify-start col-span-3 col-start-2 my-2 md:my-0 md:justify-end md:col-start-8 md:col-end-11">
            {handleLink('cancelBooking', ctaCancelBooking)}
          </div>
        </div>
        }
      </div>
      <div key="button-wrapper" className={`flex flex-row items-center ${ctaBookings ? 'justify-start' : ''} ${ctaCancel ? 'justify-end' : ''} mt-4 mb-4`}>
        {ctaBookings && handleLink('bookings', ctaBookings)}
        {ctaCancel && canCancel && handleLink('cancelBooking', ctaCancel)}
      </div>
    </>
  )
}

export default BookingInfo

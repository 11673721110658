import HTTPService from './http.service'
import endpoints from '../config/endpoints'

class SessionService {
  // Users

  async doLogin(form) {
    try {
      const response = await HTTPService.post('/users/login', form)
      if (response.valid || response.ok) {
        return response.data
      }
      throw response.error
    } catch (error) {
      console.log('error', error)
    }
  }

  getSession() {
    return typeof localStorage !== 'undefined'
      ? localStorage.getItem('session')
      : null
  }

  async doSignUp(form) {
    try {
      const response = await HTTPService.post('/users', form)
      if (response.valid || response.ok) {
        return response
      }
      throw response
    } catch (error) {
      console.log('doSignUp', error.error)
      return error
    }
  }

  async recoverPassword(form) {
    try {
      const response = await HTTPService.post('/users/recover', form)
      if (response.valid || response.ok) {
        return response.data
      }
      throw response.error
    } catch (error) {
      console.log('error', error)
    }
  }

  async validateToken(token, clientId) {
    try {
      const response = await HTTPService.get(
        `${endpoints.middlewareUrl}/users/validate?token=${token}&clientId=${clientId}`,
        true
      )
      if (response.valid || response.ok) {
        return response
      }
      throw response.error
    } catch (error) {
      console.log('validateToken', error)
    }
  }

  async getUserInfo(clientId) {
    try {
      const response = await HTTPService.get(
        `${endpoints.middlewareUrl}/users?id=${clientId}`,
        true
      )
      if (response.valid || response.ok) {
        return response.data
      }
      throw response.error
    } catch (error) {
      console.log('getUserInfo', error)
    }
  }

  async updateUser(form) {
    try {
      const response = await HTTPService.patch('/users', form)
      if (response.valid || response.ok) {
        return response.valid
      }
      throw response.error
    } catch (error) {
      console.log('updateUser', error)
    }
  }

  // Services

  async servicesList() {
    try {
      const response = await HTTPService.get('/services', false)
      if (response.valid || response.ok) {
        return response.items
      }
      throw response.error
    } catch (error) {
      console.log('servicesList', error)
    }
  }

  // Booking

  async servicesAvailability(clientId, serviceId, date) {
    try {
      const response = await HTTPService.get(
        `${endpoints.middlewareUrl}/bookings/availability?date=${date}&serviceId=${serviceId}&clientId=${clientId}`,
        true
      )
      if (response.valid || response.ok) {
        return response.items
      }
      throw response.error
    } catch (error) {
      console.log('servicesAvailability', error)
    }
  }

  async newBooking(form) {
    try {
      const response = await HTTPService.post('/booking', form)
      if (response.valid || response.ok) {
        return response
      }
      throw response.error
    } catch (error) {
      console.log('newBooking', error)
    }
  }

  async confirmBooking(bookingId) {
    try {
      const response = await HTTPService.patch('/booking', { bookingId })
      if (response.valid || response.ok) {
        return response
      }
      throw response.error
    } catch (error) {
      console.log('confirmBooking', error)
    }
  }

  async cancelBooking(bookingId) {
    try {
      const response = await HTTPService.delete('/booking', { bookingId })
      if (response.valid || response.ok) {
        return response.valid
      }
      throw response.error
    } catch (error) {
      console.log('cancelBooking', error)
    }
  }

  async getUserBookings(clientId) {
    try {
      const response = await HTTPService.get(
        `${endpoints.middlewareUrl}/booking?clientId=${clientId}`,
        true
      )
      if (response.valid || response.ok) {
        return response.items
      }
      throw response.error
    } catch (error) {
      console.log('getUserBookings', error)
    }
  }

  async getUserActivities(userId, token, language) {
    const form = { userId, token, language }
    try {
      const response = await HTTPService.postFormData(
        `${process.env.GATSBY_manager_public_url}/user-activities`,
        form
      )
      if (response.valid || response.ok) {
        return response
      }
      throw response.error
    } catch (error) {
      console.log('getUserActivities', error)
    }
  }

  async bookActivity(form) {
    try {
      const response = await HTTPService.postFormData(
        `${process.env.GATSBY_manager_public_url}/register-for-activity`,
        form
      )
      if (response.valid || response.ok) {
        return response
      }
      throw response
    } catch (error) {
      console.log('bookActivity', error.error)
      return error
    }
  }

  async cancelActivity(form) {
    try {
      const response = await HTTPService.postFormData(
        `${process.env.GATSBY_manager_public_url}/user-cancel-activity`,
        form
      )
      if (response.valid || response.ok) {
        return response
      }
      throw response.error
    } catch (error) {
      console.log('cancelActivity', error)
    }
  }

  // Contact

  async contactRequest(form) {
    try {
      const response = await HTTPService.postFormData(
        `${process.env.GATSBY_manager_public_url}/contact-request`,
        {...form, type: 'contact-request', city: process.env.GATSBY_city}
      )
      if (response.valid || response.ok) {
        return response
      }
      throw response.error
    } catch (error) {
      console.log('contactRequest', error)
    }
  }

  async contactRequestTour(form) {
    try {
      const response = await HTTPService.postFormData(
        `${process.env.GATSBY_manager_public_url}/contact-request`,
        {...form, type: 'contact-request-tour', city: process.env.GATSBY_city}
      )
      if (response.valid || response.ok) {
        return response
      }
      throw response.error
    } catch (error) {
      console.log('contactRequestTour', error)
    }
  }

  async newsletter(form) {
    try {
      const response = await HTTPService.postFormData(
        `${process.env.GATSBY_manager_public_url}/contact-request`,
        { ...form, type: 'newsletter', city: process.env.GATSBY_city}
      )
      if (response.valid || response.ok) {
        return response
      }
      throw response.error
    } catch (error) {
      console.log('contactRequestNewsletter', error)
    }
  }

  async complaint(form) {
      const response = await HTTPService.postFormData(
        `${process.env.GATSBY_manager_public_url}/complaint`,
        {...form, city: process.env.GATSBY_city}
      )
      return response
  }
}

export default new SessionService()

// @flow

import React from 'react'
import { Field } from 'formik'
import Svg from './Svg'

type Props = {
  name: string,
  options: Array<string>,
  required?: boolean,
}

const FormSelect = ({ name, options, required }: Props) => {
  return (
    <div className="relative w-full border mb-10px border-gray placeholder-gray font-secondary text-small"
    >
      <div className="absolute top-0 right-0 flex items-center justify-center h-full p-2 bg-primary-pink">
        <Svg name="arrow-dropdown" whidth={16} height="auto" />
      </div>
      <Field
        className="w-full h-full px-5 pt-4 pb-3 bg-transparent border-0 rounded-none focus:outline-none"
        as="select" 
        name={name} 
        required={required}
      >
        {options.map((item, index) => {
          return (
          <option key={index} value={item}>{item}</option>
          )
        })}
      </Field>
    </div>
  )
}

export default FormSelect

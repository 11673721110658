// @flow

import React from 'react'
import Appear from './Appear'
import type { Image } from 'types/'
import 'swiper/css/swiper.css'
import useIsMobile from '../hooks/useIsMobile'
import SliderActivitiesMobile from './SliderActivitiesMobile'
import SliderActivitiesDesktop from './SliderActivitiesDesktop'
import ContainerGrid from './ContainerGrid'

type SlideItem = {
  id: string,
  image: Image,
  supertitle: string,
  title: string,
  text: string,
  ctaHref: string,
  ctaLabel: string,
}
type Props = {
  title: string,
  items: Array<SlideItem>,
  maskMobile: Image,
  maskDesktop: Image,
}

const itemsOnScreen = 3

const SliderActivities = ({ title, items, maskMobile, maskDesktop }: Props) => {
  const isMobile = useIsMobile()
  
  // Duplicate items to prevent blank slider sides
  // Ideally it should double the items on screen
  const handleItems = () => items.length < itemsOnScreen * 2 ? [...items, ...items] : items

  const heading = <ContainerGrid>
      <div className="grid grid-cols-4 gap-4 md:grid-cols-10 md:gap-6 lg:gap-8">
        <h3 className="col-span-5 mt-12 mb-10 leading-none font-primary text-gray text-big1 md:text-big4 md:mb-28">{title}</h3>
      </div>
    </ContainerGrid>

    if (isMobile) {
      return (
        <Appear>
          <div className="mb-24">
            {heading}
            <SliderActivitiesMobile items={items} mask={maskMobile}/>
          </div>
        </Appear>
      )
    }

  return (
    <Appear>
      <div className="mb-48">
        {heading}
        <SliderActivitiesDesktop items={handleItems()} mask={maskDesktop} />
      </div>
    </Appear>
  )
}

export default SliderActivities

// @flow

import React, { useState } from 'react'
import Svg from './Svg'
import { Link } from 'gatsby'

type Props = {
  cta: Object,
  disabled?: boolean,
  hideArrow?: boolean,
  actionButton?: boolean,
  actionButtonOnClick?: Function,
  openNewWindow?: boolean,
  internalLink?: boolean,
  isBookingInfoCard?: boolean,
}

const ButtonSecondary = ({
  cta,
  disabled,
  hideArrow,
  openNewWindow,
  internalLink,
  actionButton,
  actionButtonOnClick,
  isBookingInfoCard
}: Props) => {
  const [mouseOver, setMouseOver] = useState(false)

  if (internalLink) {
    return (
      <Link
        to={cta.href}
        className={`cursor-pointer inline-flex items-end justify-start uppercase border-b items-top border-gray font-secondary text-gray ${mouseOver ? `${isBookingInfoCard ? "md:hover:font-bold" : "md:hover:text-primary-brown md:hover:border-primary-brown" }` : ""} text-small`}
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        disabled={disabled}
      >
        {cta.label}
        {hideArrow !== true && <div className='mt-1 mb-2 ml-2'>
          <Svg name="arrow-down-right" width={10} height={10} color={`${mouseOver && !isBookingInfoCard ? "primary-brown" : ""}`} />
        </div>}
      </Link>
    )
  }

  if (actionButton) {
    return (
      <div
        onClick={disabled ? () => {} : actionButtonOnClick}
        className={`cursor-pointer inline-flex items-end justify-start uppercase border-b items-top border-gray font-secondary text-gray ${mouseOver ? `${isBookingInfoCard ? "md:hover:font-bold" : "md:hover:text-primary-brown md:hover:border-primary-brown" }` : ""} text-small`}
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        {cta.label}
        {hideArrow !== true && <div className='mt-1 mb-2 ml-2'>
          <Svg name="arrow-down-right" width={10} height={10} color={`${mouseOver && !isBookingInfoCard ? "primary-brown" : ""}`} />
        </div>}
      </div>
    )
  }
  
  return (
    <a
      href={disabled ? "" : cta.href}
      target={openNewWindow ? "_blank" : null}
      className={`cursor-pointer inline-flex items-end justify-start uppercase border-b items-top border-gray font-secondary text-gray ${mouseOver ? `${isBookingInfoCard ? "md:hover:font-bold" : "md:hover:text-primary-brown md:hover:border-primary-brown" }` : ""} text-small`}
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {cta.label}
      {hideArrow !== true && <div className='mt-1 mb-2 ml-2'>
        <Svg name="arrow-down-right" width={10} height={10} color={`${mouseOver && !isBookingInfoCard ? "primary-brown" : ""}`} />
      </div>}
    </a>
  )
}

export default ButtonSecondary

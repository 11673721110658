// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import type { Image } from 'types/'
import ContainerGrid from './ContainerGrid'
import Button from './Button'
import DropCapText from './DropCapText'
import useIsMobile from '../hooks/useIsMobile'
import ActivityTag from './ActivityTag'
import ImageLazy from './ImageLazy'
import HTMLText from './HTMLText'

type Block = {
  title: string,
  text: string,
  pageName?: string,
}

type Tag = {
  color?: string,
  title: string,
  id: string,
}

type Props = {
  title: string,
  text: string,
  image: Image,
  tags?: Array<Tag>,
  blocks?: Array<Block>,
  apiId: string,
  pageName?: string,
  ctaLabel: string,
  ctaHref: string
}

const HeadingDetail = ({ title, text, image, tags, blocks, apiId, pageName, ctaLabel, ctaHref }: Props) => {
  const envCityMadrid = process.env.GATSBY_city === 'Madrid'
  const isMobile = useIsMobile()
  const [windowHeight, setWindowHeight] = useState()
  const [textSize, setTextSize] = useState(100)
  const [marginBottom, setMarginBottom] = useState('mb-24')
  const [marginBottomTags, setMarginBottomTags] = useState('mb-12')
  const [marginTop, setMarginTop] = useState('md:mt-36')

  const handleResize = () => {
    const height = document.documentElement.clientHeight || document.body.clientHeight
    setWindowHeight(height)
  }

  const handleTitleStyleDesktop = () => {
    if  (windowHeight) {
      if (windowHeight > 800) {
        setTextSize(100)
        setMarginBottom('mb-24')
        setMarginBottomTags('mb-12')
        setMarginTop('md:mt-36')
      }
      if (windowHeight < 800) {
        setTextSize(90)
        setMarginBottom('mb-16')
        setMarginBottomTags('mb-10')
        setMarginTop('md:mt-32')
      }
      if (windowHeight < 700) {
        setTextSize(80)
        setMarginBottom('mb-12')
        setMarginBottomTags('mb-8')
        setMarginTop('md:mt-24')
      }
      if (windowHeight < 600) {
        setTextSize(70)
        setMarginBottom('mb-10')
        setMarginBottomTags('mb-6')
        setMarginTop('md:mt-20')
      }
      if (windowHeight < 500) {
        setTextSize(60)
        setMarginBottom('mb-8')
        setMarginBottomTags('mb-4')
        setMarginTop('md:mt-16')
      }
      if (windowHeight < 400) {
        setTextSize(50)
        setMarginBottom('mb-6')
        setMarginBottomTags('mb-4')
        setMarginTop('md:mt-12')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    handleTitleStyleDesktop()
  }, [windowHeight])


  const customMargins = pageName === "activities" ? `mt-16 ${marginTop}` : `mt-16 mb-32 ${marginTop} md:mb-56`
 
  return (
    <Appear>
      <div className={customMargins}>
        {isMobile
        ? <ContainerGrid>
          <div className="grid grid-cols-4 gap-4 md:hidden">
            <h1 className={`col-span-4 text-gray font-primary text-big3 leading-none break-words ${tags ? "mb-4" : "mb-6 md:mb-10"}`}>{title}</h1>
            {tags && <div className="flex flex-wrap col-span-4 mb-3 md:mb-6">
              {tags.map((tag, index) => <ActivityTag key={index} item={tag}/>)}
            </div>}
            <div className="col-span-4 mb-6 md:mb-10">
              <DropCapText text={text} />
            </div>
            <div className="col-span-4">
              {image && <Appear>
                <ImageLazy image={image} alt="" />
              </Appear>}
            </div>
            {blocks && <div className="col-span-3 col-start-2">
              {blocks.map((block, index) => <div
                key={index}
                className="mt-10"
                >
                  <h3 className="w-2/3 mb-6 leading-tight text-bigger5 text-gray font-primary">{block.title}</h3>
                  <HTMLText className="text-gray text-small font-secondary" text={block.text} />
                </div>)}
            </div>}
            {(ctaLabel && ctaHref && apiId && !envCityMadrid) &&
              <div className="col-span-4 mt-10">
                <Button internalLink={true} cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} disabled={pageName === "services"}/>
              </div>
            }
          </div>
        </ContainerGrid>
        : <ContainerGrid mdCleanLeft lgCleanLeft>
          <div className="hidden grid-cols-11 gap-6 ml-6 md:grid lg:ml-8 lg:gap-8">
            <div className={`row-start-1 col-start-2 col-span-5 ${tags ? marginBottomTags : marginBottom}`}>
              <h1
                className="leading-none text-gray font-primary"
                style={{ fontSize: textSize }}
              >{title}</h1>
              {tags && <div
              className="flex mt-12"
              >
                {tags.map((tag, index) => <ActivityTag key={index} item={tag}/>)}
              </div>
              }
            </div>
            <div className="col-span-5 col-start-1 row-start-2">
              {(text && text !== "") && (
                <DropCapText text={text} />
              )}
              {(blocks && blocks.length > 0) && (
                <div className="ml-20%">
                  {blocks.map((block, index) => {
                    if (
                      (!block.title || block.title === "")
                      || (!block.text && block.text === "")
                    ) return null
                    
                    return (
                      <div key={index} className={`${text && text !== "" ? "mt-12" : ""}`}>
                        <h3 className="mb-8 text-big2 text-gray font-primary">{block.title}</h3>
                        {block.text && block.text !== "" && (
                          <HTMLText text={block.text} className="text-gray text-normal font-secondary" />
                        )}
                      </div>
                    )
                  })}
                </div>
              )}
              {(ctaLabel && ctaHref && !envCityMadrid) &&
                <div className={`ml-20% w-1/2 ${(blocks && blocks.length > 0) || (text && text !== "") ? "mt-12" : "mt-0"}`}>
                  <Button internalLink={true} cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} disabled={pageName === "services"} />
                </div>
              }
            </div>
            <div className="col-span-5 col-start-7 row-start-2">
              {image && <Appear>
                <ImageLazy className="w-full h-auto" image={image} alt="" />
              </Appear>}
            </div>
          </div>
        </ContainerGrid>
        }
      </div>
    </Appear>
  )
}

export default HeadingDetail

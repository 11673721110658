// @flow

import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Svg from './Svg'
import moment from 'moment'
import CalendarHoverMobile from './CalendarHoverMobile'
import { useGlobalState } from 'store/'

type Tag = {
  id: string,
  title: string,
  color?: string
}

type Activity = {
  id: string,
  activityId: string,
  day: string,
  dateFrom: string,
  dateTo: string,
  hourFrom: string,
  hourTo: string,
  room: Object,
  name: string,
  teacher: string,
  tags: Array<Tag>,
  description: string,
  ctaLabel: string,
  ctaHref: string,
  exceptionDates: Array<string>
}

type Props = {
  cta: Object,
  days: Array<string>,
  items: Array<Activity>,
  pageName: string,
  defaultTab: number,
  ctaHref?: string,
  start?: string,
  literals: Object,
  showTeachers?: boolean,
  userInfo?: Object,
}

const ActivityTableMobile = ({
  ctaHref,
  days,
  items,
  pageName,
  defaultTab,
  start,
  literals,
  showTeachers,
  userInfo,
}: Props) => {
  const envCityMadrid = process.env.GATSBY_city === 'Madrid'
  const [session] = useGlobalState()
  const [flag, setFlag] = useState(false)
  const [minHeight, setMinHeight] = useState("auto")
  const [activeTab, setActiveTab] = useState(
    typeof defaultTab !== 'undefined' ? defaultTab : 0
  )
  const [filteredItems, setFilteredItems] = useState([])
  const [onScreen, setOnScreen] = useState(false)
  const [book, setBook] = useState(null)

  function handleTabClick(index) {
    if (index !== activeTab) setActiveTab(index)
  }

  // Change days background color
  useEffect(() => {
    // Get first backgroundImage
    const element = document.querySelector("[data-element='backgroundImage']")

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setOnScreen(false)
        } else {
          setOnScreen(true)
        }
      },
      {
        rootMargin: '-80px 0px 0px 0px'
      }
    )
    element && observer.observe(element)

    return () => {
      element && observer && observer.unobserve(element)
    }
  }, [])

  // Handle filtered items on activeTab update
  useEffect(() => {
    let activeDays = []
    const filterResult = items.filter(item => {
      const activeDay = activeTab + 1
      // Get tabs with items
      if (!activeDays.includes(item.day)) activeDays.push(item.day)

      // Filter items by tab
      if (item.day === activeDay.toString()) return item
    })

    // Set default tab onload
    // Esto hace que si una actividad solo se hace un día (p.ej. martes)...
    // ... en el calendario mobile salga por defecto el día seleccionado...
    // ... y el usuario no vea una pestaña vacía
    const todayIsEmpty = !activeDays.includes(defaultTab.toString())
    if (
      pageName === 'activities' &&
      activeDays.length !== 0 &&
      todayIsEmpty &&
      !flag
    ) {
      const populatedTab = parseInt(activeDays[0]) - 1
      setActiveTab(populatedTab)
      setFlag(true)
    }

    // sort filtered items
    filterResult.sort((a, b) => {
      const hourA = a.hourFrom
      const hourAInt = hourA.split(':').map(item => parseInt(item))
      const hourAIntTotal = hourAInt[0] * 100 - hourAInt[1]

      const hourB = b.hourTo
      const hourBInt = hourB.split(':').map(item => parseInt(item))
      const hourBIntTotal = hourBInt[0] * 100 - hourAInt[1]

      return hourAIntTotal - hourBIntTotal
    })
    // set sorted filtered items
    setFilteredItems(filterResult)
  }, [activeTab, items])

  // Handle day literal
  const handleDayInitial = day => {
    if (day === 'Miércoles') {
      return 'X'
    }
    return day.charAt(0)
  }

  // Handle selected day literal
  const handleSelected = (day, index) => {
    const daysInMonth = moment(start).daysInMonth()
    const number = moment(start).date() + index
    const result = number > daysInMonth ? number - daysInMonth : number
    return `${day} ${result}`
  }

  useEffect(() => {
    if (book) {
      const el = document.querySelector('#calendar-hover')
      if (el instanceof HTMLElement) el.scrollIntoView({ behavior: 'smooth' })
    } else setMinHeight("auto")
  }, [book])

  function onHeightChange(value) {
    if (value === 0) {
      setMinHeight("auto")
    } else setMinHeight(`${value}px`)
  }

  return (
    <>
      <div
        className="relative col-span-4 md:hidden"
        style={{ minHeight }}
      >
        {book && !envCityMadrid && (
          <CalendarHoverMobile
            {...book}
            close={() => setBook(null)}
            literals={literals}
            onHeightChange={onHeightChange}
            userInfo={userInfo}
          />
        )}
        <div
          data-name="table-tabs"
          className={`sticky z-10 grid grid-cols-9 col-span-4 gap-0 border-l top-80px border-gray transition-colors duration-150 ease-linear ${
            onScreen ? 'bg-primary-beige' : 'bg-white'
          }`}
        >
          {days.map((day, index) => {
            return (
              <div
                key={index}
                data-name="table-day-tab"
                className={`text-center border-t border-b border-r border-gray font-secondary text-small px-1 py-4 uppercase ${
                  parseInt(activeTab) === index
                    ? 'bg-gray text-white col-span-3'
                    : 'col-span-1 text-gray bg-transparent'
                }`}
                onClick={() => {
                  handleTabClick(index)
                }}
              >
                {activeTab === index
                  ? handleSelected(day, index)
                  : handleDayInitial(day)}
              </div>
            )
          })}
        </div>
        <div
          data-name="table"
          className="flex flex-col items-stretch justify-start w-full border-l border-r border-gray"
        >
          {filteredItems.map(activity => {
            const getDay = () => {
              const daysInMonth = moment(start).daysInMonth()
              const number = moment(start).date() + (Number(activity.day) - 1)
              const result = number > daysInMonth ? number - daysInMonth : number
              return result
            }
            const activityFormattedDate = `${moment(start).year()}-${moment(start).month() + 1}-${getDay()} ${activity.hourFrom}`
            const today = moment()

            const hoursRemaining = moment(activityFormattedDate).diff(today, 'hours')
            const minutesRemaining = moment(activityFormattedDate).diff(today, 'minutes')
            const secondsRemaining = moment(activityFormattedDate).diff(today, 'seconds')
            let oneDayBefore = false
            
            if (hoursRemaining >= 0 && minutesRemaining >= 0 && secondsRemaining >= 0) {
              if (hoursRemaining > 24) {
                oneDayBefore = false
              } else if (hoursRemaining < 24 && hoursRemaining > 0) {
                oneDayBefore = true
              } else if (hoursRemaining === 0 && minutesRemaining < 60 && minutesRemaining > 0) {
                oneDayBefore = true
              } else if (hoursRemaining === 0 && minutesRemaining === 0 && secondsRemaining < 60 && secondsRemaining > 0) {
                oneDayBefore = true
              } else {
                oneDayBefore = false
              }
            }

            const isBookable = moment(activityFormattedDate).isAfter() && oneDayBefore
            const capacityControl = activity.needsReservation === "1"
            const userIsMember = session && session.hasMembership === true

            const formattedDay = moment(activityFormattedDate).format('MM-DD')

            if (activity.exceptionDates.find(exceptionDate => {
              const currentDateMoment = moment(start).add((Number(activity.day) - 1), 'days').format('MM-DD')
              return exceptionDate.substring(5) === currentDateMoment
            })) {
              return null
            }

            return (
              <div key={activity.id}>
                <div className="relative flex items-stretch justify-start border-b border-gray">
                  <div
                    className={`w-10px absolute top-0 left-0 h-full border-r border-gray`}
                    style={{
                      backgroundColor: `${
                        activity.tags.length > 0
                          ? `#${activity.tags[0].color}`
                          : '#fff'
                      }`
                    }}
                  />
                  <div className="flex-1 pt-4 pl-8 leading-tight pb-10px font-primary text-bigger2">
                    <div className="font-secondary text-small">
                      {activity.hourFrom} - {activity.hourTo} h |{' '}
                      {activity.room.title}
                    </div>
                    {activity.name}
                    {activity.teacher && showTeachers && <div className="mt-1 font-secondary text-small">{activity.teacher}</div>}
                    <br></br>
                    {/* (user login hidden) */}
                    {/* {isBookable && capacityControl && userIsMember && !envCityMadrid && <div */}
                    {isBookable && capacityControl && !envCityMadrid && <div
                      className="inline-flex items-center self-start flex-grow-0 flex-shrink-0 p-2 my-1 text-white font-secondary bg-gray text-smaller active:bg-white active:text-gray"
                      onClick={() => {
                        const obj ={
                          ...activity,
                          cta: {
                            label: activity.ctaLabel,
                            href: activity.ctaHref
                          },
                          start
                        }
                        setBook(obj)
                      }}
                    >
                      <p style={{ paddingTop: '2.5px' }}>{literals.book_activity}</p>
                    </div>}
                  </div>
                  <Link
                    to={pageName === 'activities' ? ctaHref : activity.ctaHref}
                    className="flex items-end m-2"
                  >
                    <Svg
                      name="arrow-down-right"
                      width={16}
                      height={16}
                      color="gray"
                    />
                  </Link>
                </div>
              </div>
            )
          })}
          {filteredItems.length === 0 && (
            <div className="w-full col-span-4 p-4 leading-tight text-center border-b font-primary text-bigger2 border-gray">
              {literals.noResultsFound}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ActivityTableMobile

// @flow

import React from 'react'
import { Field } from 'formik'

type Props = {
  name: string,
  value: string,
  required?: boolean,
  handleRadioCheck: Function,
  isChecked: boolean,
}

const FormRadio = ({ name, value, required, handleRadioCheck, isChecked }: Props) => {
  
  // Send id to parent on click
  const handleClick = () => {
    handleRadioCheck(value)
  }

  return (
    <>
      <Field
        className="absolute w-0 h-0 opacity-0 pointer-events-none"
        type="radio"
        name={name}
        value={value}
        required={required}
        checked={isChecked}
      />
      <div className="inline-flex items-center justify-start mr-6 font-secondary text-small text-gray">
        <button
          type="button" 
          className={`
            focus:outline-none w-4 h-4 rounded-full border-gray mr-10px bg-transparent
            transition-all duration-100 ease-in-out ${isChecked ? "border-8" : "border"}`} 
          onClick={handleClick}
        />
        <label htmlFor={value}>{value}</label>
      </div>
    </>
  )
}

export default FormRadio

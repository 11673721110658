// @flow

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import CollapsibleContainer from './CollapsibleContainer'
import BookingInfo from './BookingInfo'
import BookingActivityInfo from './BookingActivityInfo'

type Props = {
  itemListOpacity: number,
  items: Array<Object>,
  pastItems: Array<Object>,
  noBookingFound: string,
  handleDropdown: () => void,
  activeDropdown: string | "undefined",
  literals: Object,
  formattedCta: any,
  type: string,
  alertCancelActivity: string,
  alertCancelBooking: string,
}

const BookingInfoListSection = ({
  itemListOpacity,
  items,
  pastItems,
  noBookingFound,
  handleDropdown,
  activeDropdown,
  literals,
  formattedCta,
  type,
  alertCancelActivity,
  alertCancelBooking
}: Props) => {
  const [groups, setGroups] = useState([])

  async function groupItemsByMonth(items) {
    const months = []
    items.forEach(booking => {
      let month, date, day, hours, minutes
      if (type === "activities") {
        month = moment(booking.date).format("MMMM")
        date = new Date(`${booking.date}T${booking.hourFrom}`)
        day = date.getDate()
        hours = date.getHours()
        minutes = date.getMinutes()
      }
      if (type === "services") {
        month = moment(booking.startDate).format("MMMM")
        date = new Date(booking.startDate)
        day = date.getDate()
        hours = date.getHours()
        minutes = date.getMinutes()
      }

      Object.assign(booking, { 
        startingMonth: month,
        startingDay: day,
        hour: Number(hours) * 100 + minutes
      })
      if (!months.includes(month)) months.push(month)
    })

    const groupedByMonth = await months.map(month => {
      const result = items.filter(item => (
        item.startingMonth === month
      ))
      result.length > 1 && result.sort((a, b) => {
        if (a.startingDay !== b.startingDay) return a.startingDay - b.startingDay
        if (a.hour !== b.hour) return a.hour - b.hour
        return 0
      })
      return { month, items: result }
    })
    return groupedByMonth
  }

  useEffect(() => {
    groupItemsByMonth(items)
      .then(data => setGroups(data))
  }, [items])
  
  return (
    <div 
      className="col-span-4 transition-opacity duration-150 ease-linear border-b md:col-span-10 border-gray"
      style={{opacity: itemListOpacity}}
    >
      {pastItems.length > 0 && (
        <CollapsibleContainer
          id='pastItems'
          title={'Ver reservas anteriores'}
          callback={handleDropdown}
          active={activeDropdown === 'pastItems'}
          first={true}
          last={activeDropdown !== 'pastItems'}
          textStyle="font-secondary pt-2 pb-3"
        >
          {type === "activities" && (
            pastItems.map((item, index) => {
              return (
                <BookingActivityInfo
                  key={`${item.activity}${item.date}${item.hourFrom}${item.hourTo}-${index}`}
                  item={item}
                  literals={literals}
                  alertCancelActivity={alertCancelActivity}
                />
              )
            })
          )}
          {type === "services" && (
            pastItems.map((item, index) => {
              return (
              <div
                key={`${item.bookingDate}${item.startDate}-${index}`}
                className="mt-4"
              >
                <BookingInfo
                  bookingId={item.id}
                  title={item.title}
                  date={item.startDate}
                  literals={literals}
                  ctaCalendar={formattedCta('calendar')}
                  ctaCancel={formattedCta('cancel')}
                  alertCancelBooking={alertCancelBooking}
                />
              </div>
            )})
          )}
        </CollapsibleContainer>
        )
      }
      {groups.length === 0 ? (
        <div className="my-4 text-center md:my-8 text-gray font-primary text-bigger1 md:text-bigger5">
          {noBookingFound}
        </div>
      ) : (
        groups.map((group, index) => (
          <CollapsibleContainer
            key={group.month}
            id={`${type}${group.month}`}
            title={group.month}
            callback={handleDropdown}
            active={activeDropdown === `${type}${group.month}`}
            first={index === 0}
          >
            {type === "activities" && (
              group.items.map(item => {
                return (
                  <BookingActivityInfo
                    key={`${item.activity}${item.date}${item.hourFrom}${item.hourTo}`}
                    item={item}
                    literals={literals}
                    alertCancelActivity={alertCancelActivity}
                  />
                )
              })
            )}
            {type === "services" && (
              group.items.map(item => {
                return (
                <div
                  key={`${item.bookingDate}${item.startDate}`}
                  className="mt-4"
                >
                  <BookingInfo
                    bookingId={item.id}
                    title={item.title}
                    date={item.startDate}
                    literals={literals}
                    ctaCalendar={formattedCta('calendar')}
                    ctaCancel={formattedCta('cancel')}
                    alertCancelBooking={alertCancelBooking}
                  />
                </div>
              )})
            )}
          </CollapsibleContainer>
        ))
      )}
    </div>
  )
}

export default BookingInfoListSection

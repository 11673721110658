// @flow

import React, { useEffect, useState } from 'react'
import { useGlobalState } from 'store/'
import sessionService from '../services/session.service'
import { navigate } from 'gatsby'

type Props = {
  pageName: string,
  loginHref: string,
}

const RestrictedPage = ({ pageName, loginHref }: Props) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [session, dispatch] = useGlobalState()

  async function validateToken() {
    const { token, id, numberOfFutureAppointments, name, surname, hasMembership } = session
    const response = await sessionService.validateToken(token, id)
    if (response && response.valid) {
      const { data } = response
      dispatch({ type: "login", payload: {
        hasMembership: hasMembership,
        token: data.token,
        numberOfFutureAppointments: numberOfFutureAppointments,
        id: id,
        name: name,
        surname: surname,
      }})
      setFirstLoad(false)
    } else {
      setFirstLoad(false)
      dispatch({ type: "logout" })
      navigate(loginHref)
    }
  }

  useEffect(() => {
    if (!session) {
      navigate(loginHref)
    } else {
      if (firstLoad) {
        validateToken()
      }
    }
  }, [session])

  return null
}

export default RestrictedPage

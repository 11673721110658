import React from 'react'
import Svg from './Svg'

const FormDropFile = ({ labelDrop, labelSelect, labelSelected, onValueChange, value }) => {
  const onDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files.length > 0) {
      onValueChange(e.dataTransfer.files[0])
    }
  }

  const handleBrowse = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let input = document.createElement("input")
    input.type = "file"

    input.onchange = (e) => {
      if (e.target.files[0]) {
        onValueChange(e.target.files[0])
      }
    }

    input.click()
  }

  const handleDeleteFile = () => {
    onValueChange(undefined)
  }

  return (
    <div>
      <div className="p-5 border border-dashed border-gray h-32 overflow-hidden">
        {value ? (
          <div className="w-full h-full text-small font-secondary flex flex-col justify-center items-center gap-2">
            <span className="text-gray">
              {labelSelected}
            </span>
            <button key={value.name} className="flex items-center bg-gray px-2 rounded-sm py-px max-w-full" onClick={() => handleDeleteFile()}>
              <span className="text-white pr-3 break-all">{value.name}</span> <Svg name="remove" width={10} color="white"/>
            </button>
          </div>
        ) : (
          <div
            className="w-full h-full flex items-center justify-center"
            onDragOver={(e) =>
              e.preventDefault() && e.stopPropagation()
            }
            onDrop={(e) => onDrop(e)}
          >
            <div className="text-gray text-small font-secondary">
              <span>{labelDrop}</span>
              <button
                className="font-bold underline cursor-pointer"
                onClick={(e) => handleBrowse(e)}
              >
                {labelSelect}
              </button>
            </div>
          </div>
        )}
        </div>
    </div>
  )
}

export default FormDropFile
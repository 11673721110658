// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import { Formik, Form } from 'formik'
import ContainerGrid from './ContainerGrid'
import FormField from './FormField'
import FormTextArea from './FormTextArea'
import FormRadio from './FormRadio'
import FormCheckbox from './FormCheckbox'
import Button from './Button'
import sessionService from '../services/session.service'
import HTMLText from './HTMLText'

type Props = {
  title: string,
  text: string,
  placeholders: Object,
  ctaLabel: string,
  ctaHref: string,
  ctaText: string,
  requestSentMessage: string,
  literalErrorEmail: string,
  literalErrorPhone: string,
}

const ContactForm = ({
  title,
  placeholders,
  ctaLabel,
  ctaText,
  ctaHref,
  requestSentMessage,
  literalErrorEmail,
  literalErrorPhone,
}: Props) => {

  const [validationErrors, setValidationErrors] = useState()
  const [opacity, setOpacity] = useState(1)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  useEffect(() => {
    const opacityTimer = setTimeout(() => {
      setOpacity(0)
      }, 4800)

    const resetTimer = setTimeout(() => {
        setSent(false)
        setOpacity(1)
      }, 5000)
    
    return () => { 
      clearTimeout(opacityTimer)
      clearTimeout(resetTimer)
    }
  }, [sent])

  return (
    <Appear>
      <ContainerGrid>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-10 md:gap-6 lg:gap-8 text-gray">
          <div data-name="left-column" className="col-span-1 md:col-span-4">
            <h3 className="mb-2 md:mb-12 font-primary text-big1 md:text-big2">{title}</h3>
            <p className="mb-8 md:mb-12 font-secondary">{ctaText}</p>
          </div>
          <Appear className="col-span-1 md:col-span-5 md:col-start-6">
            <Formik
              enableReinitialize={true}
              initialValues={{
                type: "contact-request",
                name: "", 
                surnames: "", 
                email: "",
                phoneNumber: "",
                message: "",
                member: "",
                terms: false
              }}
              validate={ values => {
                const errors = {}
                const phoneReg = new RegExp(/^[9|6|7][0-9]{8}$/)
                const mailReg = new RegExp(/^([a-z0-9_.+-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/gm)
                Object.entries(values).forEach(field => {
                  if (field[0] === "phoneNumber") {
                    if (
                      typeof field[1] === "string"
                      && field[1] !== ""
                      && !phoneReg.test(field[1])
                    ) errors[field[0]] = true
                  }
                  if(field[0] === "email") {
                    if (
                      typeof field[1] === "string"
                      && field[1] !== ""
                      && !mailReg.test(field[1])
                    ) errors [field[0]] = true
                  }
                })
                setValidationErrors(errors)
                return errors
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setLoading(true)
                delete values.terms
                const response = await sessionService.contactRequest(values)
                values.terms = false
                if (response) {
                  setSent(true)
                  setLoading(false)
                  resetForm({})
                }
                setSubmitting(false)
              }}
            >
              {({values, setFieldValue}) => {
                return (
                  <Form className="grid grid-cols-4 md:grid-cols-5 md:gap-6 lg:gap-8">
                    <div className="col-span-4 md:col-span-5">
                      <FormField
                        type="text"
                        name="name"
                        placeholder={placeholders.name}
                        required={true}
                      />
                      <FormField
                        type="text"
                        name="surnames"
                        placeholder={placeholders.surname}
                        required={true}
                      />
                      <FormField
                        type="email"
                        name="email"
                        placeholder={placeholders.email}
                        error={validationErrors && validationErrors.email}
                        literalError={literalErrorEmail}
                        required={true}
                      />
                      <FormField
                        type="tel"
                        name="phoneNumber"
                        placeholder={placeholders.phone}
                        error={validationErrors && validationErrors.phoneNumber}
                        literalError={literalErrorPhone}
                        required={true}
                      />
                      <div className="mb-3">
                        <FormTextArea
                          name="message"
                          placeholder={placeholders.message}
                          required={true}
                        />
                      </div>
                      <div className="flex items-center justify-start">
                        <FormRadio 
                          name="member"
                          value={placeholders.member}
                          required={true}
                          isChecked={values.member === "1"}
                          handleRadioCheck={() => {setFieldValue("member", "1")}}
                        />
                        <FormRadio 
                          name="member"
                          value={placeholders.noMember}
                          required={true}
                          isChecked={values.member === "0"}
                          handleRadioCheck={() => {setFieldValue("member", "0")}}
                        />
                      </div>
                        <div
                          className="flex items-center justify-start mt-5 text-gray text-tiny"
                        >
                          <FormCheckbox 
                            name="terms"
                            value={values.terms}
                            required={true}
                            isChecked={values.terms === true}
                            handleCheckbox={(value) => setFieldValue("terms", value)}
                          />
                          <HTMLText text={placeholders.terms} className="font-secondary" />
                        </div>
                    </div>
                    <div className="flex flex-col items-center col-span-4 mt-10 md:flex-row md:col-span-6 md:mt-8 lg:mt-6">
                      <div className="w-full md:w-1/3">
                        <Button submitButton={true} cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} loading={loading} />
                      </div>
                      {sent && <Appear>
                        <div
                          className="my-4 transition-opacity duration-150 ease-linear md:ml-4 text-small"
                          style={{ opacity: opacity }}
                        >
                          <span className="text-gray font-secondary">{requestSentMessage}</span>
                        </div>
                      </Appear>}
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </Appear>
        </div>
      </ContainerGrid>
    </Appear>
  )
}

export default ContactForm

// @flow

import React, { useState, useEffect, useRef } from 'react'
import 'swiper/css/swiper.css'
import type { Image } from 'types/'
import Svg from './Svg'
import Button from './Button'
import useDidMount from 'hooks/useDidMount'
import anime from 'animejs/lib/anime.es.js'
import type { Ref } from 'types/'
import HTMLText from './HTMLText'

type SlideItem = {
  id: string,
  image: Image,
  supertitle?: string,
  title: string,
  text?: string,
  ctaLabel?: string,
  ctaHref?: string
}
type Props = {
  items: Array<SlideItem>,
  mask: Image,
  maskEnd: Image
}

const SliderHomeDesktop = ({ items, mask, maskEnd }: Props) => {
  const [active, setActive] = useState(0)
  const [mouseOverLeft, setMouseOverLeft] = useState(false)
  const [mouseOverRight, setMouseOverRight] = useState(false)
  const [maskLoaded, setMaskLoaded] = useState(false)
  const [maskEndLoaded, setMaskEndLoaded] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const hasItems = items && items.length > 0

  useEffect(() => {
    if (maskStartRef && maskStartRef.current && maskStartRef.current !== {}) {
      setMaskLoaded(true)
    }
    if (maskEndRef && maskEndRef.current && maskEndRef.current !== {}) {
      setMaskEndLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (maskLoaded && maskEndLoaded) {
      setLoaded(true)
    }
  }, [maskLoaded, maskEndLoaded])
  
  
  const didMount = useDidMount()
  const maskEl: Ref = useRef({})
  const contentEl: Ref = useRef({})
  const imageEl: Ref = useRef({})
  const maskStartRef: Ref = useRef({})
  const maskEndRef: Ref = useRef({})


  async function hideSlide(direction: string, index?: number) {
    const slideMask = maskEl.current
    const slideContent = contentEl.current
    const tl = anime
      .timeline({
        duration: 725,
        easing: 'cubicBezier(0.7, 0, 0.84, 0)'
      })
      .add({
        targets: slideContent,
        duration: 200,
        easing: 'linear',
        delay: 25,
        opacity: 0
      })
      .add({
        targets: slideMask,
        scaleY: 3,
        translateX: '35vw',
        duration: 500
      })
      .add(
        {
          targets: imageEl.current,
          opacity: 0
        },
        '-=1000'
      )
    await tl.finished
    let next = 0
    switch (direction) {
      case 'FORWARDS':
        next = active < items.length - 1 ? active + 1 : 0
        break
      case 'BACKWARDS':
        next = active > 0 ? active - 1 : items.length - 1
        break
      default:
        break
    }
    // Si hay index da igual si es prev o next
    setActive(index || next)
  }

  async function toSlide(index: number) {
    hideSlide('NONE', index)
  }

  async function showSlide() {
    const slideMask = maskEl.current
    const slideContent = contentEl.current

    const animation = anime({
      targets: slideMask,
      translateX: '-100vw',
      easing: 'cubicBezier(0, 0.55, 0.45, 1)',
      duration: 500
    })
    await animation.finished
    slideMask.style.opacity = '0'
    slideMask.style.transform = 'translateX(150vw)'
    slideMask.style.opacity = '1'
    const a2 = anime
      .timeline({
        duration: 1500,
        easing: 'cubicBezier(0, 0.55, 0.45, 1)'
      })
      .add({
        targets: slideMask,
        translateX: '100vw',
        opacity: 1,
        scale: 1,
        duration: 500
      })
      .add(
        {
          targets: slideContent,
          easing: 'linear',
          duration: 225,
          opacity: 1
        },
        '-=500'
      )
    await a2.finished
    anime({
      targets: imageEl.current,
      opacity: 1,
      duration: 700,
      easing: 'linear'
    })
  }

  useEffect(() => {
    if (didMount) {
      showSlide()
    }
  }, [active])

  const prevItem = () => {
    hideSlide('BACKWARDS')
  }

  const nextItem = () => {
    hideSlide('FORWARDS')
  }

  // $FlowFixMe
  const url = hasItems && `url(${items[active].image.dir}${items[active].image.name.normal}-1200.${items[active].image.extension})`
  
  // $FlowFixMe
  const src = `${mask.dir}${mask.name.normal}.${mask.extension}`
  
  // $FlowFixMe
  const srcEnd = `${maskEnd.dir}${maskEnd.name.normal}.${maskEnd.extension}`

  return (
    <>
      <div className="hidden mx-6 overflow-hidden md:block">
        <div
          className="relative flex items-center justify-end overflow-hidden"
          style={{
            height: '80vh'
          }}
        >
          {!loaded && <div className="absolute flex items-center justify-center w-full h-full">
            <div className="loader"></div>
          </div>}
          <div
            ref={imageEl}
            className={loaded ? "absolute inset-0" : ""}
            style={loaded ? {
              width: '70%',
              backgroundImage: url,
              backgroundSize: 'cover',
              backgroundPosition: 'center top',
              backgroundRepeat: 'no-repeat'
            } : {}}
          />
          <div
            ref={maskEl}
            style={{
              width: '150vw',
              height: '80vh',
              transform: 'translateX(100vw)'
            }}
          >
            <div className="flex pointer-events-none select-none" style={{ width: '180vw', height: '80vh' }}>
              <img
                ref={maskStartRef}
                src={src}
                style={{
                  height: '80vh',
                  width: '45vw'
                }}
              />
              {loaded && <div
                className="-ml-1 bg-primary-beige"
                style={{
                  width: '65vw',
                  height: '80vh'
                }}
              />}
              <img
                ref={maskEndRef}
                src={srcEnd}
                className={"w-1/3 -ml-1"}
                style={{
                  height: '80vh',
                  width: '35vw'
                }}
              />
            </div>
          </div>
          {hasItems && loaded && <div ref={contentEl} className="absolute w-2/5 pr-12 -ml-16 lg:pr-32">
            {items[active].supertitle && <span className="uppercase text-small text-gray font-secondary">
              {items[active].supertitle}
            </span>}
            <h2 className="mt-12 leading-none font-primary text-gray text-huge1 lg:text-huge3">
              {items[active].title}
            </h2>
            {items[active].text && <HTMLText
              className="mt-8 font-secondary text-gray text-normal"
              text={items[active].text}
            />}
            {items[active].ctaLabel && items[active].ctaHref && items[active].text && <div className="w-2/3 mt-10">
              <Button
                cta={{
                  label: items[active].ctaLabel,
                  href: items[active].ctaHref
                }}
                darkMode={true}
              />
            </div>}
            <div className="flex mt-8 -ml-4">
              <div
                onClick={prevItem}
                className="mx-4 cursor-pointer"
                onMouseOver={() => setMouseOverLeft(true)}
                onMouseLeave={() => setMouseOverLeft(false)}
              >
                <Svg
                  name="arrow-left"
                  width={12}
                  height={20}
                  color={mouseOverLeft ? 'primary-brown' : 'gray'}
                />
              </div>
              <div className="flex items-center">
                {items.map((item, index) => {
                  if (active === index) {
                    return (
                      <div
                        key={index}
                        className="w-2 h-2 mx-1 border border-solid rounded-full cursor-pointer border-gray bg-gray"
                      />
                    )
                  }
                  return (
                    <div
                      onClick={() => toSlide(index)}
                      key={index}
                      className="w-2 h-2 mx-1 border border-solid rounded-full cursor-pointer border-gray"
                    />
                  )
                })}
              </div>
              <div
                onClick={nextItem}
                className="mx-4 cursor-pointer"
                onMouseOver={() => setMouseOverRight(true)}
                onMouseLeave={() => setMouseOverRight(false)}
              >
                <Svg
                  name="arrow-right"
                  width={12}
                  height={20}
                  color={mouseOverRight ? 'primary-brown' : 'gray'}
                />
              </div>
            </div>
          </div>}
        </div>
      </div>
    </>
  )
}

export default SliderHomeDesktop

// @flow

let scrollCache = 0

const animations = {
  burgerIn: (
    topEl: HTMLElement,
    middleEl: HTMLElement,
    bottomEl: HTMLElement,
    menuEl: HTMLElement,
    menuOpenEl: HTMLElement,
    menuContentEl: HTMLElement,
  ): Promise<boolean> => {
    const body: any = document.body
    return new Promise(resolve => {
      bottomEl.classList.remove('my-1/2')
      middleEl.classList.remove('my-1/2')
      topEl.classList.remove('my-1/2')
      middleEl.style.width = '0'
      bottomEl.style.transform = 'translate(0, -2px)'
      scrollCache = window.pageYOffset
      setTimeout(() => {
        menuOpenEl.style.backgroundColor = '#C7B7AF'
        menuEl.style.transform = 'translateY(-80px)'
        bottomEl.style.transform = 'translate(0, -2px) rotate(45deg)'
        topEl.style.transform = 'rotate(-45deg)'
        setTimeout(() => {
          body.style.position = 'fixed',
          menuContentEl.style.opacity = '1'
        }, 1000)
        resolve(true)
      }, 300)
    })
  },
  burgerOut: (
    topEl: HTMLElement,
    middleEl: HTMLElement,
    bottomEl: HTMLElement,
    menuEl: HTMLElement,
    menuOpenEl: HTMLElement,
    menuContentEl: HTMLElement,
  ): Promise<boolean> => {
    const body: any = document.body
    return new Promise(resolve => {
      menuContentEl.style.opacity = '0',
      body.style.position = 'initial'
      bottomEl.style.transform = 'translate(0, -2px) rotate(0deg)'
      topEl.style.transform = 'rotate(0deg)'
      setTimeout(() => {
        window.scrollTo(0, scrollCache)
        menuEl.style.transform = 'translateY(-100vh)'
        bottomEl.style.transform = 'translate(0, 0)'
        bottomEl.classList.add('my-1/2')
        middleEl.classList.add('my-1/2')
        topEl.classList.add('my-1/2')
        middleEl.style.width = '1.5rem'
        bottomEl.style.width = '1.5rem'
        menuOpenEl.style.backgroundColor= 'initial'
        resolve(true)
        setTimeout(() => {
          body.style.position = 'initial'
          menuContentEl.style.opacity = '0',
          body.style.position = 'initial'
          bottomEl.style.transform = 'translate(0, -2px) rotate(0deg)'
          topEl.style.transform = 'rotate(0deg)'
        }, 300)
      }, 1000)
    })
  },
  languageSwitchOff: (langSwitchEl: HTMLElement, length: number) => {
    window.requestAnimationFrame(() => {
      langSwitchEl.style.transform = `translateY(-${(100 * length) / length}px)`
    })
  },
  languageSwitchOn: (langSwitchEl: HTMLElement) => {
    window.requestAnimationFrame(() => {
      langSwitchEl.style.transform = 'translateY(0)'
    })
  },
  fadeIn: (element: HTMLElement): Promise<boolean> => {
    return new Promise(resolve => {
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          element.style.opacity = '1'
          resolve(true)
        }, 500)
      })
    })
  },
  fadeOut: (element: HTMLElement): Promise<boolean> => {
    return new Promise(resolve => {
      window.requestAnimationFrame(() => {
        element.style.opacity = '0'
        setTimeout(() => {
          resolve(true)
        }, 100)
      })
    })
  }
}

export default animations

// @flow

import React from 'react'

type Props = {
  name: string,
  color?: string,
  height?: any,
  width?: any,
  className?: string
}

const Svg = ({ name, color, width, height, className }: Props) => {

  let iconColor = ''
  switch (color) {
    case 'white':
      iconColor = '#ffffff'
      break
    case 'gray':
      iconColor = '#575757'
      break
    case 'primary-brown':
      iconColor = '#C7B7AF'
      break
    default:
      break
  }


  const Icon = () => {
    switch (name) {
      case 'arrow-down-right':
      return (
        <svg fill={iconColor} height={height ? height : '16px'} width={width ? width : '16px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
                <g id="STATUS" transform="translate(-694.000000, -1350.000000)">
                    <g id="Group-5" transform="translate(144.000000, 1252.000000)">
                        <g id="Actividades" transform="translate(2.000000, 94.000000)">
                            <g id="Group-2">
                                <g id="Actividades">
                                    <g id="Group">
                                        <polygon id="Flecha-Copy-30" transform="translate(556.000000, 12.000000) scale(1, -1) translate(-556.000000, -12.000000) " points="548 4 548 5.00357524 562.292146 5.00357524 548.05903 19.2804366 548.766383 19.9899642 562.9995 5.71209935 562.9995 20 564 20 564 4"></polygon>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      )
      case 'arrow-dropdown':
      return (
        <svg fill={iconColor} height={height ? height : '16px'} width={width ? width : '16px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
              <polygon id="path-1" points="6.72450468e-05 0.00027826087 16 0.00027826087 16 15.9603478 6.72450468e-05 15.9603478"></polygon>
          </defs>
          <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
              <g id="06.1_Reserva_visita" transform="translate(-1269.000000, -798.000000)">
                  <g id="Group-7" transform="translate(59.000000, 265.000000)">
                      <g id="Group-6" transform="translate(554.000000, 282.000000)">
                          <g id="Elije_servicio" transform="translate(354.000000, 234.000000)">
                              <g id="Group">
                                  <g id="arrow_desplegable" transform="translate(310.000000, 25.000000) rotate(90.000000) translate(-310.000000, -25.000000) translate(302.000000, 17.000000)">
                                      <g>
                                          <mask id="mask-2">
                                              <use xlinkHref="#path-1"></use>
                                          </mask>
                                          <g id="Clip-2"></g>
                                          <path d="M16.0000604,7.99165217 C16.0000604,7.94643478 15.9910261,7.90052174 15.9736348,7.85878261 C15.9583304,7.82121739 15.9346783,7.78921739 15.9082435,7.76 C15.9040696,7.75513043 15.9026783,7.75026087 15.8985043,7.7453913 L8.26650435,0.10226087 C8.13015652,-0.0340869565 7.91033043,-0.0340869565 7.77398261,0.10226087 C7.63833043,0.237913043 7.63833043,0.45773913 7.77398261,0.594086957 L14.8112,7.64104348 L0.348591304,7.6 C0.156591304,7.6 0.000765217391,7.75513043 6.03764507e-05,7.94713043 C-0.000626086957,8.13843478 0.1552,8.29495652 0.346504348,8.29565217 L14.8139826,8.33669565 L7.77398261,15.3669565 C7.63833043,15.5026087 7.63833043,15.7231304 7.77398261,15.8587826 C7.84146087,15.9269565 7.9312,15.9603478 8.02024348,15.9603478 C8.10928696,15.9603478 8.19833043,15.9269565 8.2658087,15.8587826 L15.8978087,8.23791304 C15.901287,8.23373913 15.9019826,8.22956522 15.9054609,8.22608696 C15.933287,8.19617391 15.9569391,8.16278261 15.9729391,8.12452174 C15.9889391,8.08486957 15.9965913,8.04382609 15.9979826,8.0013913 C15.9979826,7.99791304 16.0000604,7.99513043 16.0000604,7.99165217 Z" id="Fill-1"></path>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
      )
      case 'arrow-left':
      return (
        <svg fill={iconColor} height={height ? height : '16px'} width={width ? width : '9px'} viewBox="0 0 9 16" xmlns="http://www.w3.org/2000/svg">
          <path d="m-5.05263158 2.52631579v.68665674l10.23735095.02336073.02546467 10.23735094h.6845528v-10.94736841z" fillRule="evenodd" transform="matrix(-.70710678 -.70710678 .70710678 -.70710678 3.140874 13.954584)"/>
        </svg>
      )
      case 'arrow-right':
      return (
        <svg fill={iconColor} height={height ? height : '16px'} width={width ? width : '9px'} viewBox="0 0 9 16" xmlns="http://www.w3.org/2000/svg">
          <path d="m-5.05263158 2.52631579v.68665674l10.23735095.02336073.02546467 10.23735094h.6845528v-10.94736841z" fillRule="evenodd" transform="matrix(.70710678 -.70710678 -.70710678 -.70710678 6.280178 13.954584)"/>
        </svg>
      )
      case 'arrow-top-right':
      return (
        <svg fill={iconColor} height={height ? height : '10px'} width={width ? width : '10px'} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
          <path d="m92.2769135 0v.76804916h8.4046355l-8.681549 8.69278033.553827.53917051 8.668326-8.67895545v8.4093702h.777847v-9.73041475z" fillRule="evenodd" transform="translate(-92)"/>
        </svg>
      )
      case 'calendar':
      return (
        <svg fill={iconColor} height={height ? height : '20px'} width={width ? width : '20px'} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="m13.8513043 0v2.59391304h-7.51652169v-2.59391304h-.86956522v2.59391304h-5.46521739v17.40608696h20v-17.40608696h-5.2791304v-2.59391304zm.8695653 5.19826087v-1.73478261h4.4086956v15.66782604h-18.25999998v-15.66782604h4.59565217v1.73478261h.86956522v-1.73478261h7.51652169v1.73478261z" fillRule="evenodd"/>
        </svg>
      )
      case 'Facebook':
      return (
        <svg fill={iconColor} height={height ? height : '15px'} width={width ? width : '8px'} viewBox="0 0 8 15" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <mask id="a">
            <path d="m.0001.0001875h7.1899v14.3138125h-7.1899z" fillRule="evenodd"/>
          </mask>
          <path d="m.0001 4.6975h1.528v-1.438c-.09-.809.09-1.618.539-2.247.539-.719 1.189-.894 2.337-.988.899-.091 1.887.09 2.786.269l-.27 2.247c-.359-.09-.809-.18-1.258-.18-.629 0-1.168.18-1.168.809v1.708h2.426l-.179 1.977h-2.247v7.46h-2.966v-7.46h-1.528z" fillRule="evenodd"/>
        </svg>
      )
      case 'Instagram':
      return (
        <svg fill={iconColor} height={height ? height : '17px'} width={width ? width : '16px'} viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <mask id="a">
            <path d="m.0001.4001h15.5999v15.5999h-15.5999z" fillRule="evenodd"/>
          </mask>
          <g fillRule="evenodd">
            <path d="m7.8001.4001h-3.2c-.654.004-1.3.141-1.9.4-1.064.397-1.903 1.236-2.3 2.3-.212.614-.346 1.252-.4 1.9v6.4c.005.654.141 1.3.4 1.9.397 1.063 1.236 1.902 2.3 2.3.614.212 1.252.346 1.9.4h6.4c.654-.005 1.3-.141 1.9-.4 1.064-.398 1.902-1.237 2.3-2.3.212-.614.346-1.253.4-1.9v-6.37c-.005-.654-.141-1.3-.4-1.9-.398-1.063-1.236-1.903-2.3-2.3-.614-.212-1.253-.347-1.9-.4zm0 1.3999363h3.2c.515-.0029363 1.025.1000637 1.5.3000637.354.103.669.312.9.6.255.259.46.564.6.9.151.488.251.991.3000289 1.5v6.4c.0019711.515-.1000289 1.025-.3000289 1.5-.103.354-.313.669-.6.9-.259.256-.564.459-.9.6-.489.15-.991.251-1.5.3000289h-6.4c-.515.0019711-1.026-.1000289-1.5-.3000289-.355-.103-.669-.312-.9-.6-.256-.259-.46-.564-.6-.9-.151-.489-.251-.991-.30002885-1.5v-6.4c-.00197115-.515.10002885-1.026.30002885-1.5.103-.354.312-.669.6-.9.259-.256.564-.46.9-.6.489-.151.991-.251 1.5-.3000637z" transform="translate(0 .6)"/>
            <path d="m7.8001 11.4c-1.436 0-2.6-1.164-2.6-2.6s1.164-2.6 2.6-2.6 2.6 1.164 2.6 2.6c.045 1.391-1.046 2.555-2.437 2.6-.055.002-.109.002-.163 0m0-6.6c-2.209 0-4 1.791-4 4s1.791 4 4 4 4-1.791 4-4-1.791-4-4-4"/>
            <path d="m11.9998 3.6998c.497 0 .9.403.9.9s-.403.9-.9.9-.9-.403-.9-.9.403-.9.9-.9"/>
          </g>
        </svg>
      )
      case 'logo':
      return (
        <svg fill={iconColor} height={height ? height : '86.45px'} width={width && width} enableBackground="new 0 0 551.42 86.45" viewBox="0 0 551.42 86.45" xmlns="http://www.w3.org/2000/svg">
          <path d="m133.6 14.14h-39.16v71.33h14.29v-62h32.04c4.6 0 14.83 3.17 14.83 13.17s-8.89 12-12.95 12c-7.39 0-29.28 0-29.28 0v9.33h27.9 7.67c4.08 0 8.71 3.75 8.71 8.33v19.17h14.29s.64-18.52 0-24.17c-.48-4.23-5.33-9.17-14-9.67 0 0 11.18-4.5 11.5-16 .33-11.83-15.42-21.5-28.67-21.5-2.79.01-7.17.01-7.17.01z"/>
          <path d="m392.73 85.47h-14.29v-20.16s0-18.45 0-24.6c0-10-11.85-17.23-17.87-17.23-8.04 0-33.04 0-33.04 0v62h-14.29v-71.34h40.17c-.61-.01 5.85-.03 11.2 0 8.93.04 28.14 12.21 28.14 25.46-.02 11.67-.02 45.87-.02 45.87z"/>
          <path d="m504.79 14.14s-.48 42.87.43 55.59c.49 6.91 8.28 16.72 22.88 16.72h23.31v-9.45h-23.15s-9.19.19-9.19-7.56v-55.3z"/>
          <path d="m205.89 55.39-5.15-4.48c-4.11-3.81-8.1-7.81-10.05-10.69-1.41-2.09-3.03-5.31-3.03-9.19 0-11.06 12.34-17.34 21.69-17.8 9.58-.47 17.17 1.63 17.17 1.63v8.94s-11.56-.69-15.87-.56c-5.12.15-9.37 2.19-9.37 7.94 0 4.04 1.61 6.18 3.06 7.64 3.15 3.17 10.16 8.73 13.44 11.77 3.16 2.93 7.87 7.68 8.1 15.09.04 1.2.02 1.52-.05 2.72-.6 10.61-13.68 17.24-22.68 17.91-5.54.42-21-.21-21-.21v-9.04s13.79 1.29 20.02.5c4.86-.62 10.75-4.54 10.75-11.29v-.54c-.01-2.26-1.44-6.45-7.03-10.34"/>
          <path d="m301.77 23.47v-9.33h-31.28s-8.22 0-11.47 0c-7.92 0-19.5 6.7-19.5 20.14 0 7.39 5.45 12.99 8.33 15.11-4.54 3.13-9.33 8.66-9.33 15.84 0 14.41 10.32 20.25 16.92 20.25h20.19 26.15v-9.44h-26.15s-10.39 0-13.85 0c-3.81 0-10.5-2.86-10.5-11.36s6.81-10.25 10.5-10.25h13.85 18.15v-9.33s-24 0-32 0c-3.69 0-10.5-2.86-10.5-11.36s7-10.25 10.5-10.25c9.99-.02 39.99-.02 39.99-.02z"/>
          <path d="m449.01 22.14c18.96.1 24.16 11.44 24.16 16.71v9.79h-44.27v9.33h44.27v27.5h14.29s0-35.87 0-47.67c0-7.21-10.47-24.12-38.33-24.48h-.83c-27.87.37-38.33 17.27-38.33 24.48v47.67h14.29v-36.83c0-4.36 0-7.04 0-9.79 0-5.27 5.2-16.6 24.14-16.71z"/>
          <path d="m39.05 22.14c18.96.1 24.16 11.44 24.16 16.71v9.79h-44.27v9.33h44.27v27.5h14.29s0-35.87 0-47.67c0-7.21-10.47-24.12-38.33-24.48h-.83c-27.87.37-38.34 17.28-38.34 24.49v47.66h14.29v-36.83c0-4.36 0-7.04 0-9.79 0-5.27 5.2-16.6 24.14-16.71z"/>
          <path d="m37.7 7c-17.96 0-25.3 5.17-25.3 5.17v-7.67s10.53-4.5 25.3-4.5h2.1c14.77 0 25.3 4.5 25.3 4.5v7.67s-7.33-5.17-25.3-5.17z"/>
        </svg>
      )
      case 'logo-group':
      return (
        <svg className="font-secondary" fill={iconColor} height={height ? height : '84px'} width={width && width} viewBox="0 0 332 84" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <mask id="a">
            <path d="m.24262464.33365909h31.69216666v7.32755114h-31.69216666z" fillRule="evenodd"/>
          </mask>
          <g fillRule="evenodd">
            <g transform="translate(0 31)">
              <path d="m80.3558486 9.11840909h-23.5569638v42.96192041h8.595913v-37.340909h19.2716377c2.7642609 0 8.9212971 1.9073977 8.9212971 7.930125 0 6.0227272-5.3450797 7.2272727-7.7875652 7.2272727h-17.6110362v5.6210113h16.7780289 4.6107102c2.4563188 0 5.2380217 2.2585228 5.2380217 5.019341v11.543159h8.595312s.386732-11.1558977 0-14.5545227c-.288696-2.5458068-3.207529-5.5210341-8.4202902-5.8221704 0 0 6.7223982-2.7132387 6.9166662-9.6363637.200884-7.1266932-9.2719416-12.94886361-17.2411445-12.94886361z"/>
              <path d="m236.206091 52.0803295h-8.595311v-12.146034-14.8165114c0-6.0227273-7.124768-10.3783636-10.750906-10.3783636h-19.873087v37.340909h-8.595913v-42.96192041s27.6805-.15779545 30.891638 0c5.376355.26439773 16.923579 7.35254541 16.923579 15.33265911z"/>
              <path d="m303.606001 9.11840909s-.288094 25.81882951.258021 33.47732951c.297116 4.1629091 4.979399 10.0706023 13.762964 10.0706023h14.021587v-5.6860568h-13.921145s-5.525514.1156364-5.525514-4.5519773v-33.30989771z"/>
              <path d="m123.831128 33.9598102-3.096261-2.6975795c-2.471355-2.29225-4.87234-4.7055568-6.04276-6.4370909-.85045-1.2569432-1.820587-3.1956591-1.820587-5.5324773 0-6.6629432 7.420681-10.44220455 13.043029-10.71925 5.760681-.28367045 10.325681.97989773 10.325681.97989773v5.38492047s-6.95516-.4125568-9.548008-.3384773c-3.082427.0879318-5.638586 1.3171705-5.638586 4.7802386 0 2.4325796.968333 3.7214432 1.841036 4.5995569 1.894565 1.9067954 6.11313 5.2590454 8.082877 7.0875454 1.898173 1.7616477 4.732202 4.6248523 4.869934 9.0858864.022855.7227273.013232.9178636-.027666 1.6399886-.363276 6.3919205-8.229631 10.3801705-13.638464 10.7873068-3.332631.2511478-12.629833-.1270795-12.629833-.1270795v-5.4439432s8.294587.7781364 12.038007.3011364c2.923043-.3722046 6.464377-2.7349205 6.464377-6.8002614v-.3264318c0-1.3551136-.85887-3.8786364-4.222776-6.2238864"/>
              <path d="m181.499468 14.7394205v-5.62101141h-18.813935-6.898021c-4.761674 0-11.728261 4.03281821-11.728261 12.12977271 0 4.4483864 3.277898 7.8235227 5.011877 9.1003409-2.731182 1.8821023-5.613327 5.2126705-5.613327 9.5369887 0 8.6817613 6.207558 12.1948181 10.174718 12.1948181h12.141456 15.725493v-5.6860568h-15.725493-8.332478c-2.293326 0-6.315217-1.7243068-6.315217-6.843625s4.097072-6.1732954 6.315217-6.1732954h8.332478 10.913899v-5.6210114h-19.246377c-2.218145 0-6.315217-1.7243068-6.315217-6.843625s4.210145-6.1732954 6.315217-6.1732954z"/>
              <path d="m270.054093 13.936892c11.401072.0614319 14.530413 6.8893978 14.530413 10.0627728v5.8974545h-26.62616v5.6210114h26.62616v16.5625h8.595913v-28.7085341c0-4.3411818-6.29537-14.52501137-23.055957-14.74544319h-.500405c-16.759986.22043182-23.055957 10.40426139-23.055957 14.74544319v28.7085341h8.595913v-22.1835114-5.8974545c0-3.1721705 3.127536-9.9983296 14.521993-10.0627728z"/>
              <path d="m23.485692 13.936892c11.4016739.0614319 14.5310145 6.8893978 14.5310145 10.0627728v5.8974545h-26.6267608v5.6210114h26.6267608v16.5625h8.5953116v-28.7085341c0-4.3411818-6.2953695-14.52501137-23.0559565-14.74544319h-.4998044c-16.76058691.22043182-23.05595648 10.40426139-23.05595648 14.74544319v28.7085341h8.5953116v-22.1835114-5.8974545c0-3.1721705 3.12753628-9.9983296 14.52199278-10.0627728z"/>
              <path d="m15.457487 4.54926705c-10.80443483 0-15.21486236 3.11194318-15.21486236 3.11194318v-4.617625s6.33205797-2.71022727 15.21486236-2.71022727h1.262442c8.8828043 0 15.2148623 2.71022727 15.2148623 2.71022727v4.617625s-4.4104275-3.11194318-15.2148623-3.11194318z" transform="translate(7.217391 .268794)"/>
            </g>
            <text fontSize="20">
              <tspan x="0" y="19">GRUPO</tspan>
              <tspan x="19.013672" y="47.431641"/>
              <tspan x="19.013672" y="69.431641"/>
            </text>
          </g>
        </svg>
      )
      case 'oval-fill':
      // no color prop
      return (
        <svg height={height ? height : '43px'} width={width ? width : '43px'} viewBox="0 0 43 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="STATUS" transform="translate(-211.000000, -9909.000000)" fill="#FFFFFF" fillRule="nonzero" stroke="#FFFFFF">
                    <path d="M233.344931,9951.84493 C244.390626,9951.84493 253.344931,9942.89063 253.344931,9931.84493 C253.344931,9920.79924 243.890626,9908.84493 232.844931,9908.84493 C221.799236,9908.84493 213.344931,9920.79924 213.344931,9931.84493 C213.344931,9942.89063 222.299236,9951.84493 233.344931,9951.84493 Z" id="Oval-Copy" transform="translate(233.344931, 9930.344931) rotate(45.000000) translate(-233.344931, -9930.344931) "></path>
                </g>
            </g>
        </svg>
      )
      case 'oval-left':
      return (
        <svg fill={iconColor} height={height ? height : '42px'} width={width ? width : '42px'} viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
                <g id="00.00_home" transform="translate(-30.000000, -4029.000000)" fillRule="nonzero">
                    <g id="Actividades-Copy-3" transform="translate(-143.000000, 3145.000000)">
                        <g id="Arrow_gallery-Copy" transform="translate(193.000000, 904.500000) scale(-1, 1) translate(-193.000000, -904.500000) translate(163.000000, 875.000000)">
                            <path d="M30,51 C41.045695,51 50,42.045695 50,31 C50,19.954305 40.545695,8 29.5,8 C18.454305,8 10,19.954305 10,31 C10,42.045695 18.954305,51 30,51 Z" id="Oval-Copy-3" transform="translate(30.000000, 29.500000) rotate(45.000000) translate(-30.000000, -29.500000) "></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      )
      case 'oval-line':
      return (
        <svg fill={iconColor} height={height ? height : '44px'} width={width ? width : '43px'} viewBox="0 0 43 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="03.00_horario" transform="translate(-160.000000, -13152.000000)" fillRule="nonzero" stroke="#575757">
                    <g id="Group-5" transform="translate(-696.000000, 11792.000000)">
                        <g id="Footer" transform="translate(848.000000, 1025.000000)">
                            <g transform="translate(0.000000, 100.000000)" id="Group-9">
                                <g transform="translate(0.000000, 180.000000)">
                                    <g id="RRSS" transform="translate(0.000000, 47.000000)">
                                        <path d="M30,51 C41.045695,51 50,42.045695 50,31 C50,19.954305 40.545695,8 29.5,8 C18.454305,8 10,19.954305 10,31 C10,42.045695 18.954305,51 30,51 Z" id="Oval-Copy-3" transform="translate(30.000000, 29.500000) rotate(45.000000) translate(-30.000000, -29.500000) "></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      )
      case 'oval-right':
      return (
        <svg fill={iconColor} height={height ? height : '42px'} width={width ? width : '42px'} viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
                <g id="00.00_home" transform="translate(-1368.000000, -4029.000000)" fillRule="nonzero">
                    <g id="Actividades-Copy-3" transform="translate(-143.000000, 3145.000000)">
                        <g id="Arrow_gallery-Copy" transform="translate(1503.000000, 875.000000)">
                            <path d="M30,51 C41.045695,51 50,42.045695 50,31 C50,19.954305 40.545695,8 29.5,8 C18.454305,8 10,19.954305 10,31 C10,42.045695 18.954305,51 30,51 Z" id="Oval-Copy-3" transform="translate(30.000000, 29.500000) rotate(45.000000) translate(-30.000000, -29.500000) "></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      )
      case 'remove':
      return (
        <svg fill={iconColor} height={height ? height : '20px'} width={width ? width : '20px'} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
              <g id="03.00_horario_filtered" transform="translate(-172.000000, -615.000000)">
                  <g id="Group-8" transform="translate(172.000000, 615.000000)">
                      <path d="M20,0 L19.9999517,0.707951673 L0.707951673,19.9999517 L0,20 L-4.83272051e-05,19.2929517 L19.2929517,-4.83272051e-05 L20,0 Z" id="Combined-Shape"></path>
                      <path d="M0.706951673,-4.83272051e-05 L19.9999517,19.2929517 L20,20 L19.2929517,19.9999517 L-4.83272051e-05,0.707951673 L0,0 L0.706951673,-4.83272051e-05 Z" id="Combined-Shape"></path>
                  </g>
              </g>
          </g>
      </svg>
      )
      case 'Twitter':
      return (
        <svg fill={iconColor} height={height ? height : '14px'} width={width ? width : '17px'} viewBox="0 0 17 14" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <mask id="a">
            <path d="m0 .00021973h15.7599v12.98758027h-15.7599z" fillRule="evenodd"/>
          </mask>
          <path d="m15.32.24148027c-.616.378-1.292.648-2 .8-1.187-1.302-3.204-1.396-4.506-.21-.022.02-.043.04-.064.06-.839.815-1.195 2.008-.94 3.15-2.611-.138-5.036-1.391-6.66-3.44-.852 1.505-.419 3.411 1 4.4-.516-.016-1.02-.156-1.47-.41-.011 1.554 1.071 2.903 2.59 3.23-.277.076-.563.113-.85.11-.205 0-.409-.021-.61-.06.41 1.323 1.615 2.23900003 3 2.28000003-1.353 1.086-3.088 1.58-4.81 1.37 4.211 2.733 9.841 1.535 12.574-2.67600003.039-.061.078-.123.116-.184.936-1.501 1.434-3.232 1.44-5v-.43c.642-.468 1.193-1.047 1.63-1.71-.59.267-1.217.442-1.86.52.674-.41 1.179-1.049 1.42-1.8" fillRule="evenodd" transform="translate(.612903 .193548)"/>
        </svg>
      )
      case 'User':
      return (
        <svg fill={iconColor} height={height ? height : '23.83px'} width={width ? width : '24px'} enableBackground="new 0 0 23.83 24" viewBox="0 0 23.83 24" xmlns="http://www.w3.org/2000/svg"><path d="m17.38 12.15c.73-1.25 1.16-2.76 1.16-4.38 0-4.28-2.97-7.77-6.62-7.77-3.66 0-6.63 3.49-6.63 7.77 0 1.62.43 3.13 1.16 4.38-3.78 1.11-6.45 4.63-6.45 8.62v3.23h23.83v-3.23c0-4-2.67-7.51-6.45-8.62zm-5.47-11.15c3.1 0 5.62 3.04 5.62 6.77s-2.52 6.77-5.62 6.77-5.62-3.04-5.62-6.77 2.52-6.77 5.62-6.77zm10.92 22h-21.83v-2.23c0-3.66 2.53-6.87 6.05-7.74 1.21 1.54 2.94 2.51 4.86 2.51s3.65-.97 4.86-2.51c3.53.88 6.05 4.08 6.05 7.74v2.23z"/></svg>
      )
    }
  }
  return (
    <div className={className ? className : ''}>
     {Icon()}
    </div>
  )
}

export default Svg

// @flow

import React from 'react'
import Appear from './Appear'
import type { Image } from 'types/'
import ContainerGrid from './ContainerGrid'
import Button from './Button'
import DropCapText from './DropCapText'
import ImageLazy from './ImageLazy'

type Props = {
  title: string,
  subtitle: string,
  text: string,
  image: Image,
  ctaLabel: string,
  ctaHref: string,
}

const HeadingHome = ({ title, subtitle, text, image, ctaLabel, ctaHref }: Props) => {

  return (
    <Appear>
      <div className="mt-20 mb-28 md:mt-84 md:mb-64">
        <ContainerGrid>
          <div className="grid grid-cols-4 gap-4 md:grid-cols-10 md:gap-6 lg:gap-8">
            <h1 className="col-span-3 mb-4 leading-none text-gray font-primary text-big3 md:text-huge1 md:mb-10">{title}</h1>
            <div className="col-span-4 md:col-start-1 md:col-span-5">
              <div className="mb-10 leading-none text-gray font-primary md:w-4/5 text-bigger4 md:text-big2 md:mb-16">{subtitle}</div>
              <DropCapText text={text} />
              <div className="mt-10 mb-16 md:mt-16 md:mb-0 md:ml-20% md:w-3/5">
                <Button cta={{ label: ctaLabel, href: ctaHref }} internalLink={true} />
              </div>
            </div>
            <div className="col-span-4 md:col-start-6 md:col-span-5">
              {image && <Appear>
                <ImageLazy className="w-full h-auto" image={image} alt={title} />
              </Appear>}
            </div>
          </div>
        </ContainerGrid>
      </div>
    </Appear>
  )
}

export default HeadingHome

// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import type { Image } from 'types/'
import ContainerGrid from './ContainerGrid'
import Button from './Button'
import DropCapText from './DropCapText'
import useIsMobile from '../hooks/useIsMobile'
import ImageLazy from './ImageLazy'

type Props = {
  title: string,
  text: string,
  image: Image,
  ctaLabel: string,
  ctaHref: string,
  pageName?: string
}

const Heading = ({ title, text, image, ctaLabel, ctaHref, pageName }: Props) => {
  const hasProps = (
    title !== ""
    || text  !== ""
  )

  const envCityMadrid = process.env.GATSBY_city === 'Madrid'

  const isMobile = useIsMobile()
  const [windowHeight, setWindowHeight] = useState()
  const [textSize, setTextSize] = useState(100)
  const [marginBottom, setMarginBottom] = useState('mb-24')
  const [marginTop, setMarginTop] = useState('md:mt-36')

  const handleResize = () => {
    const height = document.documentElement.clientHeight || document.body.clientHeight
    setWindowHeight(height)
  }

  const handleTitleStyleDesktop = () => {
    if  (windowHeight) {
      if (windowHeight > 800) {
        setTextSize(100)
        setMarginBottom('mb-24')
        setMarginTop('md:mt-36')
      }
      if (windowHeight < 800) {
        setTextSize(90)
        setMarginBottom('mb-20')
        setMarginTop('md:mt-32')
      }
      if (windowHeight < 700) {
        setTextSize(80)
        setMarginBottom('mb-16')
        setMarginTop('md:mt-28')
      }
      if (windowHeight < 600) {
        setTextSize(70)
        setMarginBottom('mb-12')
        setMarginTop('md:mt-24')
      }
      if (windowHeight < 500) {
        setTextSize(60)
        setMarginBottom('mb-10')
        setMarginTop('md:mt-20')
      }
      if (windowHeight < 400) {
        setTextSize(50)
        setMarginBottom('mb-8')
        setMarginTop('md:mt-16')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    handleTitleStyleDesktop()
  }, [windowHeight])

  const customMargins = pageName === "activities" ? `mt-16 mb-20 ${marginTop} md:mb-0` : `mt-16 mb-20 ${marginTop} md:mb-56`

  if (!hasProps) return null

  return (
    <Appear>
    <div className={customMargins}>
      {isMobile
      ? <ContainerGrid>
        <div className="grid grid-cols-4 gap-4 md:hidden">
          <h2 className={`${pageName === "services" ? "col-span-4 mr-2 md:mr-0" : "col-span-3"} row-start-1 mb-6 leading-none md:mb-10 text-gray font-primary text-big3`}>
            {title}
          </h2>
          <div className="col-span-4 row-start-2">
            <DropCapText text={text} />
          </div>
          {ctaLabel && !envCityMadrid && <div className="col-span-4 my-6 md:mb-10 md:mt-12">
            <Button cta={{ href: ctaHref, label: ctaLabel }} darkMode={true} internalLink={true} disabled={pageName === "services"}/>
          </div>
          }
          <div className='col-span-4'>
          {image && <Appear>
              <ImageLazy className="w-full h-auto" image={image} alt="" />
            </Appear>}
          </div>
        </div>
      </ContainerGrid>
      : <ContainerGrid mdCleanLeft lgCleanLeft>
        <div className="hidden grid-cols-11 gap-6 ml-6 md:grid lg:ml-8 lg:gap-8">
          <h2
          className={`${pageName === "services" ? "col-span-8" : "col-span-5"} col-start-2 row-start-1 ${marginBottom} leading-none text-gray font-primary`}
          style={{
            fontSize: textSize
          }}
          >{title}</h2>
          <div className="col-span-5 col-start-1 row-start-2">
            <DropCapText text={text} />
            {ctaLabel && !envCityMadrid && <div className="mt-12 ml-20% w-3/5">
              <Button cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} internalLink={true} disabled={pageName === "services"}/>
            </div>}
          </div>
            <div className="col-span-5 col-start-7 row-start-2">
            {image && <Appear>
                <ImageLazy className="w-full h-auto" image={image} alt="" />
              </Appear>}
            </div>
          </div>
        </ContainerGrid>
        }
      </div>
    </Appear>
  )
}

export default Heading

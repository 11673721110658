// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import moment from 'moment'
import localization from 'moment/locale/es'
import useIsMobile from '../hooks/useIsMobile'
import ActivityTableDesktop from './ActivityTableDesktop'
import ActivityTableMobile from './ActivityTableMobile'
import Button from './Button'

type Tag = {
  id: string,
  title: string,
  color?: string,
}

type Activity = {
  id: string,
  activityId: string,
  day: string,
  dateFrom: string,
  dateTo: string,
  hourFrom: string,
  hourTo: string,
  room: Object,
  name: string,
  teacher: string,
  tags: Array<Tag>,
  description: string,
  ctaLabel: string,
  ctaHref: string,
  exceptionDates: Array<string>,
}

type Props = {
  title: string,
  ctaLabel: string,
  ctaHref: string,
  days: Array<string>,
  rooms: Array<string>,
  items: Array<Activity>,
  pageName: string,
  showNoScheduleLiteral: boolean,
  noSchedule: string,
  noResultsFound: string,
  cancel: string,
  book_activity: string,
  name: string,
  email: string,
  valid_activity_register: string,
  literalErrorEmail: string,
  literalErrorCapacity: string,
  LiteralErrorRegistered: string,
  literalError: string,
}

const ActivityCalendar = ({
  title,
  ctaLabel,
  ctaHref,
  days,
  rooms,
  items,
  pageName,
  noSchedule,
  showNoScheduleLiteral,
  noResultsFound,
  cancel,
  book_activity,
  name,
  email,
  valid_activity_register,
  literalErrorEmail,
  literalErrorCapacity,
  LiteralErrorRegistered,
  literalError,
}: Props) => {

  const isMobile = useIsMobile()
  const [today] = useState(moment().isoWeekday(1))
  const [todayItems, setTodayItems] = useState([])
  const [filteredItems, setFilteredItems] = useState(items)

  function getHours(items) {
    // Get array without duplicated hours
    const all = []
    items.forEach((activity) => {
      const start = activity.hourFrom
      if (!all.includes(start)) {
        all.push(start)
      }
    })
    // Split hours & minutes and get integers
    const split = (
        all.map(hour => {
        const splitHour = hour.split(":")
        const splitHourInt = splitHour.map(item => parseInt(item))
        const h = splitHourInt[0]
        const m = splitHourInt[1]
        const total = (h * 100) + m
        return {
          int: total,
          string: hour
          }
      })
    )
    // order integers & return ordered array
    split.sort((a, b) => { return a.int - b.int })
    return split
  }
  
  const itemsHours = getHours(items)

  // Filter by date
  moment.updateLocale("es", localization)
  const itemsOnWeek = () => {
    let newItems = []
    items.map(activity => {
      const dateIsBewteen = today.isBetween(moment(activity.dateFrom), moment(activity.dateTo))
      const isOnCurrentWeek = moment(activity.dateFrom).isSame(today, "week")
      if (dateIsBewteen || isOnCurrentWeek === true) {
        newItems.push(activity.name)
      }
    })
    setTodayItems(newItems)
  }

  // Update filters on week change
  useEffect(() => {
    itemsOnWeek()
  }, [today])

  // Filtered items
  useEffect(() => {
    let newItems = []
    items.map(item =>{
      if (todayItems.includes(item.name)) {
        newItems.push(item)
      }
    })
    setFilteredItems(newItems)
  }, [todayItems])

  const literalText = {
    noResultsFound,
    cancel,
    book_activity,
    name,
    email,
    valid_activity_register,
    literalErrorEmail,
    literalErrorCapacity,
    LiteralErrorRegistered,
    literalError,
  }

  // Get default tab for activity table mobile
  const [defaultTab, setDefaultTab] = useState(0)
  useEffect(() => {
    const today = moment(new Date())
    const day = today.day()
    setDefaultTab(day - 1)
  }, [])

  const calendar = isMobile === true ? (
    <ActivityTableMobile
      items={filteredItems}
      days={days}
      cta={{ label: ctaLabel, href: ctaHref }}
      pageName={pageName}
      defaultTab={defaultTab}
      ctaHref={ctaHref}
      literals={literalText}
    />
  ) : (
    <ActivityTableDesktop
      items={filteredItems}
      disableHover={true}
      cta={{ label: ctaLabel, href: ctaHref }}
      days={days}
      rooms={rooms}
      hours={itemsHours}
      literals={literalText}
    />
  )

  return (
    <Appear>
      <div className="grid grid-cols-4 gap-4 mx-4 mt-10 mb-24 md:grid-cols-12 md:gap-6 lg:gap-8 md:mx-6 lg:mx-8 text-gray md:mt-32">
        <h3 className="col-span-4 mb-2 leading-none md:col-span-5 font-primary text-big1 md:text-big4 md:mb-6 lg:mb-4">{title}</h3>
        <div className="items-center justify-between hidden col-span-4 mt-6 mb-2 md:flex md:flex-col md:my-0 md:mb-6 lg:mb-4 md:col-span-3 md:col-end-13 md:items-stretch md:justify-end">
          <Button internalLink={true} cta={{ label: ctaLabel, href: ctaHref }} />
        </div>
        {showNoScheduleLiteral ? <div className="col-span-4 font-bold md:col-span-12 text-bigger2 font-secondary">{noSchedule}</div> : calendar}
      </div>
    </Appear>
  )
}

export default ActivityCalendar

// @flow

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import ButtonSecondary from './ButtonSecondary'
import Svg from './Svg'
import sessionService from '../services/session.service'
import { navigate } from 'gatsby'
import HTMLText from './HTMLText'
import { createEvent } from "ics"
import { useGlobalState } from 'store/'

type Props = {
  item: Object,
  literals: Object,
  alertCancelActivity: string,
}
const BookingActivityInfo = ({
  item,
  literals,
  alertCancelActivity,
}: Props) => {
  const [session, dispatch] = useGlobalState()
  const [canCancel, setCanCancel] = useState(true)

  const getRemainingTime = () => {
  const bookingDate = moment(`${item.date} ${item.hourFrom}`).format('YYYY-MM-DD HH:mm:ss')
  const futureActivity = moment(bookingDate).isAfter()
  const remainingTime = moment(bookingDate).fromNow()
  const splitRemainingTime = remainingTime.split(' ')
    if (!futureActivity) {
      setCanCancel(false)
    }
    if (['hora', 'horas'].includes(splitRemainingTime[2]) && (Number(splitRemainingTime[1]) <= 2 || splitRemainingTime[1] === "una")) {
      setCanCancel(false)
    }
    if (['minuto', 'minutos', 'segundo', 'segundos'].includes(splitRemainingTime[2])) {
      setCanCancel(false)
    }
  }

  const handleCancelActivity = async (userId, token, registeredActivityId) => {
    const form = {
      userId,
      token,
      registeredActivityId,
    }
    const response = await sessionService.cancelActivity(form)
    if (response.valid) {
      location.reload()
    }
  }

  useEffect(() => {
    getRemainingTime()
  }, [])

  const {
    date,
    hourFrom,
    hourTo,
    activity,
    room,
    registeredActivityId,
  } = item

  function format(hour) {
    const arr = hour.split(":")
    arr.pop()
    return arr.join(":")
  }

  const formatted = `${moment(date).format("dddd DD")} ${literals.of} ${moment(date).format("MMMM")} ${literals.of} ${moment(date).format("YYYY")} de <strong>${format(hourFrom)} a ${format(hourTo)}  h</strong> | ${room}`


  return (
   <div
    className="grid grid-cols-4 gap-4 px-4 py-5 mb-4 border md:p-6 lg:p-8 md:grid-cols-10 md:gap-6 lg:gap-8 text-gray border-gray"
  >
    <div className="flex justify-start col-span-1 p-2 md:items-center items-left md:justify-center">
      <Svg name="calendar" color="gray" width={24} height={24} />
    </div>
    <div className="col-span-3 col-start-2 md:col-span-6 md:col-start-2 text-normal font-secondary">
      <h3 className="pb-5 font-light leading-none font-primary text-big1 md:text-big2">{activity}</h3>
      <HTMLText className="leading-tight" text={formatted} />
    </div>
    {canCancel && <div className="flex items-end justify-end col-span-1 col-start-4 md:col-start-10">
      <ButtonSecondary
        cta={{ label: 'Cancel', href: '' }}
        actionButton={true}
        actionButtonOnClick={() => {
            if (confirm(alertCancelActivity)) {
              //alert_cancel_activity
              handleCancelActivity(session.id, session.token, registeredActivityId)
            }
          }}
        isBookingInfoCard
      />
    </div>}
  </div>
  )
}

export default BookingActivityInfo

// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import { Formik, Form } from 'formik'
import { navigate } from 'gatsby'
import ContainerGrid from './ContainerGrid'
import DropCapText from './DropCapText'
import FormField from './FormField'
import '../styles/calendar.css'
import Button from './Button'
import ButtonSecondary from './ButtonSecondary'
import sessionService from '../services/session.service'
import { useGlobalState } from 'store/'
import HTMLText from './HTMLText'

type Props = {
  pageName: string,
  formTitle: string,
  placeholderEmail: string,
  placeholderPassword: string,
  signUpText: string,
  loginErrorText: string,
  terms: string,
  text: string,
  ctaLabel: string,
  ctaHref: string,
  signUpLabel: string,
  signUpHref: string,
  literalErrorEmail: string,
  literalErrorLogin: string,
  recoverLabel: string,
  recoverText: string,
  recoverHref: string,
}

const LoginForm = ({
    placeholderEmail,
    placeholderPassword,
    signUpText,
    text,
    ctaLabel,
    ctaHref,
    signUpLabel,
    signUpHref,
    literalErrorEmail,
    literalErrorLogin,
    recoverLabel,
    recoverText,
    recoverHref,
  }: Props) => {

  const [session, dispatch] = useGlobalState()
  const [opacity, setOpacity] = useState(1)
  const [validationErrors, setValidationErrors] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const opacityTimer = setTimeout(() => {
      setOpacity(0)
      }, 4800)

    const resetTimer = setTimeout(() => {
        setError(false)
        setOpacity(1)
      }, 5000)
    
    return () => { 
      clearTimeout(opacityTimer)
      clearTimeout(resetTimer)
    }
  }, [error])
  
  const Label = ({title}) => <label className="block col-span-4 mt-6 text-left uppercase md:col-start-1 md:mt-0 mb-10px md:col-span-6 font-secondary text-smaller">{title}</label>

  if (session) {
    navigate("/es/perfil-editar")
  }

  useEffect(() => {
  }, [session])

  return (
    <Appear>
      <ContainerGrid
        mdCleanLeft={true}
        lgCleanLeft={true}
      >
        <div className="grid grid-cols-1 gap-4 mb-48 md:grid-cols-11 md:gap-6 lg:gap-8 text-gray md:mb-84">
          <div data-name="left-column" className="hidden col-span-1 md:col-span-5 md:block">
            <DropCapText text={text} />
          </div>
          <Appear className="col-span-1 md:col-span-6">
            <Formik
              enableReinitialize={true}
              initialValues={{
                email: "",
                password: "",
              }}
              validate={ values => {
                const errors = {}
                const mailReg = new RegExp(/^([a-z0-9_.+-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/gm)
                Object.entries(values).forEach(field => {
                  if(field[0] === "email") {
                    if (
                      typeof field[1] === "string"
                      && field[1] !== ""
                      && !mailReg.test(field[1])
                    ) errors [field[0]] = true
                  }
                })
                setValidationErrors(errors)
                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setLoading(true)
                const response = await sessionService.doLogin(values)
                setSubmitting(false)
                if (response) {
                  dispatch({ type: 'login', payload: response })
                  setLoading(false)
                } else {
                  setError(true)
                  setLoading(false)
                }
              }}
            >
              { () => {
              return (
                <Form className="grid grid-cols-4 md:grid-cols-6 md:gap-6 lg:gap-5">
                  <div className="col-span-4 md:col-span-3">
                  <Label title={placeholderEmail} />
                  <FormField
                      type="email"
                      name="email"
                      placeholder={placeholderEmail}
                      error={validationErrors && validationErrors.email}
                      literalError={literalErrorEmail}
                      required={true}
                    />
                  </div>
                  <div className="col-span-4 md:col-span-3">
                  <Label title={placeholderPassword} />
                    <FormField
                      type="password"
                      name="password"
                      placeholder={placeholderPassword}
                      required={true}
                    />
                  </div>
                  <div className="col-span-4 mt-8 md:col-start-1 md:col-span-2 md:mt-0 lg:mt-6">
                    <Button submitButton={true} cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} loading={loading} />
                  </div>
                  {error &&
                    <Appear className="col-span-4 md:col-span-3 md:col-end-7">
                      <div
                        className="my-6 transition-opacity duration-150 ease-linear md:my-4 text-smaller md:mr-4"
                        style={{ opacity: opacity }}
                      >
                        <span className="text-red-500 font-secondary">{literalErrorLogin}</span>
                      </div>
                    </Appear>
                  }
                </Form>
              )}}
            </Formik>
              <div className="grid grid-cols-4 gap-4 pt-8 md:grid-cols-6 md:gap-6 lg:gap-5">
                <HTMLText className="col-span-4 col-start-1 font-secondary text-normal" text={recoverText}/>
                <div className="col-span-4 col-start-1">
                  <ButtonSecondary cta={{ href: recoverHref, label: recoverLabel }} />
                </div>
              </div>
            {/* {process.env.NODE_ENV !== "production" && ( */}
              <div className="grid grid-cols-4 gap-4 pt-8 md:grid-cols-6 md:gap-6 lg:gap-5">
                <HTMLText className="col-span-4 col-start-1 font-secondary text-normal" text={signUpText}/>
                <div className="col-span-4 col-start-1">
                  <ButtonSecondary cta={{ href: signUpHref, label: signUpLabel }} />
                </div>
              </div>
            {/* )} */}
          </Appear>
        </div>
      </ContainerGrid>
    </Appear>
  )
}

export default LoginForm

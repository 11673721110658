/* eslint-disable no-underscore-dangle */
/* eslint-disable no-throw-literal */
/* eslint-disable func-names */
// @flow
/*
  IMPORTANTE
  No utilices este servicio directamente desde un componente.
  Mejor crea un nuevo servicio que describa el código en el que se harán las peticiones.
*/
import endpoints from '../config/endpoints'

type RequestConfig = {
  method: string,
  body?: string | FormData | URLSearchParams,
  headers?: Object,
}
function HTTPService(apiURL): void {
  this.apiURL = apiURL
}

HTTPService.prototype._doFetch = async function (url: string, config: RequestConfig) {
  try {
    const response = await fetch(url, config)
    const parsed = await response.json()
    if (response.status === 200) {
      if (!parsed.valid) return parsed
      return parsed
    } else throw {
      ...parsed,
      status: response.status,
    }
  } catch (err) {
    console.log('err', err)
    throw err
  }
}

HTTPService.prototype.get = async function (
  endpoint: string,
  absoluteUrl: boolean,
): Object {
  const config: RequestConfig = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  }
  return this._doFetch(absoluteUrl ? endpoint : this.apiURL + endpoint, config)
}

HTTPService.prototype.post = async function (endpoint: string, body: Object) {
  const config: RequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }
  return this._doFetch(this.apiURL + endpoint, config)
}

HTTPService.prototype.postFormData = async function (url: string, body: Object) {
  const data = new FormData()
  if (body instanceof Object) {
    Object.keys(body).forEach((part) => {
      data.append(part, body[part])
    })
  }
  const config: RequestConfig = {
    method: 'POST',
    headers: {
    },
    body: data,
  }
  return this._doFetch(url, config)
}

HTTPService.prototype.put = async function (endpoint: string, body: any) {
  const config: RequestConfig = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  }
  return this._doFetch(this.apiURL + endpoint, config)
}

HTTPService.prototype.patch = async function (endpoint: string, body: any) {
  const config: RequestConfig = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  }
  return this._doFetch(this.apiURL + endpoint, config)
}

HTTPService.prototype.delete = async function (endpoint: string, body: Object) {
  const config: RequestConfig = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  }
  await this._doFetch(this.apiURL + endpoint, config)
}
const Service = new HTTPService(endpoints.apiURL)
export default Service
// @flow

import React, { useState, useEffect, useRef } from 'react'
import Appear from './Appear'
import type { Ref } from 'types/'
import type { Image } from 'types/'
import useIsMobile from '../hooks/useIsMobile'
import ContainerGrid from './ContainerGrid'
import ActivitiesItem from './ActivitiesItem'
import ActivityTag from './ActivityTag'
import FilterDropdown from './FilterDropdown'

type Tag = {
  id: string,
  title: string,
  color?: string,
  disabled?: boolean,
}

type ListItemType = {
  id: string,
  title: string,
  tags: Array<Tag>,
  url: string,
  calendarUrl: string,
  viewSchedule: string,
}

type Props = {
  dropdown: Object,
  title: string,
  tags: Array<Tag>,
  calendarIcon: Image,
  arrowIcon: Image,
  tagIcon: Array<string>,
  itemLink: string,
  mobileLinkMore: string,
  mobileLinkLess: string,
  listItems: Array<ListItemType>,
  dropdownPlaceholder: string,
  dropdownTitle: string,
  dropdownId: string,
  emptyStateLiteral: string
}

const Activities = (
  {
    dropdownPlaceholder,
    dropdownTitle,
    dropdownId,
    title,
    tags,
    calendarIcon,
    arrowIcon,
    tagIcon,
    itemLink,
    mobileLinkMore,
    mobileLinkLess,
    listItems,
    emptyStateLiteral
  }: Props) => {

  const isMobile = useIsMobile()
  const wrapperEl: Ref = useRef({})
  const topEl: Ref = useRef({})
  const defaultValue = 10

  const [filters, setFilters] = useState([])
  const [filteredListItems, setFilteredListItems] = useState(listItems)

  const [containerHeight, setContainerHeight] = useState(0)
  const [opened, setOpened] = useState(!isMobile)
  const [opacity, setOpacity] = useState(1)
  const [dropdownOpened, setDropdownOpened] = useState(false)
  const [expandable, setExpandable] = useState(filteredListItems.length > defaultValue)

  function handleTagClick(tag) {
    if (filters.includes(tag)) {
      setFilters([])
    } else {
      setFilters([tag])
    }
  }

  // Filter items
  useEffect(() => {
    const newItems = listItems.filter(item => {
      let shouldAdd = false
      item.tags.forEach(theTag =>{
        if (
          filters.length === 0
          || filters.filter(tag => (
            typeof tag.id === "string" && tag.id === theTag.id
          )).length > 0
        ) {
          shouldAdd = true
        }
      })
      return shouldAdd
    })
    setOpacity(0)
    const logicTimer = setTimeout(() => {
      setFilteredListItems(newItems)
    }, 200)
    const opacityTimer = setTimeout(() => {
      setOpacity(1)
    })

    return () => {
      clearTimeout(logicTimer)
      clearTimeout(opacityTimer)
    }
  }, [filters])

  // Filter items mobile
  const dropdownValue = (id, item) => {
    if (id === "1") {
      if (item.id === "-1") {
        setFilters([])
      } else {
        setFilters([item])
      }
      setOpened(false)
    }
  }

  // Reset filters on query change
  useEffect(() => {
    setFilters([])
    setDropdownOpened(false)
    dropdownValue( "-1", dropdownPlaceholder)
  }, [isMobile])

  // Check if list is expandable
  useEffect(() => {
    if (isMobile && filteredListItems.length > defaultValue) {
      setExpandable(true)
      setOpened(false)
    } else {
      setExpandable(false)
      setOpened(true)
    }
  }, [filters, filteredListItems])

  async function checkItemHeight() {
    const nodes = await wrapperEl.current.children
    const arr = Array.from(nodes)

    // height of all elements
    const heights = await arr.map(item => item.offsetHeight)
    // heights of first elements
    const first = heights.slice(0, defaultValue)

    return {
      default: first.reduce((a, b) => a + b, 0),
      all: heights.reduce((a, b) => a + b, 0)
    }
  }

  useEffect(() => {
    if (filteredListItems.length !== 0) {
      checkItemHeight().then(value => {
        if (opened) {
          setContainerHeight(value.all)
        } else {
          setContainerHeight(value.default)
          const { top } = topEl.current.getBoundingClientRect()
          const y = top + window.pageYOffset
          window.scrollTo({ top: y })
        }
      })
    } else setContainerHeight("auto")
  }, [opened, isMobile])

  function dropdownClick(id, value) {
    setDropdownOpened(value)
  }

  return (
    <Appear>
      <ContainerGrid>
        <div ref={topEl} className="grid grid-cols-4 gap-4 md:mt-20 md:mb-8 md:grid-cols-10 md:gap-6 lg:gap-8">
          <h3 className="col-span-3 leading-none text-gray font-primary md:text-big4 md:col-span-4 text-big1">{title}</h3>
        </div>
        <div
          data-name="filters-dropdown-mobile"
          className="mt-8 md:hidden"
        >
          <FilterDropdown
            placeholder={dropdownPlaceholder}
            title={dropdownTitle}
            id={dropdownId}
            items={tags}
            dropdownValue={dropdownValue}
            dropdownClick={dropdownClick}
          />
        </div>
        <div
          data-name="filters-tags-desktop"
          className="flex-wrap items-start justify-start hidden py-12 -mb-10px md:flex"
        >
          {tags.map((item, index) => {
            return (
              <ActivityTag
                key={index}
                item={item}
                src={tagIcon}
                isChecked={filters.includes(item)}
                onClickCallback={() => handleTagClick(item)}
              />
            )
          })}
        </div>
        <div className="hidden w-full h-auto py-px md:block bg-gray"/>
        <div
          ref={wrapperEl}
          className="grid grid-cols-1 gap-4 pb-4 mt-6 transition-all duration-200 ease-out md:pb-8 md:mb-56 md:grid-cols-2 md:gap-6 lg:gap-8"
          style={{
            maxHeight: `${containerHeight}px`, 
            minHeight: `${dropdownOpened ? "352px" : "auto"}`,
            opacity: opacity
          }}
        >
          {filteredListItems.length > 0 ? (
            filteredListItems.map(item => {
              return (
                <div key={item.id} className="-mb-4 md:-mb-6 lg:-mb-8">
                  <ActivitiesItem
                    tags={item.tags}
                    name={item.title}
                    calendarIcon={calendarIcon}
                    arrowIcon={arrowIcon}
                    itemLink={itemLink}
                    url={item.url}
                    viewSchedule={item.viewSchedule}
                    calendarUrl={item.calendarUrl}
                  />
                </div>
              )
            })
          ) : (
          <div className="col-span-2 leading-tight text-center font-primary text-bigger2 text-gray">{emptyStateLiteral}</div>
          )}
        </div>
      </ContainerGrid>
      <div className="w-full mt-10 text-center md:hidden">
        {expandable && (
          <button
            type="button"
            aria-label={opened ? mobileLinkLess : mobileLinkMore}
            className="inline border-b font-secondary text-gray text-small border-gray focus:outline-none"
            onClick={() => setOpened(!opened)}
          >
            {opened ? mobileLinkLess : mobileLinkMore}
          </button>
        )}
      </div>
    </Appear>
  )
}

export default Activities

// @flow

import React, { useState, useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import Button from './Button'
import ButtonSecondary from './ButtonSecondary'
import ActivityForm from './ActivityForm'
import { useGlobalState } from 'store/'

import moment from 'moment'

type Props = {
  cta: Object,
  day: string,
  hourFrom: string,
  hourTo: string,
  room: Object,
  color: string,
  name: string,
  description: string,
  hovered: boolean,
  start: string,
  setShouldHideOnMouseLeave: Function,
  activityId: string,
  tags: Object,
  close: Function,
  literals: Object,
  onHeightChange?: Function,
  userInfo: Object,
}

const CalendarHoverMobile = ({
  cta,
  day,
  hourFrom,
  hourTo,
  room,
  name,
  tags,
  description,
  start,
  activityId,
  close,
  literals,
  onHeightChange,
  userInfo,
}: Props) => {
  // const [session] = useGlobalState()
  const [loaded, setLoaded] = useState(false)
  const el = useRef(null)
  
  // Handle selected day literal
  const getDay = () => {
    const daysInMonth = moment(start).daysInMonth()
    const number = moment(start).date() + (Number(day) - 1)
    const result = number > daysInMonth ? number - daysInMonth : number
    return result
  }

  useEffect(() => {
    setLoaded(true)
  }, [])

  useEffect(() => {
    let value = 0
    if (loaded && el.current !== null) {
      value = el.current.offsetHeight
    }
    typeof onHeightChange !== "undefined" && onHeightChange(value)
  }, [loaded, el])

  const isBookable = moment(`${moment(start).year()}-${moment(start).month() + 1}-${getDay()} ${hourFrom}`).isAfter()
  // const userIsMember = session && session.hasMembership === true

  return (
    <>
      <div
        id="calendar-hover"
        className={`z-40 absolute top-0 cursor-default bg-white border text-gray border-gray transition-opacity duration-150 ease-linear`}
        style={{ minWidth: '100%' }}
        ref={el}
      >
        <div
          style={{ backgroundColor: `#${tags[0].color}` }}
          className={`w-10px absolute top-0 left-0 h-full border-r border-gray`}
        />
        <div className="p-5">
          <div className="font-secondary text-smaller">
            {hourFrom} - {hourTo} h | {room.title}
          </div>
          <div className="font-secondary text-smaller">
            {moment(`${moment(start).year()}-${moment(start).month() + 1}-${getDay()}`).format('DD/MM/YYYY')}
          </div>
          <h6 className="mt-5 mb-6 font-primary text-bigger2">{name}</h6>
          <p className="mr-6 leading-snug font-secondary text-small">
            {description}
          </p>
          <div className="flex items-end pt-8 pb-4 cursor-pointer">
            <div className="flex flex-col w-full">
              <div className="pb-8">
                <ButtonSecondary cta={cta} internalLink={true} />
              </div>
              {/* (user login hidden) */}
              {/* {userInfo ? ( */}
                {/* {isBookable && userIsMember ? ( */}
                {isBookable ? (
                  <ActivityForm
                    literals={literals}
                    item={{
                      hourFrom: `${hourFrom}:00`,
                      hourTo: `${hourTo}:00`,
                      room: room.id,
                      name,
                      activity: activityId,
                      date: moment(
                        `${moment(start).year()}-${moment(start).month() +
                          1}-${getDay()}`
                      ).format('YYYY-MM-DD')
                    }}
                    close={() => close()}
                    userInfo={userInfo}
                  />
                ) : (
                  <div className="mb-2 mr-6 leading-snug font-secondary text-small">{literals.literalClosedBooking}</div>
                )}
              {/* ) : (
                <div className="mb-3">
                {isBookable && userIsMember ? (
                  <>
                    <div className="mb-3 mr-6 leading-snug font-secondary text-small">{literals.literalLoginToBook}</div>
                    <Button
                      darkMode={true}
                      actionButton={true}
                      actionButtonOnClick={() => navigate("/es/iniciar-sesion/")}
                      cta={{ label: literals.literalLogin }}
                    />
                  </>
                ) : (
                  <div className="mb-2 mr-6 leading-snug font-secondary text-small">{literals.literalClosedBooking}</div>
                )}
                </div>
              )} */}
              <div className="flex justify-end">
                <ButtonSecondary
                  cta={{ label: literals.cancel }}
                  actionButton={true}
                  actionButtonOnClick={() => {
                    close()
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CalendarHoverMobile

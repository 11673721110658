// @flow

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import CalendarHover from './CalendarHover'
import { useGlobalState } from 'store/'

type Tag = {
  id: string,
  title: string,
  color?: string
}

type RoomType = {
  id: string,
  title: string,
}

type Props = {
  day: string,
  dateFrom: string,
  dateTo: string,
  hourFrom: string,
  hourTo: string,
  room: RoomType,
  name: string,
  teacher: string,
  tags: Array<Tag>,
  description: string,
  cta: Object,
  disableHover?: boolean,
  rooms: Array<RoomType>,
  hoverEffectCallback?: Function,
  hoveredItem?: string,
  showTeachers?: boolean,
  start?: string,
  id: string,
  literals: Object,
  activityId: string,
  userInfo: Object,
  needsReservation: string,
  exceptionDates: Array<string>,
}

// eslint-disable-next-line react/display-name
const CalendarCell = ({
  day,
  hourFrom,
  hourTo,
  room,
  name,
  tags,
  description,
  cta,
  disableHover,
  rooms,
  hoverEffectCallback,
  hoveredItem,
  start,
  id,
  literals,
  activityId,
  showTeachers,
  teacher,
  userInfo,
  needsReservation,
  exceptionDates,
  dateTo,
  dateFrom,
}: Props) => {

  const [session] = useGlobalState()
  const [hovered, setHovered] = useState(false)
  const [shouldHideOnMouseLeave, setShouldHideOnMouseLeave] = useState(true)
  const [oneDayBefore, setOneDayBefore] = useState(false)

  useEffect(() => {
    checkRemainingTime()
  }, [])

  function handleCalendarHover(value) {
    if (typeof hoverEffectCallback !== 'undefined') {
      hoverEffectCallback(value, name)
    }
    setHovered(value)
  }

  const cellPosition = () => {
    // Asign starting column by day
    let col = ''
    switch (day) {
      case '1':
        col = '3'
        break
      case '2':
        col = '6'
        break
      case '3':
        col = '9'
        break
      case '4':
        col = '12'
        break
      case '5':
        col = '15'
        break
      case '6':
        col = '18'
        break
      case '7':
        col = '21'
        break
    }
    // Asign starting row by room
    const roomIndex = rooms.findIndex(element => element === room)
    return `row-start-${roomIndex + 1} col-start-${col}`
  }

  const hoverOnSimilar = hoveredItem === name
  // const hoverBackground = hoverOnSimilar ? '#575757' : ''

  const getDay = () => {
    const daysInMonth = moment(start).daysInMonth()
    const number = moment(start).date() + (Number(day) - 1)
    const result = number > daysInMonth ? number - daysInMonth : number
    return result
  }

  
  const activityFormattedDate = `${moment(start).year()}-${moment(start).month() + 1}-${getDay()} ${hourFrom}`
  const activityFormattedDay = `${moment(start).year()}-${moment(start).month() + 1}-${getDay()}`
  const today = moment()

  const checkRemainingTime = () => {
    const hoursRemaining = moment(activityFormattedDate).diff(today, 'hours')
    const minutesRemaining = moment(activityFormattedDate).diff(today, 'minutes')
    const secondsRemaining = moment(activityFormattedDate).diff(today, 'seconds')
    
    if (hoursRemaining >= 0 && minutesRemaining >= 0 && secondsRemaining >= 0) {
      if (hoursRemaining > 24) {
        setOneDayBefore(false)
      } else if (hoursRemaining < 24 && hoursRemaining > 0) {
        setOneDayBefore(true)
      } else if (hoursRemaining === 0 && minutesRemaining < 60 && minutesRemaining > 0) {
        setOneDayBefore(true)
      } else if (hoursRemaining === 0 && minutesRemaining === 0 && secondsRemaining < 60 && secondsRemaining > 0) {
        setOneDayBefore(true)
      } else {
        setOneDayBefore(false)
      }
    } 
  }

  const isBookable = moment(activityFormattedDate).isAfter() && oneDayBefore
  const capacityControl = needsReservation === "1"
  const userIsMember = session && session.hasMembership === true

  // if (moment(activityFormattedDay).isBefore(moment(dateFrom)) || moment(activityFormattedDay).isAfter(moment(dateTo))) {
  //   return null
  // }

  const formattedDay = moment(activityFormattedDay).format('MM-DD')

  if (exceptionDates.find(exceptionDate => {
    const currentDateMoment = moment(start).add((Number(day) - 1), 'days').format('MM-DD')
    return exceptionDate.substring(5) === currentDateMoment
  })) {
    return null
  }

  return (
    <div
      data-name="subtable-activity-cell"
      className={`
        group printable h-full printHeight relative col-span-3 row-span-1 min-h-28
        ${!disableHover ? 'cursor-pointer' : ''} 
        ${hoverOnSimilar ? 'bg-gray text-white' : 'text-gray'} 
        ${cellPosition()}
      `}
      style={{
        backgroundColor: `${
          tags.length > 0 && !hoverOnSimilar ? `#${tags[0].color || ""}` : ''
        }`
      }}
      onMouseEnter={() => {
        if (!window.tileOpen) {
          handleCalendarHover(true)
        }
      }}
      onMouseLeave={() => {
        if (shouldHideOnMouseLeave) {
          handleCalendarHover(false)
        }
      }}
    >
      <div data-id={id} className="relative h-full p-5 transition-colors duration-150 ease-linear">
        <div className="font-secondary text-smaller">
          {hourFrom} - {hourTo} | {room.title}
        </div>
        
        <h6 className="mt-1 leading-tight break-words font-primary text-bigger2">
          {name}
        </h6>
        {teacher && showTeachers && <div className="mt-1 font-secondary text-smaller">{teacher}</div>}
        {!disableHover && (
          <div
            className={`${
              shouldHideOnMouseLeave
                ? 'pointer-events-none'
                : 'pointer-events-auto'
            } group-hover:pointer-events-auto`}
          >
            <CalendarHover
              cta={cta}
              day={day}
              hourFrom={hourFrom}
              hourTo={hourTo}
              room={room}
              name={name}
              description={description}
              hovered={hovered}
              color={tags.length > 0 ? `#${tags[0].color || ""}` : ''}
              start={start}
              id={id}
              literals={literals}
              setShouldHideOnMouseLeave={setShouldHideOnMouseLeave}
              shouldHideOnMouseLeave={shouldHideOnMouseLeave}
              handleCalendarHover={handleCalendarHover}
              activityId={activityId}
              userInfo={userInfo}
              isBookable={isBookable}
              userIsMember={userIsMember}
              capacityControl={capacityControl}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo<Props>(CalendarCell)

// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import useIsMobile from '../hooks/useIsMobile'
import Svg from './Svg'

type Props = {
  items: Array<Object>,
}

const Gallery = ({ items }: Props) => {
  const isMobile = useIsMobile()
  const [swiper, setSwiper] = useState(null)
  const [active, setActive] = useState(0)
  const [mouseOverLeft, setMouseOverLeft] = useState(false)
  const [mouseOverRight, setMouseOverRight] = useState(false)

  useEffect(() => {
  }, [active])

  const params: any = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 30,
    speed: 1000,
    loop: true,
    watchSlidesVisibility: true,
    updateOnWindowResize: true,
    grabCursor: true,
    runCallbacksOnInit: true,
    onInit: (swiper) => {setSwiper(swiper)},
  }

  const prevItem = () => {
    if (swiper !== null) {
      swiper.slidePrev()
      setActive(swiper.realIndex)
    }
  }

  const nextItem = () => {
    if (swiper !== null) {
      swiper.slideNext()
      setActive(swiper.realIndex)
    }
  }

  return (
    <Appear>
      <div className="relative">
        <Swiper
          {...params}
          slideClass="swiper-slide"
          containerClass="swiper-container"
          ref={node => {node ? setSwiper(node.swiper) : setSwiper(null)}}
        >
        {items.map((item, index) => (
          <div key={index}>
            <div
              className="mb-8 bg-center bg-cover"
              style={{
                paddingBottom: "65.27929901%",
                backgroundImage: `url(${item.image.dir}${item.image.name.normal}-800.${item.image.extension.replace('png', 'jpg')})`
              }}
            />
          </div>
        ))}
        </Swiper>
        {isMobile
        ? <div className="relative z-10 flex justify-between -mt-5 md:hidden">
            <div onClick={prevItem} className="px-4">
              <Svg name="arrow-left" width={12} height={20} color='gray' />
            </div>
            <div onClick={nextItem} className="px-4">
              <Svg name="arrow-right" width={12} height={20} color='gray' />
            </div>
        </div>
        : <div style={{ marginTop: -290 }} className="absolute z-10 hidden w-full md:block">
          <div className="flex justify-between mx-8">
            <div
            onClick={prevItem}
            onMouseOver={() => setMouseOverLeft(true)}
            onMouseLeave={() => setMouseOverLeft(false)}
            className="relative flex items-center justify-center cursor-pointer"
            >
              <Svg name="oval-left" width={42} height={42} color={mouseOverLeft ? "gray" : "white"} />
              <div className="absolute">
                <Svg name="arrow-left" width={12} height={16} color={mouseOverLeft ? "white" : "gray"} />
              </div>
            </div>
            <div
            onClick={nextItem}
            onMouseOver={() => setMouseOverRight(true)}
            onMouseLeave={() => setMouseOverRight(false)}
            className="relative flex items-center justify-center cursor-pointer"
            >
              <Svg name="oval-right" width={42} height={42} color={mouseOverRight ? "gray" : "white"} />
              <div className="absolute">
                <Svg name="arrow-right" width={9} height={16} color={mouseOverRight ? "white" : "gray"} />
              </div>
            </div>
          </div>
        </div>}
      </div>
    </Appear>
  )
}

export default Gallery

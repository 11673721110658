// @flow

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import useIsMobile from '../hooks/useIsMobile'
import type { Image } from 'types/'
import Svg from './Svg'
import ActivityTableDesktop from './ActivityTableDesktop'
import ActivityTableMobile from './ActivityTableMobile'
import ActivityTag from './ActivityTag'
import Button from './Button'
import FilterDropdown from './FilterDropdown'
import sessionService from '../services/session.service'
import { useGlobalState } from 'store/'

type Tag = {
  id: string,
  title: string,
  color?: string,
}

type Room = {
  id: string,
  title: string,
  calendarPosition: string,
}

type Activity = {
  id: string,
  activityId: string,
  day: string,
  dateFrom: string,
  dateTo: string,
  hourFrom: string,
  hourTo: string,
  room: Room,
  name: string,
  teacher: string,
  tags: Array<Tag>,
  description: string,
  ctaLabel: string,
  ctaHref: string,
  exceptionDates: Array<string>
}

type ItemsWrapper = {
  date: Object,
  items: Array<Activity>
}

type Props = {
  dropdownActivity: Object,
  dropdownRoom: Object,
  dropdownHours: Object,
  tags: Array<Tag>,
  ctaLabel: string,
  days: Array<string>,
  rooms: Array<Room>,
  items: Array<ItemsWrapper>,
  disableHover?: boolean,
  arrows: Object,
  tagIcon: Image,
  literalText: Object,
  language: string,
  pageName: string,
  cancel: string,
  book_activity: string,
  name: string,
  email: string,
  valid_activity_register: string,
  noResultsFound: string,
  literalErrorEmail: string,
  literalErrorCapacity: string,
  LiteralErrorRegistered: string,
  literalError: string,
  literalClosedBooking: string,
  literalLoginToBook: string,
  literalLogin: string,
  literalErrorAvailability: string,
  literalErrorPastActivity: string,
  literalErrorAnotherActivity: string,
}

const Calendar = ({
  dropdownActivity,
  dropdownRoom,
  dropdownHours,
  tags,
  ctaLabel,
  arrows,
  days,
  rooms,
  items,
  disableHover,
  tagIcon,
  literalText,
  pageName,
  cancel,
  book_activity,
  name,
  email,
  valid_activity_register,
  noResultsFound,
  literalErrorEmail,
  literalErrorCapacity,
  LiteralErrorRegistered,
  literalError,
  literalClosedBooking,
  literalLoginToBook,
  literalLogin,
  literalErrorAvailability,
  literalErrorPastActivity,
  literalErrorAnotherActivity,
}: Props) => {

  const literals = {
    cancel,
    book_activity,
    name,
    email,
    valid_activity_register,
    noResultsFound,
    literalErrorEmail,
    literalErrorCapacity,
    LiteralErrorRegistered,
    literalError,
    literalClosedBooking,
    literalLoginToBook,
    literalLogin,
    literalErrorAvailability,
    literalErrorPastActivity,
    literalErrorAnotherActivity,
  }
  const isMobile = useIsMobile()
  const [session, dispatch] = useGlobalState()
  const [currentWeek, setCurrentWeek] = useState(0)
  const [weeklyItems, setWeeklyItems] = useState(items[0].items)
  const [currentDropdown, setCurrentDropdown] = useState('')
  const [tagFilters, setTagFilters] = useState([])
  const [filteredItems, setFilteredItems] = useState(items[0].items)
  const [filteredHours, setFilteredHours] = useState()
  const [initialUrl, setInitialUrl] = useState('undefined')
  const [currentUrl, setCurrentUrl] = useState(initialUrl)
  const [firstLoad, setFirstLoad] = useState(true)
  const [showTeachers, setShowTeachers] = useState(false)
  const [userInfo, setUserInfo] = useState()

  const showTeachersUrlParam = '?showteachers=true'

  // get user info
  async function getUserInfo() {
    const response = await sessionService.getUserInfo(session.id)
    setUserInfo(response)
  }

  useEffect(() => {
    if (firstLoad && session) {
      getUserInfo()
    }
  }, [])

  function normalize(string) {
    const normalized = string
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .replace(/[()¡!¿?[];:]/g,'')
      .replace(/[ .]/g,'-')
    const lastChar = normalized.slice(normalized.length - 1)
    if (lastChar == "-") return normalized.slice(0, -1)
    return normalized
  }

  // Handle desktop tag click
  function handleClick(item) {
    if (tagFilters.includes(item)) {
      setTagFilters([])
    } else {
      setTagFilters([item])
    }
  }

  useEffect(() => {
    setWeeklyItems(
      typeof items[currentWeek] !== 'undefined'
        ? [...items[currentWeek].items]
        : [...items[0].items]
    )
  }, [currentWeek])

  // Change week on arrow click
  const [opacity, setOpacity] = useState(1)
  function changeWeek(value) {
    setOpacity(0)
    setTimeout(() => {
      if (value === 'next') {
        if (typeof items[currentWeek + 1] !== 'undefined') {
          setCurrentWeek(currentWeek + 1)
        }
      } else if (value === 'prev') {
        if (typeof items[currentWeek - 1] !== 'undefined') {
          setCurrentWeek(currentWeek - 1)
        }
      }
      setOpacity(1)
    }, 200)
  }

  // Get starting hours of activities
  function getHours(items) {
    // Get array without duplicated hours
    const all = []
    items.forEach(activity => {
      const start = activity.hourFrom
      if (!all.includes(start)) {
        all.push(start)
      }
    })
    // Split hours & minutes and get integers
    const split = all.map(hour => {
      const splitHour = hour.split(':')
      const splitHourInt = splitHour.map(item => parseInt(item))
      const h = splitHourInt[0]
      const m = splitHourInt[1]
      const total = h * 100 + m
      return {
        int: total,
        string: hour
      }
    })
    // order integers & return ordered array
    split.sort((a, b) => {
      return a.int - b.int
    })
    return split
  }

  const itemsHours = getHours(weeklyItems)

  const dropdownHoursItems = () => {
    let itemArray = []
    itemsHours.map(itemHour => {
      const i = { id: `${itemHour.int}`, title: itemHour.string }
      itemArray.push(i)
    })
    return itemArray
  }

  // Get activities
  const dropdownActivityItems = () => {
    let newItems = []
    weeklyItems.forEach(item => {
      if (newItems.filter(i => i.id === item.activityId).length === 0) {
        newItems.push({ id: item.activityId, title: item.name ? item.name.trim() : '' })
      }
    })
    newItems.sort((a, b) => a.title.localeCompare(b.title))
    return newItems
  }

  // Get rooms
  const dropdownRoomItems = () => {
    let activeRooms = []
    weeklyItems.forEach(item => {
      if(!activeRooms.includes(item.room.id)) {
        activeRooms.push(item.room.id)
      }
    })

    // Ensure order of received rooms is following calendarPosition order
    const ordererdRoomsreference = rooms.sort((a, b) => {
      return parseInt(a.calendarPosition) - parseInt(b.calendarPosition)
    })

    const returnedItems = []
    ordererdRoomsreference.forEach(room => {
      if (activeRooms.includes(room.id)) {
        const obj = {
          id: room.id,
          title: room.title,
        }
        returnedItems.push(obj)
      }
    })

    return returnedItems
  }

  // Dropdown click
  const dropdownClick = (id, value) => {
    if (value === true && currentDropdown !== id) {
      setCurrentDropdown(id)
    }
  }

  // Url filters
  // Store & update current url
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setInitialUrl(window.location.href)
      if (window.location.href.includes(showTeachersUrlParam)) {
        setShowTeachers(true)
      }
      setFirstLoad(false)
    }
  })

  // Sets new url on browser window
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      currentUrl !== 'undefined' &&
      !firstLoad
    ) {
      window.history.pushState(null, '', currentUrl)
    }
    if (currentUrl.includes(showTeachersUrlParam)) {
      setShowTeachers(true)
    }
  }, [currentUrl])

  // Add new params
  function setParam(key, value) {
    if (!firstLoad && window !== 'undefined') {
      const currentParams = new URLSearchParams(window.location.search)
      if (!currentParams.has(key)) {
        // Appends new param
        currentParams.append(key, value)
      } else {
        // Updates existing param
        currentParams.set(key, value)
      }
      // Get new url with updated params
      setCurrentUrl(
        `${window.location.origin}${
          window.location.pathname
        }?${currentParams.toString()}`
      )
    }
  }

  // Remove params
  function removeParam(key) {
    if (!firstLoad && window !== 'undefined') {
      const currentParams = new URLSearchParams(window.location.search)
      if (currentParams.has(key)) currentParams.delete(key)

      // Remove leading ? when params are undefined
      if (currentParams.toString() === '') {
        // Get new url with updated params
        setCurrentUrl(`${window.location.origin}${window.location.pathname}`)
      } else {
        // Get new url with updated params
        setCurrentUrl(
          `${window.location.origin}${
            window.location.pathname
          }?${currentParams.toString()}`
        )
      }
    }
  }

  // Pass param to dropdown
  function parseParams(key) {
    let value
    if (!firstLoad && window !== 'undefined') {
      const currentParams = new URLSearchParams(window.location.search)
      if (currentParams.has(key)) {
        value = currentParams.get(key)
        if (value !== null) value = value.toString()
      }
    }
    return value
  }

  const dropdownValue = (id, value) => {
    setCurrentDropdown(id)
    switch (id) {
      case '1':
        // First dropdown
        if (value.title === dropdownActivity.placeholder) {
          removeParam('activity')
          setDropdownActivityValue(dropdownActivityItems())
        } else {
          setParam('activity', normalize(value.title))
          setDropdownActivityValue(value)
        }
        break
      case '2':
        // Second dropdown
        if (value.title === dropdownRoom.placeholder) {
          removeParam('room')
          setDropdownRoomValue(dropdownRoomItems())
        } else {
          setParam('room', value.id)
          setDropdownRoomValue(value)
        }
        break
      case '3':
        // Third dropdown
        if (value.title === dropdownHours.placeholder) {
          removeParam('hour')
          setDropdownHoursValue(dropdownHoursItems())
        } else {
          setParam('hour', value.id)
          setDropdownHoursValue(value)
        }
    }
  }

  // Apply dropdown selection
  const [dropdownActivityValue, setDropdownActivityValue] = useState(
    dropdownActivityItems()
  )
  const [dropdownRoomValue, setDropdownRoomValue] = useState(
    dropdownRoomItems()
  )
  const [dropdownHoursValue, setDropdownHoursValue] = useState(
    dropdownHoursItems()
  )

  // Apply filters
  useEffect(() => {
    let newItems = []

    // const currentStartDate = moment(items[currentWeek].date.start)
    // const currentEndDate = moment(items[currentWeek].date.end)

    weeklyItems.forEach(item => {
      let shouldBeAdded = true

      // if (moment(item.dateFrom).isSameOrAfter(currentStartDate) || moment(item.dateTo).isSameOrBefore(currentEndDate)) {
      //   shouldBeAdded = false
      // }

      // if (!moment(item.dateFrom).isSameOrBefore(currentStartDate) || !moment(item.dateTo).isSameOrAfter(currentEndDate)) {
      //   shouldBeAdded = false
      // }

      if (
        !Array.isArray(dropdownActivityValue) &&
        dropdownActivityValue.id > -1 &&
        dropdownActivityValue.id !== item.activityId
      ) {
        shouldBeAdded = false
      }
      if (
        !Array.isArray(dropdownRoomValue) &&
        dropdownRoomValue.id > -1 &&
        dropdownRoomValue.id !== item.room.id
      ) {
        shouldBeAdded = false
      }
      if (
        !Array.isArray(dropdownHoursValue) &&
        dropdownHoursValue.id > -1 &&
        dropdownHoursValue.title !== item.hourFrom
      ) {
        shouldBeAdded = false
      }
      if (tagFilters.length > 0) {
        let hasTag = false
        item.tags.forEach(tag => {
          if (tagFilters.filter(t => t.id === tag.id).length > 0) {
            hasTag = true
          }
        })
        if (!hasTag) {
          shouldBeAdded = false
        }
      }
      if (shouldBeAdded) {
        newItems.push(item)
      }
    })
    setFilteredItems(newItems)
  }, [
    dropdownActivityValue,
    dropdownRoomValue,
    dropdownHoursValue,
    tagFilters,
    weeklyItems
  ])

  // Get preceding & following hour if activeHour.length === 1
  // Esto tiene que actualizarse con los filteredItems
  useEffect(() => {
    if (!isMobile) {
      let promise = new Promise(resolve => {
        // Get string arr of all hours
        const allHours = itemsHours.map(hour => hour.string)

        // Get arr of obj with hours of filtered items
        let activeHours = getHours(filteredItems)

        let result = () => {
          if (activeHours.length === 1) {
            let activeHour = activeHours[0].string
            let index = allHours.indexOf(activeHour)
            let prev2 = allHours[index - 2]
            let prev1 = allHours[index - 1]
            let next1 = allHours[index + 1]
            let next2 = allHours[index + 2]

            if (index === 0) {
              // No preceding hour. Return 2 next hours
              return { next1, next2 }
            } else if (index + 1 === allHours.length) {
              // No following hour. Return 2 prev hours
              return { prev1, prev2 }
            } else {
              // Is inbetween. Return prev & next hours
              return { prev1, next1 }
            }
          } else return undefined
        }

        resolve(result())
      })
      promise.then(
        result => setFilteredHours(result),
        err => console.log(err)
      )
    }
  }, [filteredItems, isMobile])

  // Disable dropdown items
  const [activeActivityItems, setActiveActivityItems] = useState('all')
  const [activeRoomItems, setActiveRoomItems] = useState('all')
  const [activeHoursItems, setActiveHoursItems] = useState('all')

  useEffect(() => {
    const isTagFilterActive = tagFilters.length !== 0
    const isDropdownActivityActive = !(dropdownActivityValue instanceof Array)
    const isDropdownRoomActive = !(dropdownRoomValue instanceof Array)
    const isDropdownHoursActive = !(dropdownHoursValue instanceof Array)

    // Activities
    if (isDropdownRoomActive || isDropdownHoursActive || isTagFilterActive) {
      const arr = []
      filteredItems.forEach(item => {
        const trimmedName = item.name.trim()
        !arr.includes(trimmedName) && arr.push(trimmedName)
      })
      setActiveActivityItems(arr)
    } else {
      setActiveActivityItems('all')
    }

    // Hours
    if (isDropdownRoomActive || isDropdownActivityActive || isTagFilterActive) {
      const arr = []
      filteredItems.forEach(item => {
        !arr.includes(item.hourFrom) && arr.push(item.hourFrom)
      })
      setActiveHoursItems(arr)
    } else {
      setActiveHoursItems('all')
    }

    // Filter rooms
    function filterRooms() {
      let arr = []
      let arrObj = []
      filteredItems.forEach(item => {
        if (!arr.includes(item.room.title)) {
          arr.push(item.room.title)
          arrObj.push({
            name: item.room.title
          })
        }
      })
      return arrObj
    }

    // Rooms
    if (
      isDropdownActivityActive ||
      isDropdownHoursActive ||
      isTagFilterActive
    ) {
      const arr = []
      filterRooms().forEach(item => {
        const trimmedName = item.name.trim()
        !arr.includes(trimmedName) && arr.push(trimmedName)
      })
      setActiveRoomItems(arr)
    } else setActiveRoomItems('all')
  }, [filteredItems, tagFilters])

  // Click to print
  const handlePrint = () => {
    if (navigator.userAgent.includes('AppleWebKit')) {
      document.execCommand('print', false, null)
    } else {
      window.print()
    }
  }

  // Get default tab for activity table mobile
  const [defaultTab, setDefaultTab] = useState(0)
  useEffect(() => {
    const today = moment(new Date())
    const day = today.day()
    setDefaultTab(day - 1)
  }, [])

  return (
    <div className="grid grid-cols-4 gap-4 mx-4 mt-10 mb-48 transform md:mt-0 md:grid-cols-12 md:gap-6 lg:gap-8 md:mx-6 lg:mx-8 text-gray md:mb-112 print:mb-0">
      <div className="grid grid-cols-4 col-span-4 gap-4 md:col-span-12 print:hidden md:grid-cols-11 md:gap-6 lg:gap-8">
        <div
          data-name="week filter"
          className="flex items-center justify-between col-span-4 mt-6 mb-2 md:flex-col md:my-0 md:mb-6 lg:mb-4 md:col-span-5 md:col-start-4 md:items-stretch md:justify-end"
        >
          <span
            style={{ opacity: `${opacity}` }}
            className="transition-opacity duration-150 ease-linear font-secondary text-small md:hidden"
          >
            {literalText.week} {literalText.from}{' '}
            {moment(items[currentWeek].date.start).format('DD')}{' '}
            {literalText.to}{' '}
            {moment(items[currentWeek].date.end).format('DD')} /
            {moment(items[currentWeek].date.start).format('MM')}{' '}
            {literalText.of}{' '}
            {moment(items[currentWeek].date.start).format('YYYY')}
          </span>
          <div className="flex items-center text-center md:justify-between">
            <div
              className="flex items-center justify-center w-4 h-4 p-4 mr-8 cursor-pointer md:mr-0"
              onClick={() => {
                changeWeek('prev')
              }}
            >
              <Svg name="arrow-left" width="16" height={16} color="gray" />
            </div>
            <span
              style={{ opacity: `${opacity}` }}
              className="hidden -mb-1 transition-opacity duration-150 ease-linear md:inline font-primary text-bigger2"
            >
              {literalText.week} {literalText.from}{' '}
              {moment(items[currentWeek].date.start).format('DD')}{' '}
              {literalText.to}{' '}
              {moment(items[currentWeek].date.end).format('DD')}{' '}
              {literalText.of}{' '}
              {moment(items[currentWeek].date.end).format('MMMM')}
            </span>
            <div
              className="flex items-center justify-center w-4 h-4 p-4 cursor-pointer"
              onClick={() => {
                changeWeek('next')
              }}
            >
              <Svg name="arrow-right" width="16" height={16} color="gray" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-4 md:col-span-3 print:hidden">
        <FilterDropdown
          {...dropdownActivity}
          items={dropdownActivityItems()}
          dropdownValue={dropdownValue}
          dropdownClick={dropdownClick}
          forceClose={currentDropdown}
          urlFilter={parseParams('activity')}
          bgColor="primary-beige"
          firstLoad={firstLoad}
          activeItems={activeActivityItems}
        />
      </div>
      <div className="col-span-4 md:col-span-3 print:hidden">
        <FilterDropdown
          {...dropdownRoom}
          items={dropdownRoomItems()}
          dropdownValue={dropdownValue}
          dropdownClick={dropdownClick}
          forceClose={currentDropdown}
          urlFilter={parseParams('room')}
          bgColor="primary-beige"
          firstLoad={firstLoad}
          activeItems={activeRoomItems}
        />
      </div>
      <div className="col-span-4 mb-6 md:mb-0 md:col-span-3 print:hidden">
        <FilterDropdown
          {...dropdownHours}
          items={dropdownHoursItems()}
          dropdownValue={dropdownValue}
          dropdownClick={dropdownClick}
          forceClose={currentDropdown}
          urlFilter={parseParams('hour')}
          bgColor="primary-beige"
          firstLoad={firstLoad}
          activeItems={activeHoursItems}
        />
      </div>
      <div className="flex-col items-stretch justify-start hidden col-span-4 pt-10 md:col-span-3 md:flex print:hidden">
        <Button
          actionButton={true}
          actionButtonOnClick={() => handlePrint()}
          cta={{ label: ctaLabel }}
          darkMode={true}
        />
      </div>
      <div
        data-name="tags"
        className="flex-wrap items-start justify-start hidden col-span-12 py-8 -mb-10px md:flex print:hidden"
      >
        {tags.map((item, index) => {
          return (
            <ActivityTag
              key={index}
              item={item}
              src={tagIcon}
              isChecked={tagFilters.includes(item)}
              onClickCallback={() => handleClick(item)}
            />
          )
        })}
      </div>
      {isMobile === true ? (
        <ActivityTableMobile
          items={filteredItems}
          days={days}
          cta={{ label: ctaLabel, href: '#' }}
          arrows={arrows}
          pageName={pageName}
          defaultTab={defaultTab}
          showTeachers={showTeachers}
          start={items[currentWeek].date.start}
          end={items[currentWeek].date.end}
          literals={literals}
          userInfo={userInfo}
        />
      ) : (
        <ActivityTableDesktop
          items={filteredItems}
          disableHover={disableHover}
          cta={{ label: ctaLabel, href: '#' }}
          days={days}
          hours={itemsHours}
          filteredHours={filteredHours}
          rooms={rooms}
          pageName={pageName}
          showTeachers={showTeachers}
          start={items[currentWeek].date.start}
          end={items[currentWeek].date.end}
          literals={literals}
          dropdownRoomValue={dropdownRoomValue}
          userInfo={userInfo}
        />
      )}
      {filteredItems.length === 0 && (
        <div className="hidden w-full col-span-4 p-4 leading-tight text-center md:block md:col-span-12 font-primary text-bigger2">
          {noResultsFound}
        </div>
      )}
    </div>
  )
}

export default Calendar

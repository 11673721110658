// @flow

import React from 'react'
import { Field } from 'formik'

type Props = {
  name: string,
  value: string,
  required?: boolean,
  isChecked?: boolean,
  handleCheckbox?: Function,
}

const FormCheckbox = ({ name, value, required, isChecked, handleCheckbox }: Props) => {

  // Change field state onclick
  const handleClick = () => {
    if (handleCheckbox) {
      handleCheckbox(!value)
    }
  }

  return (
    <>
      <Field 
        className="absolute opacity-0 pointer-events-none"
        type="checkbox"
        name={name}
        required={required}
        checked={isChecked}
      />
      <button
        type="button" 
        className={`flex-shrink-0 focus:outline-none w-4 h-4 border-gray mr-10px bg-transparent transition-all duration-100 ease-in-out ${isChecked === true ? "border-8" : "border"}`} 
        onClick={handleClick}
      />
    </>
  )
}

export default FormCheckbox

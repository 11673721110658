// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import moment from 'moment'
import { Formik, Form } from 'formik'
import ContainerGrid from './ContainerGrid'
import DropCapText from './DropCapText'
import FormField from './FormField'
import FormCheckbox from './FormCheckbox'
import '../styles/calendar.css'
import Calendar from 'react-calendar'
import Button from './Button'
import sessionService from '../services/session.service'
import Svg from './Svg'
import HTMLText from './HTMLText'

type Props = {
  text: string,
  formTitle: string,
  calendarTitle: string,
  placeholders: Object,
  ctaLabel: string,
  ctaHref: string,
  ctaText: string,
  requestSentMessage: string,
  literalErrorEmail: string,
  literalErrorPhone: string,
}

const TourForm = ({
  formTitle,
  calendarTitle,
  placeholders,
  ctaLabel,
  ctaText,
  ctaHref,
  requestSentMessage,
  literalErrorEmail,
  literalErrorPhone,
}: Props) => {

  const [validationErrors, setValidationErrors] = useState()
  const [renderCalendar, setRenderCalendar] = useState(true)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    const opacityTimer = setTimeout(() => {
      setOpacity(0)
      }, 4800)

    const resetTimer = setTimeout(() => {
        setSent(false)
        setOpacity(1)
      }, 5000)
    
    return () => { 
      clearTimeout(opacityTimer)
      clearTimeout(resetTimer)
    }
  }, [sent])

  // Format date
  // Gets first letter of weekday
  function formatDate(locale, date) {
    const dateES = (date.toLocaleDateString(locale, { weekday: "short" }))
    if (dateES === "mié.") {
      return ("x")
    } else {
      return (
        dateES.charAt(0)
      )
    }
  }
  
  const today = new Date()

  return (
    <Appear>
      <ContainerGrid
        mdCleanLeft={true}
        lgCleanLeft={true}
      >
        <div className="grid grid-cols-1 gap-4 md:grid-cols-11 md:gap-6 lg:gap-8 text-gray">
          <div data-name="left-column" className="hidden col-span-1 md:col-span-5 md:block">
            <DropCapText text={ctaText} />
          </div>
          <Appear className="col-span-1 md:col-span-6">
            <Formik
              enableReinitialize={true}
              initialValues={{
                type: "contact-request-tour",
                name: "", 
                surnames: "", 
                email: "",
                phoneNumber: "",
                requestDate: "",
                requestTime: "",
                message: "",
                member: "",
                terms: false
              }}
              validate={ values => {
                const errors = {}
                const phoneReg = new RegExp(/^[9|6|7][0-9]{8}$/)
                const mailReg = new RegExp(/^([a-z0-9_.+-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/gm)
                const hourReg = new RegExp(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)
                Object.entries(values).forEach(field => {
                  if (field[0] === "phoneNumber") {
                    if (
                      typeof field[1] === "string"
                      && field[1] !== ""
                      && !phoneReg.test(field[1])
                    ) errors[field[0]] = true
                  }
                  if(field[0] === "email") {
                    if (
                      typeof field[1] === "string"
                      && field[1] !== ""
                      && !mailReg.test(field[1])
                    ) errors [field[0]] = true
                  }
                  if(field[0] === "requestDate") {
                    if (
                      field[1] === ""
                    ) errors[field[0]] = true
                  }
                  if(field[0] === "requestTime") {
                    if (
                      typeof field[1] === "string"
                      && field[1] !== ""
                      && !hourReg.test(field[1])
                    ) errors[field[0]] = true
                  }
                })
                setValidationErrors(errors)
                return errors
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setLoading(true)
                delete values.terms
                const response = await sessionService.contactRequestTour(values)
                if (response) {
                  setSent(true)
                  setLoading(false)
                  setRenderCalendar(false)
                  resetForm({})
                  setRenderCalendar(true)
                }
                setSubmitting(false)
              }}
            >
              {({values, setFieldValue}) => {
              return (
                <Form className="grid grid-cols-4 md:grid-cols-6 md:gap-6 lg:gap-5">
                  <label
                    className="col-span-4 mt-6 uppercase md:my-0 mb-10px md:col-span-6 font-secondary text-smaller"
                  >
                    {formTitle}
                  </label>
                  <div className="col-span-4 md:col-span-3">
                    <FormField
                      type="text"
                      name="name"
                      placeholder={placeholders.name}
                      required={true}
                    />
                  </div>
                  <div className="col-span-4 md:col-span-3">
                    <FormField
                      type="text"
                      name="surnames"
                      placeholder={placeholders.surname}
                      required={true}
                    />
                  </div>
                  <div className="col-span-4 md:col-span-3">
                    <FormField
                      type="email"
                      name="email"
                      placeholder={placeholders.email}
                      error={validationErrors && validationErrors.email}
                      literalError={literalErrorEmail}
                      required={true}
                    />
                  </div>
                  <div className="col-span-4 md:col-span-3">
                    <FormField
                      type="tel"
                      name="phoneNumber"
                      placeholder={placeholders.phone}
                      error={validationErrors && validationErrors.phoneNumber}
                      literalError={literalErrorPhone}
                      required={true}
                    />
                  </div>
                  <label className="col-span-4 mt-6 uppercase md:my-0 mb-10px md:col-span-6 font-secondary text-smaller">
                    {calendarTitle}
                  </label>
                  <div className="col-span-4 mb-3 text-center no-underline uppercase md:col-span-3 font-secondary text-small " >
                  {renderCalendar &&
                    <Calendar
                      maxDetail={"month"}
                      minDetail={"month"}
                      locale={"es"}
                      formatShortWeekday={(locale, date) => formatDate(locale, date)}
                      onClickDay={(value) => {setFieldValue("requestDate", moment(value).format("YYYY-MM-DD"))}}
                      minDate={today}
                      prevLabel={<Svg name="arrow-left" width={16} height={16} color="gray" />}
                      nextLabel={<Svg name="arrow-right" width={16} height={16} color="gray" />}
                    />
                  }
                  </div>
                  <div className="col-span-4 mb-3 md:col-span-3">
                    <FormField 
                      type="time" 
                      name="requestTime" 
                      placeholder={placeholders.hour} 
                      min="00:00" max="23:59"
                      error={validationErrors && validationErrors.requestTime}
                      required={true}
                    />
                  </div>
                  <div className="flex items-center justify-start col-span-6 mt-5 text-gray text-tiny">
                    <FormCheckbox 
                      name="terms"
                      value={values.terms}
                      required={true}
                      isChecked={values.terms === true}
                      handleCheckbox={(value) => setFieldValue("terms", value)}
                    />
                    <HTMLText text={placeholders.terms} className="font-secondary" />
                  </div>
                  <div className="flex flex-col items-center col-span-4 mt-8 md:flex-row md:col-start-1 md:col-span-4 md:mt-8 lg:mt-6">
                    <div className="w-full md:w-1/2">
                    <Button submitButton={true} cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} loading={loading} />
                    </div>
                    {sent && <Appear>
                      <div
                        className="my-4 transition-opacity duration-200 ease-linear text-small md:ml-4"
                        style={{ opacity: opacity }}
                      >
                        <span className="text-gray font-secondary">{requestSentMessage}</span>
                      </div>
                    </Appear>}
                  </div>
                </Form>
              )}}
            </Formik>
          </Appear>
        </div>
      </ContainerGrid>
    </Appear>
  )
}

export default TourForm

// @flow
import React, { useRef, useState, useEffect } from 'react'
import type { Ref } from 'types/'

type Props = {
  children: React$Node,
  className?: string,
  callback?: () => void,
  direction?: string,
}

let observer

const Appear = ({ className, children, callback, direction }: Props) => {
  const [show, setShow] = useState(false)
  const mainRef: Ref = useRef(null)
  useEffect(() => {
    if (show) {
      observer.unobserve(mainRef.current)
    }
  }, [show])
  useEffect(() => {
    observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (callback) {
            callback()
          }
          setShow(true)
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1
      }
    )
    if (mainRef.current) {
      observer.observe(mainRef.current)
    }
  }, [])

  if (direction === "toLeft") {
    return (
      <div
      ref={mainRef}
      className={`${
        className ? className : ""
      } transition transform duration-normal ${
        show ? "opacity-100 translate-x-0 translate-y-0" : "opacity-0 translate-x-8 translate-y-0"
      }`}
    >
      {children}
    </div>
    )
  }

  if (direction === "toRight") {
    return (
      <div
      ref={mainRef}
      className={`${
        className ? className : ""
      } transition transform duration-normal ${
        show ? "opacity-100 translate-x-0 translate-y-0" : "opacity-0 -translate-x-8 translate-y-0"
      }`}
    >
      {children}
    </div>
    )
  }

  return (
    <div
      ref={mainRef}
      className={`${
        className ? className : ""
      } transition transform duration-normal ${
        show ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
      }`}
    >
      {children}
    </div>
  )
}

export default Appear

// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import type { Image } from 'types/'
import ContainerGrid from './ContainerGrid'
import Button from './Button'
import DropCapText from './DropCapText'
import useIsMobile from '../hooks/useIsMobile'
import ImageLazy from './ImageLazy'

type Props = {
  title: string,
  text: string,
  image: Image,
  ctaText: string,
  file: Object
}

const HeadingRestaurant = ({ title, text, image, ctaText, file }: Props) => {
  const isMobile = useIsMobile()
  const [windowHeight, setWindowHeight] = useState()
  const [textSize, setTextSize] = useState(100)
  const [marginBottom, setMarginBottom] = useState('mb-24')
  const [marginTop, setMarginTop] = useState('md:mt-36')

  const handleResize = () => {
    const height = document.documentElement.clientHeight || document.body.clientHeight
    setWindowHeight(height)
  }

  const handleTitleStyleDesktop = () => {
    if  (windowHeight) {
      if (windowHeight > 800) {
        setTextSize(100)
        setMarginBottom('mb-24')
        setMarginTop('md:mt-36')
      }
      if (windowHeight < 800) {
        setTextSize(90)
        setMarginBottom('mb-20')
        setMarginTop('md:mt-32')
      }
      if (windowHeight < 700) {
        setTextSize(80)
        setMarginBottom('mb-16')
        setMarginTop('md:mt-28')
      }
      if (windowHeight < 600) {
        setTextSize(70)
        setMarginBottom('mb-12')
        setMarginTop('md:mt-24')
      }
      if (windowHeight < 500) {
        setTextSize(60)
        setMarginBottom('mb-10')
        setMarginTop('md:mt-20')
      }
      if (windowHeight < 400) {
        setTextSize(50)
        setMarginBottom('mb-8')
        setMarginTop('md:mt-16')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    handleTitleStyleDesktop()
  }, [windowHeight])

  return (
    <div className={`mt-16 mb-6 ${marginTop} md:mb-28`}>
      {isMobile
      ? <Appear>
        <ContainerGrid>
          <div className="grid grid-cols-4 gap-4 mb-6 md:hidden">
            <h2 className="col-span-3 row-start-1 mb-10 leading-none text-gray font-primary text-big3">{title}</h2>
            <div className="col-span-4 row-start-2">
              <DropCapText text={text} />
            </div>
            <div className="col-span-4 mt-12 mb-10">
              {image && <Appear>
                <ImageLazy className="w-full h-auto" image={image} alt="" />
              </Appear>}
            </div>
            <div className="col-span-4">
              <Button cta={{ label: ctaText, href: file.file }} openNewWindow={true}/>
            </div>
          </div>
        </ContainerGrid>
      </Appear>
      : <div className="relative hidden md:block">
        <Appear>
          <ContainerGrid mdCleanLeft lgCleanLeft>
            <div className="grid grid-cols-11 gap-6 mb-24 ml-6 lg:ml-8 lg:gap-8">
              <h2
                className={`col-span-5 col-start-2 row-start-1 ${marginBottom} leading-none text-gray font-primary`}
                style={{ fontSize: textSize }}
              >{title}</h2>
              <div className="col-span-5 col-start-1 row-start-2">
                <DropCapText text={text} />
                <div className="mt-12 ml-20% w-3/5">
                  <Button cta={{ label: ctaText, href: file.file }} openNewWindow={true}/>
                </div>
              </div>
              <div className="col-span-5 col-start-7 row-start-2">
                {image && <Appear>
                  <ImageLazy className="w-full h-auto" image={image} alt="" />
                </Appear>}
              </div>
            </div>
          </ContainerGrid>
        </Appear>
      </div>
      }
    </div>
  )
}

export default HeadingRestaurant

// @flow

import React from 'react'
import HTMLText from './HTMLText'
import Appear from './Appear'

type Props = {
  text: string
}

const Legal = ({ text }: Props) => {
  const dropCap = text.charAt(0)
  const textBody = text.substring(1)

  return (
    <Appear>
      <div className="grid grid-cols-4 gap-4 mx-4 md:grid-cols-12 md:gap-6 lg:gap-8 md:mx-6 lg:mx-8 mb-60 md:mb-100">
        <div className="col-span-1 col-start-1 leading-none text-center md:text-right font-primary text-gray text-huge2 md:text-huge3">
          {dropCap}
        </div>
        <HTMLText
          className="col-span-3 col-start-2 text-gray md:col-span-7 font-secondary text-small md:text-normal"
          text={textBody}
        />
      </div>
    </Appear>
  )
}

export default Legal

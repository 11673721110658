// @flow

import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import ActivitySubGrid from './ActivitySubGrid'

type Tag = {
  id: string,
  title: string,
  color?: string
}

type Hour = {
  int: number,
  string: string
}

type Activity = {
  id: string,
  activityId: string,
  day: string,
  dateFrom: string,
  dateTo: string,
  hourFrom: string,
  hourTo: string,
  room: Object,
  name: string,
  teacher: string,
  tags: Array<Tag>,
  description: string,
  ctaLabel: string,
  ctaHref: string,
  exceptionDates: Array<string>,
}

type Props = {
  cta: Object,
  days: Array<string>,
  hours: Array<Hour>,
  rooms: Array<Object>,
  items: Array<Activity>,
  disableHover?: boolean,
  pageName?: string,
  filteredHours?: Object,
  showTeachers?: boolean,
  start?: string,
  literals: Object,
  dropdownRoomValue?: any,
  userInfo?: Object,
}

const ActivityTableDesktop = ({
  cta,
  days,
  rooms,
  items,
  disableHover,
  hours,
  pageName,
  filteredHours,
  showTeachers,
  start,
  literals,
  dropdownRoomValue,
  userInfo,
}: Props) => {
  const [onScreen, setOnscreen] = useState(false)
  const [hoveredItem, setHoveredItem] = useState(undefined)
  // Select similar on hover
  function hoverEffectCallback(value, name) {
    if (!disableHover) {
      value === true ? setHoveredItem(name) : setHoveredItem(undefined)
    }
  }

  // Change days background color
  useEffect(() => {
    // Get first backgroundImage
    const element = document.querySelector("[data-element='backgroundImage']")

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setOnscreen(false)
        } else {
          setOnscreen(true)
        }
      },
      {
        rootMargin: '-75px 0px 0px 0px'
      }
    )
    element && observer.observe(element)

    return () => {
      element && observer && observer.unobserve(element)
    }
  }, [])

  // Handle sticky days
  const stickyEl = useRef(null)
  const [sticky, setSticky] = useState(false)
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setSticky(true)
        } else {
          setSticky(false)
        }
      },
      {
        rootMargin: '-75px 0px 0px 0px'
      }
    )
    stickyEl.current && observer.observe(stickyEl.current)

    return () => {
      stickyEl.current && observer && observer.unobserve(stickyEl.current)
    }
  }, [])

  function handlebackground() {
    if (sticky || pageName === 'calendar') {
      return `transition-color duration-200 ease-linear ${
        onScreen ? 'bg-primary-beige' : 'bg-white'
      }`
    } else {
      return 'bg-transparent'
    }
  }

  const EmptyCell = ({ position }) => (
    <div
      className="col-span-3 col-start-3 border-b border-r border-gray"
      style={{
        gridRowStart: '1',
        gridColumnStart: `${position}`
      }}
    />
  )

  const EmptySubGrid = ({ index, hour }) => (
    <div
      data-name="subtable"
      className="grid col-start-1 row-span-1 gap-0 col-span-23 col-end-24 grid-cols-23"
      style={{ gridRowStart: `${index + 2}` }}
    >
      <div className="relative col-span-2 col-start-1 row-span-1 -mt-px border-b border-l border-r print:border-t-0 border-gray font-primary text-normal">
        <div className="sticky px-4 py-4 border-t print:static print:border-none top-75px border-gray">
          <span className="block mt-px print:mt-0">{hour}</span>
        </div>
      </div>
      <EmptyCell position="3" />
      <EmptyCell position="6" />
      <EmptyCell position="9" />
      <EmptyCell position="12" />
      <EmptyCell position="15" />
      <EmptyCell position="18" />
      <EmptyCell position="21" />
    </div>
  )

  // Handle selected day literal
  const handleDay = (day, index) => {
    const daysInMonth = moment(start).daysInMonth()
    const number = moment(start).date() + index
    const result = number > daysInMonth ? number - daysInMonth : number
    return `${day} ${result}`
  }

  return (
    <div
      className={
        'printZoom hidden print:grid md:grid w-full col-span-4 gap-0 md:col-span-12 grid-cols-23 relative'
      }
    >
      <div
        data-name="table-blank-top-left-cell"
        className="relative flex items-center justify-center col-span-2 row-start-1 border border-gray"
      >
        {typeof dropdownRoomValue !== 'undefined' && !Array.isArray(dropdownRoomValue) && (
          <div className="absolute -mt-1 font-bold font-primary text-bigger2">{dropdownRoomValue.title}</div>
        )}
        <div
          ref={stickyEl}
          className="w-full h-px -mt-px bg-transparent col-span-23"
        />
      </div>
      {days.map((day, index) => (
        <div
          key={index}
          data-name="table-day-cell"
          className={`sticky print:static z-10 col-span-3 row-start-1 px-4 pt-3 pb-4 text-center capitalize border-t border-b border-r top-75px border-gray font-primary text-bigger2 transition-color duration-200 ease-linear ${handlebackground()}`}
        >
          {handleDay(day, index)}
        </div>
      ))}
      {typeof filteredHours !== 'undefined' &&
        typeof filteredHours.prev2 !== 'undefined' && (
          <EmptySubGrid index={0} hour={filteredHours.prev2} />
        )}
      {typeof filteredHours !== 'undefined' &&
        typeof filteredHours.prev1 !== 'undefined' && (
          <EmptySubGrid index={1} hour={filteredHours.prev1} />
        )}
      {hours.map((hour, index) => {
        let groupItems = []
        items.map(item => {
          if (item.hourFrom === hour.string) {
            groupItems.push(item)
          }
        })
        if (groupItems.length !== 0)
          return (
            <React.Fragment key={hour.int}>
              <ActivitySubGrid
                cta={cta}
                days={days}
                index={index + 2}
                hour={hour}
                rooms={rooms}
                items={groupItems}
                disableHover={disableHover}
                hoverEffectCallback={hoverEffectCallback}
                hoveredItem={hoveredItem}
                start={start}
                literals={literals}
                showTeachers={showTeachers}
                userInfo={userInfo}
              />
            </React.Fragment>
          )
      })}
      {typeof filteredHours !== 'undefined' &&
        typeof filteredHours.next1 !== 'undefined' && (
          <EmptySubGrid index={hours.length + 2} hour={filteredHours.next1} />
        )}
      {typeof filteredHours !== 'undefined' &&
        typeof filteredHours.next2 !== 'undefined' && (
          <EmptySubGrid index={hours.length + 3} hour={filteredHours.next2} />
        )}
    </div>
  )
}

export default ActivityTableDesktop

// @flow

import React from 'react'
import type { Image } from 'types/'

import ContainerGrid from './ContainerGrid'
import DropCapText from './DropCapText'

type Props = {
  color: string,
  background: string,
  title: string,
  image: Image
}

const HelloWorld = ({ color, background, title, image }: Props) => {
  return (
    <>
      <ContainerGrid>
        <h1 className={`bg-${background} text-${color}`}>{title}</h1>
        <img className={`w-1/4`} src={image.normal} />
        <DropCapText text="Arsenal Femenino es uno de los centros de deporte, ocio y salud más completos de Europa, sometido a las más profundas exigencias de calidad con la intención de superar las expectativas de nuestras socias. Con una superficie de 12.000 m², el centro se aloja en un moderno edificio de gran belleza y funcionalidad que incorpora la más avanzada tecnología para alcanzar el máximo confort."/>
      </ContainerGrid>
    </>
  )
}

export default HelloWorld

// @flow

import React from 'react'
import Svg from './Svg'

type Tag = {
  id: string,
  color?: string,
  title: string,
}
type Props = {
  item: Tag,
  isChecked?: boolean,
  onClickCallback?: Function,
}
const ActivityTag = ({ item, isChecked, onClickCallback }: Props) => {
  const content = <div className="flex items-center justify-start mb-3 mr-5 leading-none whitespace-no-wrap font-secondary text-small">
    <div
      data-name="square-wrapper"
      className="relative flex-shrink-0 w-5 h-5 mr-10px"
    >
      <div
        className={`absolute top-0 left-0 w-5 h-5 transition-all duration-150 ease-in-out`}
        style={{
          borderWidth: `${isChecked || undefined ? `1px` : `0.625rem`}`,
          borderColor: isChecked ? '#575757' : `${typeof item.color !== "undefined" ? `#${item.color}` : "#575757"}`
        }}
      />
      { isChecked && <Svg name="remove" width={20} height={20} color="gray" /> }
    </div>
      {item.title}
  </div>

  if (onClickCallback) {
    return (
      <a className="underline cursor-pointer text-gray hover:text-primary-brown" onClick={() => onClickCallback()}>
        {content}
      </a>
    )
  }

  return (
    <div className="text-gray">
      {content}
    </div>
  )

}

export default ActivityTag

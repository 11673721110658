// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import type { Image } from 'types/'
import ContainerGrid from './ContainerGrid'
import RelatedCard from './RelatedCard'
import Button from './Button'

type Tag = {
  id: string,
  title: string,
  color?: string
}

type Item = {
  id: string,
  image: Image,
  name: string,
  text: string,
  ctaLabel: string,
  ctaHref: string,
  tags?: Array<Tag>
}
type Props = {
  title: string,
  ctaLabel: String,
  ctaHref: String,
  items: Array<Item>
}

const Related = ({ title, ctaLabel, ctaHref, items }: Props) => {

  const [extraMargin, setExtraMargin] = useState(false)
  
  useEffect(() => {
    items.map(item => item.tags && setExtraMargin(true))
  }, [])

  return (
    <Appear>
      <ContainerGrid
        cleanLeft={true}
        mdCleanLeft={true}
        lgCleanLeft={true}
        cleanRight={true}
        mdCleanRight={true}
        lgCleanRight={true}
      >
        <div className={`grid grid-cols-4 gap-4 mx-4 md:grid-cols-12 md:gap-6 lg:gap-8 text-gray md:mx-6 lg:mx-8 ${extraMargin === true ? "md:mb-48" : ""}`}>
          <div className="flex items-center justify-start col-span-3 md:col-span-4">
            <h3 className="mb-2 leading-tight font-primary text-big1 md:text-big2 md:mb-0">{title}</h3>
          </div>
          <div className="flex flex-col items-stretch justify-end col-span-4 mb-6 md:col-span-3 md:col-end-13 md:mb-0">
            <Button cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} internalLink={true} />
          </div>
          {items.map((item) => (
            <RelatedCard
              key={item.id}
              image={item.image}
              name={item.name}
              text={item.text}
              ctaLabel={item.ctaLabel}
              ctaHref={item.ctaHref}
              tags={item.tags ? item.tags : []}
            />
          ))}
        </div>
      </ContainerGrid>
    </Appear>
  )
}

export default Related

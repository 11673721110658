// @flow

import React from 'react'

type Props = {
  children: React$Node,
}
const Layout = ({ children }: Props) => {

  return (
    <>
      <div>
        <main className="relative pt-80px md:pt-150px print:pt-0">
          {children}
        </main>
      </div>
    </>
  )
}

export default Layout

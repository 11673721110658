// @flow

import React, { useState } from 'react'
import Appear from './Appear'
import { Formik, Form } from 'formik'
import FormField from './FormField'
import '../styles/calendar.css'
import Button from './Button'
import sessionService from '../services/session.service'

type Props = {
  item: Object,
  close: Function,
  literals: Object,
  userInfo: Object,
}

const ActivityForm = ({ item, close, literals, userInfo }: Props) => {
  const [validationErrors, setValidationErrors] = useState()
  const [loading, setLoading] = useState(false)
  const [valid, setValid] = useState(false) 
  const [error, setError] = useState(false)
  const [cantBook, setCantBook] = useState(false)
  // const userName = `${userInfo.name.charAt(0)}${userInfo.name.slice(1).toLowerCase()}`


  const Label = ({ title }) => (
    <div className="mb-1 uppercase font-secondary text-smaller">{title}</div>
  )

  // (user login hidden)
  // const handleBookActivity = async () => {
  //   const { email, name, id } = userInfo
  //   setLoading(true)
  //   const response = await sessionService.bookActivity({
  //     ...item,
  //     name,
  //     email,
  //     userId: id,
  //   })
  //   setLoading(false)
  //   if (response.valid) {
  //     setValid(true)
  //     setTimeout(() => {
  //       close()
  //     }, 3000)
  //   } else {
  //     if (!response.valid && response.error) {
  //       if (response.error === 'user-registered') {
  //         setCantBook('user-registered')
  //       }
  //       if (response.error === 'no-capacity') {
  //         setCantBook('no-capacity')
  //       }
  //       if (response.error === 'not-yet-available') {
  //         setCantBook('not-yet-available')
  //       }
  //       if (response.error === 'past-activity') {
  //         setCantBook('past-activity')
  //       }
  //       if (response.error === 'another-reservation') {
  //         setCantBook('another-reservation')
  //       }
  //     }
  //     setError(true)
  //   }
  // }

  return (
    // <Appear>
      <div className="flex flex-col pb-4">
        {/* (user login hidden) */}
        {/* <p className="mb-4 leading-snug font-secondary text-small">{`Has iniciado sesión como ${userName}. ¿Quieres reservar esta actividad?`}</p> */}
        <p className="mb-4 leading-snug font-secondary text-small">{'¿Quieres reservar esta actividad?'}</p>
        {/* (user login hidden) */}
        {/* <Button
          actionButton={true}
          actionButtonOnClick={() => handleBookActivity()}
          cta={{ label: 'Reservar' }}
          darkMode={true}
          loading={loading}
          />
          {valid && (
            <Appear>
              <div className="mt-2 font-secondary text-smaller">
                {literals.valid_activity_register}
              </div>
            </Appear>
          )}
          {error && (
            <Appear>
              <div className="mt-2 font-secondary text-smaller">
                {cantBook === 'user-registered' && literals.LiteralErrorRegistered}
                {cantBook === 'no-capacity' && literals.literalErrorCapacity}
                {cantBook === 'not-yet-available' && literals.literalErrorAvailability}
                {cantBook === 'past-activity' && literals.literalErrorPastActivity}
                {cantBook === 'another-reservation' && literals.literalErrorAnotherActivity}
                {!cantBook && literals.literalError}
              </div>
            </Appear>
          )} */}
          {/* INPUTS NAME / EMAIL */}
        <Appear className="col-span-1 md:col-span-6">
          <Formik
            enableReinitialize={true}
            initialValues={{
              email: '',
              name: ''
            }}
            validate={values => {
              const errors = {}
              const mailReg = new RegExp(/^([a-z0-9_.+-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/gm)
              Object.entries(values).forEach(field => {
                if (field[0] === "email") {
                  if (
                    typeof field[1] === "string"
                    && field[1] !== ""
                    && !mailReg.test(field[1])
                  )
                    errors[field[0]] = true
                }
              })
              setValidationErrors(errors)
              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setLoading(true)
              const response = await sessionService.bookActivity({
                ...item,
                ...values
              })
              
              setSubmitting(false)
              setLoading(false)
              if (response.valid) {
                setValid(true)
                setTimeout(() => {
                  close()
                }, 3000)
              } else {
                if (!response.valid && response.error) {
                  if (response.error === 'user-registered') {
                    setCantBook('user-registered')
                  }
                  if (response.error === 'no-capacity') {
                    setCantBook('no-capacity')
                  }
                  if (response.error === 'not-yet-available') {
                    setCantBook('not-yet-available')
                  }
                  if (response.error === 'past-activity') {
                    setCantBook('past-activity')
                  }
                  if (response.error === 'another-reservation') {
                    setCantBook('another-reservation')
                  }
                }
                setError(true)
              }
            }}
          >
            {() => {
              return (
                <Form>
                  <Label title={literals.email} />
                  <FormField
                    type="email"
                    name="email"
                    placeholder={literals.email}
                    error={validationErrors && validationErrors.email}
                    literalError={literals.literalErrorEmail}
                    required={true}
                  />
                  <Label title={literals.name} />
                  <FormField
                    type="text"
                    name="name"
                    placeholder={literals.name}
                    required={true}
                  />
                  <Button
                    submitButton={true}
                    cta={{ label: 'Enviar', href: '' }}
                    darkMode={true}
                    loading={loading}
                  />
                  {valid && (
                    <Appear>
                      <div className="mt-2 font-secondary text-smaller">
                        {literals.valid_activity_register}
                      </div>
                    </Appear>
                  )}
                  {error && (
                    <Appear>
                      <div className="mt-2 font-secondary text-smaller">
                        {cantBook === 'user-registered' && literals.LiteralErrorRegistered}
                        {cantBook === 'no-capacity' && literals.literalErrorCapacity}
                        {cantBook === 'not-yet-available' && literals.literalErrorAvailability}
                        {cantBook === 'past-activity' && literals.literalErrorPastActivity}
                        {cantBook === 'another-reservation' && literals.literalErrorAnotherActivity}
                        {!cantBook && literals.literalError}
                      </div>
                    </Appear>
                  )}
                </Form>
              )
            }}
          </Formik>
        </Appear>
      </div>
    // </Appear>
  )
}

export default ActivityForm

// @flow

import React from 'react'
import { Field } from 'formik'
import Svg from './Svg'

type Item = {
  id: string,
  title: string
}

type Props = {
  type: string,
  name: string,
  placeholder?: string,
  required?: boolean,
  options?: Array<Item>,
  onChangeCallback?: Function,
  error?: boolean,
  literalError?: string,
  label?: boolean
}

const FormField = ({ type, name, placeholder, required, options, onChangeCallback, error, label, literalError }: Props) => {

  function handleChange(event){
    onChangeCallback ? onChangeCallback(event) : null
  }

  if (type === "select" && options) { 
    return (
      <div className="relative w-full mb-10px font-secondary">
        <div className="absolute top-0 bottom-0 right-0 flex items-center justify-center pointer-events-none pr-10px">
          <Svg name="arrow-dropdown" width={16} height={16} color="gray" />
        </div>
        <Field 
          className={`w-full pt-4 pb-3 pl-5 pr-8 bg-transparent border rounded-none appearance-none transition-colors duration-150 ease-linear ${(typeof error !== "undefined" && error === true) ? "border-red-500 placeholder-red-500" : "border-gray placeholder-gray"}font-secondary text-small focus:outline-none`} 
          as={type} 
          name={name}
          required={required}
          onChange={event => handleChange(event.target.value)}
        >
          <option key={0} value={-1}>{placeholder}</option>
          {options.map(option => {
            return <option key={option.id} value={option.id}>{option.title}</option>
          })}
        </Field>
      </div>
    )
  } else {
    return (
    <div className="font-secondary">
      {label && <div className="mb-1 uppercase text-tiny text-gray font-secondary">{placeholder}{required ? "*" : ""}</div>}
      <Field 
        className={`w-full px-5 pt-4 pb-3 bg-transparent border rounded-none appearance-none mb-10px placeholder-gray transition-colors duration-150 ease-linear ${(typeof error !== "undefined" && error === true) ? "border-red-500" : "border-gray"} font-secondary text-small focus:outline-none`}
        type={type} 
        name={name} 
        placeholder={placeholder}
        required={required}
      />
      <div className={`text-tiny uppercase text-red-500 overflow-hidden transition-all duration-150 ease-in-out ${error ? "h-8 text-opacity-100" : "h-0 text-opacity-0"}`}>{literalError}</div>
    </div>
    )
  }
}

export default FormField

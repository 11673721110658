// @flow

import React, { useState } from 'react'
import Appear from './Appear'
// import moment from 'moment'
import { Formik, Form } from 'formik'
import { navigate } from 'gatsby'
import ContainerGrid from './ContainerGrid'
import DropCapText from './DropCapText'
// import Calendar from 'react-calendar'
// import Svg from './Svg'
import FormField from './FormField'
import FormCheckbox from './FormCheckbox'
import '../styles/calendar.css'
import Button from './Button'
import ButtonSecondary from './ButtonSecondary'
import sessionService from '../services/session.service'
import { useGlobalState } from 'store/'
import HTMLText from './HTMLText'

type Props = {
  pageName: string,
  form_titleInfo: string,
  form_titleAddress: string,
  form_titlePassword: string,
  placeholder_name: string,
  placeholder_surname: string,
  placeholder_dni: string,
  placeholder_email: string,
  placeholder_phone: string,
  placeholder_birthdate: string,
  placeholder_password: string,
  placeholder_repassword: string,
  placeholder_addressCountry: string,
  placeholder_addressRegion: string,
  placeholder_addressCity: string,
  placeholder_addressStreet: string,
  placeholder_addressNumber: string,
  placeholder_addressPostalCode: string,
  terms: string,
  text: string,
  ctaLabel: string,
  ctaHref: string,
  signUpText: string,
  signUpLabel: string,
  signUpHref: string,
  literalErrorEmail: string,
  literalErrorPhone: string,
  literalErrorId: string,
  literalErrorPasswordSecurity: string,
  literalErrorPasswordRepeat: string,
  literalErrorPostalCode: string,
  literalIdAlreadyExists: string,
  literalEmailAlreadyExists: string,
}

const SignupForm = ({
    form_titleInfo,
    // form_titleAddress,
    form_titlePassword,
    placeholder_name,
    placeholder_surname,
    placeholder_dni,
    placeholder_email,
    // placeholder_phone,
    // placeholder_birthdate,
    placeholder_password,
    placeholder_repassword,
    // placeholder_addressCountry,
    // placeholder_addressRegion,
    // placeholder_addressCity,
    // placeholder_addressStreet,
    // placeholder_addressNumber,
    // placeholder_addressPostalCode,
    terms,
    text,
    ctaLabel,
    ctaHref,
    signUpText,
    signUpLabel,
    signUpHref,
    literalErrorEmail,
    // literalErrorPhone,
    literalErrorId,
    literalErrorPasswordSecurity,
    literalErrorPasswordRepeat,
    // literalErrorPostalCode,
    literalIdAlreadyExists,
    literalEmailAlreadyExists,
  }: Props) => {

  const [session, dispatch] = useGlobalState()
  const [validationErrors, setValidationErrors] = useState()
  const [loading, setLoading] = useState(false)
  const [userExists, setUserExists] = useState(false)

  // Sets calendar minimum date to select
  // const today = new Date

  // function formatDate(locale, date) {
  //   const dateES = (date.toLocaleDateString(locale, { weekday: "short" }))
  //   if (dateES === "mié.") {
  //     return ("x")
  //   } else {
  //     return (
  //       dateES.charAt(0)
  //     )
  //   }
  // }

  async function logInAfterSignUp(form) {
    const response = await sessionService.doLogin(form)
    if (response) {
      dispatch({ type: 'login', payload: response })
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  if (session) {
    navigate("/es/perfil-editar")
  }

  return (
    <Appear>
      <ContainerGrid
        // mdCleanLeft={true}
        // lgCleanLeft={true}
      >
        <div className="grid grid-cols-1 gap-4 mb-48 md:grid-cols-10 md:gap-6 lg:gap-8 text-gray md:mb-84">
        {/* <div className="grid grid-cols-1 gap-4 mb-48 md:grid-cols-11 md:gap-6 lg:gap-8 text-gray md:mb-84"> */}
          {/* <div data-name="left-column" className="hidden col-span-1 md:col-span-5 md:block">
            <DropCapText text={text} />
          </div> */}
          {/* <Appear className="col-span-1 md:col-span-6"> */}
          <Appear className="col-span-1 md:col-start-3 md:col-span-6">
            <Formik
              enableReinitialize={true}
              initialValues={{
                email: "",
                idNumber: "",
                name: "",
                surname: "",
                // phoneNumber: "",
                // birthDate: "",
                password: "",
                repassword: "",
                terms: false
              }}
              validate={values => {
                const errors = {}
                // const phoneReg = new RegExp(/^[9|6|7][0-9]{8}$/)
                const dniReg = new RegExp(/^[0-9XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i)
                const mailReg = new RegExp(/^([a-z0-9_.+-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/gm)
                let password

                Object.entries(values).forEach(field => {
                  // if (field[0] === "phoneNumber") {
                  //   if (
                  //     typeof field[1] === "string"
                  //     && field[1] !== ""
                  //     && !phoneReg.test(field[1])
                  //   ) errors[field[0]] = true
                  // }
                  if(field[0] === "email") {
                    if (
                      typeof field[1] === "string"
                      && field[1] !== ""
                      && !mailReg.test(field[1])
                    ) errors [field[0]] = true
                  }
                  if(field[0] === "idNumber") {
                    if (
                      typeof field[1] === "string"
                      && field[1] !== ""
                      && !dniReg.test(field[1])
                    ) errors [field[0]] = true
                  }
                  if (field[0] === "password") {
                    password = field[1]
                    if (
                      typeof field[1] === "string"
                      && field[1] !== ""
                      && field[1].length < 6
                    ) errors[field[0]] = true
                  }
                  if (field[0] === "repassword") {
                    if (
                      typeof field[1] === "string"
                      && field[1] !== ""
                      && field[1] !== password
                    ) errors[field[0]] = true
                  }
                })
                setValidationErrors(errors)
                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setLoading(true)
                delete values.repassword
                delete values.terms
                const response = await sessionService.doSignUp(values)
                setSubmitting(false)
                if (!response.valid && response.error) {
                  if (response.error === 'user-exists-Email') {
                    setUserExists('user-exists-Email')
                    setLoading(false)
                  }
                  if (response.error === 'user-exists-DocumentoIdentidad') {
                    setUserExists('user-exists-DocumentoIdentidad')
                    setLoading(false)
                  }
                }
                if (response.valid) {
                  delete values.idNumber
                  // delete values.birthDate
                  delete values.name
                  delete values.surname
                  // delete values.phoneNumber
                  // delete values.addressCountry
                  // delete values.addressRegion
                  // delete values.addressCity
                  // delete values.addressStreet
                  // delete values.addressNumber
                  // delete values.addressPostalCode
                  logInAfterSignUp(values)
                }
              }}
            >
              {({values, setFieldValue}) => {
              return (
                <Form className="grid grid-cols-4 md:grid-cols-6 md:gap-6 lg:gap-5">
                  <label className="col-span-4 mt-6 uppercase md:my-0 mb-10px md:col-span-6 font-secondary text-smaller">
                    {form_titleInfo}
                  </label>
                  <div className="col-span-4 mb-4 md:col-span-3 md:mb-0">
                    <FormField
                      type="text"
                      name="name"
                      placeholder={placeholder_name}
                      required={true}
                      label={true}
                    />
                  </div>
                  <div className="col-span-4 mb-4 md:col-span-3 md:mb-0">
                    <FormField
                      type="text"
                      name="surname"
                      placeholder={placeholder_surname}
                      required={true}
                      label={true}
                    />
                  </div>
                  <div className="col-span-4 mb-4 md:col-span-3 md:mb-0">
                    <FormField
                      type="email"
                      name="email"
                      placeholder={placeholder_email}
                      error={(validationErrors && validationErrors.email) || userExists === 'user-exists-Email'}
                      literalError={userExists === 'user-exists-Email' ? literalEmailAlreadyExists : literalErrorEmail}
                      label={true}
                      required={true}
                    />
                  </div>
                  {/* <div className="col-span-4 mb-4 md:col-span-3 md:mb-0">
                    <FormField
                      type="tel"
                      name="phoneNumber"
                      placeholder={placeholder_phone}
                      error={validationErrors && validationErrors.phoneNumber}
                      literalError={literalErrorPhone}
                      label={true}
                      required={true}
                    />
                  </div> */}
                  <div className="col-span-4 mb-4 md:col-span-3 md:mb-0">
                    <FormField
                      type="text"
                      name="idNumber"
                      placeholder={placeholder_dni}
                      error={(validationErrors && validationErrors.idNumber) || userExists === 'user-exists-DocumentoIdentidad'}
                      literalError={userExists === 'user-exists-DocumentoIdentidad' ? literalIdAlreadyExists : literalErrorId}
                      label={true}
                      required={true}
                    />
                  </div>
                    {/* <label className="col-span-4 mt-6 uppercase md:my-0 mb-10px md:col-span-6 font-secondary text-smaller">
                      {form_titleAddress}
                    </label>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="addressCountry"
                        placeholder={placeholder_addressCountry}
                        required={false}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="addressRegion"
                        placeholder={placeholder_addressRegion}
                        required={false}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="addressCity"
                        placeholder={placeholder_addressCity}
                        // error={validationErrors && validationErrors.addressCity}
                        required={false}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="addressStreet"
                        placeholder={placeholder_addressStreet}
                        required={false}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="addressNumber"
                        placeholder={placeholder_addressNumber}
                        required={false}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="addressPostalCode"
                        placeholder={placeholder_addressPostalCode}
                        error={validationErrors && validationErrors.addressPostalCode}
                        literalError={literalErrorPostalCode}
                        required={false}
                        label={true}
                      />
                    </div> */}
                  <label className="col-span-4 mt-6 uppercase md:my-0 mb-10px md:col-span-6 font-secondary text-smaller">
                    {form_titlePassword}
                  </label>
                  <div className="col-span-4 mb-4 md:col-span-3 md:mb-0">
                    <FormField
                      type="password"
                      name="password"
                      placeholder={placeholder_password}
                      error={validationErrors && validationErrors.password}
                      literalError={literalErrorPasswordSecurity}
                      label={true}
                      required={true}
                    />
                  </div>
                  <div className="col-span-4 mb-4 md:col-span-3 md:mb-0">
                    <FormField
                      type="password"
                      name="repassword"
                      placeholder={placeholder_repassword}
                      error={validationErrors && validationErrors.repassword}
                      literalError={literalErrorPasswordRepeat}
                      label={true}
                      required={true}
                    />
                  </div>
                  {/* <label className="col-span-4 mt-6 uppercase md:my-0 mb-10px md:col-span-6 font-secondary text-smaller">
                    {placeholder_birthdate}
                  </label>
                  <div className="col-span-4 mb-3 text-center no-underline uppercase md:col-span-3 font-secondary text-small">
                    <Calendar
                      maxDate={today}
                      maxDetail={"month"}
                      defaultView={"decade"}
                      minDetail={"decade"}
                      locale={"es"}
                      formatShortWeekday={(locale, date) => formatDate(locale, date)}
                      onClickDay={(value) => setFieldValue("birthDate", moment(value).format("YYYY-MM-DD"))}
                      prevLabel={<Svg name="arrow-left" width={16} height={16} color="gray" />}
                      nextLabel={<Svg name="arrow-right" width={16} height={16} color="gray" />}
                    />
                  </div> */}
                  <div className="flex items-center justify-start col-span-6 mt-5 text-gray text-tiny">
                    <FormCheckbox
                      name="terms"
                      value={values.terms}
                      required={true}
                      isChecked={values.terms === true}
                      handleCheckbox={(value) => setFieldValue("terms", value)}
                    />
                    <HTMLText text={terms} className="font-secondary" />
                  </div>
                  <div className="col-span-4 mt-8 md:col-start-1 md:col-span-2 md:mt-8 lg:mt-6">
                    <Button submitButton={true} cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} loading={loading}/>
                  </div>
                </Form>
              )}}
            </Formik>
            <div className="grid grid-cols-4 gap-4 pt-4 md:grid-cols-6 md:gap-6 lg:gap-5 md:pt-8">
              <HTMLText className="col-span-4 col-start-1 font-secondary text-normal" text={signUpText}/>
              <div className="col-span-4 col-start-1">
                <ButtonSecondary cta={{ href: signUpHref, label: signUpLabel }} />
              </div>
            </div>
          </Appear>
        </div>
      </ContainerGrid>
    </Appear>
  )
}

export default SignupForm

// @flow

import React from 'react'
import Appear from './Appear'
import 'swiper/css/swiper.css'
import useIsMobile from '../hooks/useIsMobile'
import ContainerGrid from './ContainerGrid'
import SliderTestimonialsDesktop from './SliderTestimonialsDesktop'
import SliderTestimonialsMobile from './SliderTestimonialsMobile'

type Props = {
  supertitle: string,
  title: string,
  items: Array<Object>,
  arrows: Object,
  margins?: string,
}

const Testimonials = ({ supertitle, title, items, arrows, margins }: Props) => {
  const isMobile = useIsMobile()

  const heading = <ContainerGrid>
    <div className="grid grid-cols-4 gap-4 md:grid-cols-10 md:gap-6 lg:gap-8">
      <div className="col-span-4 mb-10 md:mb-20">
        <span className="leading-none uppercase font-secondary text-smaller md:text-small text-gray">
          {supertitle}
        </span>
        <h3 className="mt-4 leading-tight md:mt-12 font-primary text-big1 md:text-big2 text-gray">
          {title}
        </h3>
      </div>
    </div>
  </ContainerGrid>

  if (isMobile) {
    return (
      <Appear>
        <div className={`${margins ? margins : "my-32 md:my-72"} relative`}>
          {heading}
          <div className="mx-4 md:mx-6 lg:mx-8">
            <SliderTestimonialsMobile items={items} arrows={arrows} />
          </div>
        </div>
      </Appear>
  )
  }

  return (
    <Appear>
      <div className={`${margins ? margins : "my-32 md:my-72"} relative`}>
        {heading}
        <div className="mx-4 md:mx-6 lg:mx-8">
          <SliderTestimonialsDesktop items={items} arrows={arrows} />
        </div>
      </div>
    </Appear>
  )
}

export default Testimonials

// @flow

import React from 'react'
import Appear from './Appear'
import type { Image } from 'types/'
import { Link } from 'gatsby'
import useIsMobile from '../hooks/useIsMobile'
import Button from './Button'
import ContainerGrid from './ContainerGrid'
import Newsletter from './Newsletter'
import Svg from './Svg'
import SocialLink from './SocialLink'
import HTMLText from './HTMLText'

type ExternalLink = {
  id: string,
  ctaLabel: string,
  ctaHref: string,
}
type Policy = {
  id: string,
  label: string,
  href: string,
}
type Social = {
  title: string,
  items: Array<ExternalLink>,
  background: Object,
}
type Logo = {
  desktop: Image,
  mobile: Image,
}
type footerProps = {
  logo: Logo,
  links: Array<ExternalLink>,
  social: Social,
  policy: Array<Policy>,
  copyright: string,
  leftColumn: Object,
  rightColumn: Object,
}
type Props = {
  newsletter: Object,
  footer: footerProps,
}

const Footer = ({ newsletter, footer }: Props) => {
  const isMobile = useIsMobile()

  return (
    <Appear className="print:hidden">
      <ContainerGrid>
        <Newsletter
          title={newsletter.title}
          text={newsletter.text}
          inputText={newsletter.inputText}
          termsText={newsletter.termsText}
          cta={{ label: newsletter.ctaLabel, href: newsletter.ctaHref }}
          image={newsletter.image}
          requestSentMessage={newsletter.requestSentMessage}
          literalErrorEmail={newsletter.literalErrorEmail}
          literalError={newsletter.literalError}
        />
        {isMobile
        ? <div className="grid grid-cols-4 gap-4">
          <div className="flex items-center justify-center col-span-4 my-10">
          {footer.social.items.map(item => <div key={item.id} className="mr-2">
              <SocialLink
                icon={item.ctaLabel}
                background={footer.social.background}
                href={item.ctaHref}
              />
            </div>)}
          </div>
          <div className="flex flex-col items-center justify-center col-span-2 col-start-2 mb-10">
            <span className="mb-1 font-bold text-gray font-secondary text-smaller">GRUPO</span>
            <Svg name="logo" color="gray" width="100%" height="100%"/>
          </div>
          <div className="flex flex-col items-center justify-center col-span-4 mb-20">
            <div className="flex items-center justify-center">
              {footer.policy.map((item, index) => <div
              key={item.id}
              className="flex items-center"
              >
                {index !== 0 && <span className="mx-4 text-gray font-secondary text-smaller">|</span>}
                  <Link
                      to={item.href}
                      >
                        <div className="underline text-smaller text-gray font-secondary">
                          {item.label}
                        </div>
                  </Link>
                </div>)}
            </div>
            <span className="mt-4 text-smaller text-gray font-secondary">{footer.copyright}</span>
          </div>
        </div>
        : <div>
        <Appear>
          <div className="w-full h-1 mt-20 mb-24 border-b border-gray print:hidden"/>
          <div
          className="grid grid-cols-10 gap-6 mb-24 lg:gap-8 print:hidden"
          >
            <div className="col-span-2 col-start-1 row-start-1 row-end-2">
              <span className="text-bigger2 text-gray font-primary">{footer.leftColumn.title}</span>
              <HTMLText className="my-8 text-normal text-gray font-secondary" text={footer.leftColumn.text}/>
            </div>
            <div className="col-span-2 col-start-1 row-start-2 row-end-3">
              <span className="text-bigger2 text-gray font-primary">{footer.social.title}</span>
              <div className='flex items-center justify-start mt-8'>
                {footer.social.items.map(item => <div key={item.id} className="mr-2">
                  <SocialLink
                    icon={item.ctaLabel}
                    background={footer.social.background}
                    href={item.ctaHref}
                  />
                </div>)}
              </div>
            </div>
            <div className="col-span-2 col-start-4 row-start-1 row-end-2">
              <span className="text-bigger2 text-gray font-primary">{footer.rightColumn.title}</span>
              <HTMLText className="my-8 text-normal text-gray font-secondary" text={footer.rightColumn.text}/>
            </div>
            <div className="col-span-2 col-start-4 row-start-2 row-end-3 mt-12">
              {footer.policy.map(item => <Link
                key={item.id}
                to={item.href}
                >
                  <div className="underline text-normal text-gray hover:text-white font-secondary">
                    {item.label}
                  </div>
                </Link>)}
              <span className="text-normal text-gray font-secondary">{footer.copyright}</span>
            </div>
            <div className="col-span-3 col-start-8 row-start-1 row-end-2">
              <Svg name="logo-group" color="gray" widht className="md:flex md:flex-col md:items-left md:justify-center"/>
            </div>
            <div className="col-span-3 col-start-8 row-start-2 row-end-3">
              {footer.links.map(link => <div
              key={link.id}
              className='mb-2'
              >
                <Button cta={{ href: link.ctaHref, label: link.ctaLabel }} openNewWindow={true} />
              </div>)}
            </div>
          </div>
        </Appear>
        </div>
      }
      </ContainerGrid>
    </Appear>
  )
}

export default Footer

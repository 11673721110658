// @flow
import type { SessionState, Action } from 'types/reducers.types'
import sessionService from '../services/session.service'

const initialState: SessionState = JSON.parse(sessionService.getSession())
const reducer = (state: SessionState, action: Action): SessionState => {
  switch (action.type) {
    case 'login':
    localStorage.setItem('session', JSON.stringify(action.payload))
      return action.payload
    case 'logout':
    localStorage.removeItem('session')
      return null
    default:
      return state
  }
}

export { reducer as sessionReducer, initialState as session }
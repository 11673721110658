// @flow

import React from 'react'
import type { Image } from 'types/'
import Button from './Button'
import HTMLText from './HTMLText'

type Props = {
  mask: Image,
  image: Image,
  supertitle: string,
  title: string,
  text: string,
  ctaLabel: string,
  ctaHref: string,
  isMobile: boolean
}

const SlideActivity = ({ mask, image, supertitle, title, text, ctaLabel, ctaHref, isMobile }: Props) => {
  // $FlowFixMe
  const url = `url(${image.dir}${image.name.normal}-1200.jpg)`
  
  // $FlowFixMe
  const src = `${mask.dir}${mask.name.normal}.png`

  if (isMobile) {
    return (
      <div className="relative w-full h-full px-4">
        <div className="relative flex flex-col items-stretch justify-between w-full h-full">
          <div
          className="flex-1 -mb-24 overflow-x-hidden bg-center bg-no-repeat bg-cover"
          style={{ backgroundImage: url }}
          >
          </div>
          <div className="relative px-6 pb-6">
            <div className="absolute bottom-0 left-0 z-0 w-auto h-full">
              <img
                src={src}
                className="w-full h-full"
              />
            </div>
            <div className="relative z-10 pt-12">
              <span className="w-1/2 uppercase text-smaller text-gray font-secondary">{supertitle}</span>
                <h2 className="w-1/2 mt-4 leading-none font-primary text-gray text-big1">{title}</h2>
                <HTMLText
                  className="mt-4 mb-6 leading-snug font-secondary text-gray text-normal"
                  text={text}
                />
                <div className="mt-5">
                  <Button cta={{ label: ctaLabel, href: ctaHref }} darkMode={true}/>
                </div>
              </div>
            </div>
        </div>
      </div>
    )
  }

  return (
    <div className="relative grid w-full grid-cols-8 gap-6 lg:gap-8">
      <div
        className="absolute inset-0 bg-center bg-no-repeat bg-cover"
        style={{ backgroundImage: url }}
      />
      <div className="relative col-span-5 -mr-px">
        <div className="absolute inset-y-0 right-0 -mr-6 lg:-mr-8">
          <svg className="w-auto h-full" viewBox="0 0 126 565"  fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0 262.472C0 422.25 51.635 513.28 125 565h1V0h-1C49.127 58.51 0 142.514 0 262.472z" fill="#F1EBDE"/></svg>
        </div>
      </div>
      <div className="relative flex flex-col items-stretch justify-center col-span-3 col-end-9 py-16 pr-16 bg-primary-beige">
        <span className="uppercase text-small text-gray font-secondary">{supertitle}</span>
        <h2 className="mt-4 leading-none font-primary text-gray text-big2">{title}</h2>
        <HTMLText
          className="mt-5 font-secondary text-gray text-normal"
          text={text}
        />
        <div className="mt-8">
          <Button cta={{ label: ctaLabel, href: ctaHref }} darkMode={true}/>
        </div>
      </div>
    </div>
  )
}

export default SlideActivity

// @flow

import React, { createContext, useContext, useReducer } from 'react'

export const StateContext: React$Context<?Object> = createContext()

export const GlobalState = ({ reducer, initialState, children }: { reducer: any, initialState: Object, children: React$Node }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)

export const useGlobalState: function = () => useContext(StateContext)
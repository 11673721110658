import { useEffect, useState } from 'react'

const debounce = (func, wait, immediate) => {
	let timeout
	return function() {
		const context = this, args = arguments
		const later = function() {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		const callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false)
  const handleResize = debounce(() => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    if (width < 1024) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, 250)
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return isMobile
}

export default useIsMobile

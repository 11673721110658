// @flow

import React from 'react'
import Appear from './Appear'
import type { Image } from 'types/'
import { Link } from 'gatsby'
import ActivityTag from './ActivityTag'
import ButtonSecondary from './ButtonSecondary'
import ImageLazy from './ImageLazy'

type Tag = {
  id: string,
  title: string,
  color?: string
}

type Props = {
  image: Image,
  name: string,
  text: string,
  ctaLabel: string,
  ctaHref: string,
  tags?: Array<Tag>,
}

const RelatedCards = ({image, name, text, ctaLabel, ctaHref, tags }: Props) => {
  return (
    <Link to={ctaHref} className="col-span-4">
      <Appear className="h-full">
        <div className="flex flex-col items-stretch h-full border border-gray text-gray">
          <div className="relative items-center justify-center h-64 overflow-hidden md:flex">
            {image && <ImageLazy className="w-full h-auto" image={image} alt={name} />}
          </div>
          <div className="p-5 md:p-8">
            <h4 className={`font-primary text-big1 md:text-big2 mb-5 leading-none`}>{name}</h4>
            {tags && (
              <div className="flex flex-wrap items-start justify-start mb-5">
                {tags.map((tag, index) => {
                  return (
                    <ActivityTag key={index} item={tag}/>
                  )
                })}
              </div>
            )}
            <p className={`${tags ? "md:mb-12 text-small" : "md:mb-8 text-small md:text-normal"} mb-8 leading-snug font-secondary`}>{text}</p>
            <ButtonSecondary cta={{ label: ctaLabel, href: ctaHref }} actionButton={true} disabled={true}/>
          </div>
        </div>
      </Appear>
    </Link>
  )
}

export default RelatedCards
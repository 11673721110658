// @flow

import React, { useState, useEffect, useRef } from 'react'
import type { Image } from 'types/'
import Button from './Button'
import useDidMount from 'hooks/useDidMount'
import Svg from './Svg'
import anime from 'animejs/lib/anime.es.js'

type SlideItem = {
  id: string,
  image: Image,
  supertitle?: string,
  title: string,
  text?: string,
  ctaLabel?: string,
  ctaHref?: string,
}
type Props = {
  items: Array<SlideItem>,
  mask: Image,
}

let touchCache = {
  start: {},
  end: {}
}

const SliderHome = ({ items, mask }: Props) => {
  const [active, setActive] = useState(0)
  const contentEl = useRef(null)
  const maskEl = useRef(null)
  const [mouseOverLeft, setMouseOverLeft] = useState(false)
  const [mouseOverRight, setMouseOverRight] = useState(false)
  const didMount = useDidMount()
  const [maskLoaded, setMaskLoaded] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const handleMaskLoaded = () => {
    setMaskLoaded(true)
  }

  useEffect(() => {
    if (maskLoaded) {
      setLoaded(true)
    }
  }, [maskLoaded])

  async function showSlide() {
    anime
      .timeline({
        duration: 700,
        easing: 'cubicBezier(.5, .05, .1, .3)'
      })
      .add({
        targets: maskEl.current,
        scale: 1,
        translateY: 0,
        duration: 500
      })
      .add({
        targets: contentEl.current,
        opacity: 1,
        duration: 500
      })
  }

  // async function toSlide(index: number) {
  //   hideSlide('NONE', index)
  // }

  async function hideSlide(direction: string, index?: number) {
    const tl = anime
      .timeline({
        duration: 700,
        easing: 'cubicBezier(.5, .05, .1, .3)'
      })
      .add({
        targets: contentEl.current,
        opacity: 0,
        duration: 500
      })
      .add({
        targets: maskEl.current,
        scale: 1.8,
        translateY: -110,
        duration: 500
      })
    await tl.finished
    let next = 0
    switch (direction) {
      case 'FORWARDS':
        next = active < items.length - 1 ? active + 1 : 0
        break
      case 'BACKWARDS':
        next = active > 0 ? active - 1 : items.length - 1
        break
      default:
        break
    }
    setActive(index || next)
  }

  useEffect(() => {
    if (didMount) {
      showSlide()
    }
  }, [active])

  const prevItem = () => {
    hideSlide('BACKWARDS')
  }

  const nextItem = () => {
    hideSlide('FORWARDS')
  }

  function handleTouch(e) {
    if (e.type === 'touchstart') {
      const { clientX, clientY } = e.touches[0]
      touchCache.start = {
        x: clientX,
        y: clientY
      }
      return
    }
    if (e.type === 'touchmove') {
      const { clientX, clientY } = e.touches[0]
      touchCache.end = {
        x: clientX,
        y: clientY
      }
      return
    }
    if (e.type === 'touchend') {
      const deltaX = touchCache.end.x - touchCache.start.x
      const deltaY = touchCache.end.y - touchCache.start.y
      // Debes mover mas de 1/4 del ancho de la pantalla y menos de 1/8 de su altura para detectar un swipe
      if (Math.abs(deltaX) < window.innerWidth / 4 || Math.abs(deltaY) > window.innerHeight / 8) return
      if (deltaX < 0) {
        hideSlide('FORWARDS')
      } else {
        hideSlide('BACKWARDS')
      }
    }
  }

  // $FlowFixMe
  const url = `url(${items[active].image.dir}${items[active].image.name.normal}-1200.${items[active].image.extension})`
  
  // $FlowFixMe
  const src = `${mask.dir}${mask.name.normal}.${mask.extension}`

  return (
    <div
      className="relative flex items-center w-screen h-screen overflow-visible mobileFill md:h-80vh scroll-snap-x md:hidden"
      onTouchStart={handleTouch}
      onTouchEnd={handleTouch}
      onTouchMove={handleTouch}
    >
      {!loaded && <div className="absolute flex items-center justify-center w-full h-full">
        <div className="loader"></div>
      </div>}
      <div className="inline-block w-screen overflow-x-hidden">
        <div className="relative w-screen h-screen">
          <div className="w-full h-full slide-mask">
            <div
              className="w-full h-1/2"
              style={loaded ? {
                backgroundImage: url,
                backgroundSize: 'cover',
                backgroundPosition: 'center top',
                backgroundRepeat: 'no-repeat'
              } : {}}
            />
          </div>
          <div className="absolute inset-x-0 bottom-0">
            <div className="relative w-full h-full">
              <img
                ref={maskEl}
                src={src}
                className={loaded ? "w-full h-full" : ""}
                onLoad={handleMaskLoaded}
              />
              {loaded && <div
                className="absolute inset-x-0 top-0 mx-4 mt-12"
                ref={contentEl}
              >
                {items[active].supertitle && <span className="uppercase text-smaller text-gray font-secondary">
                  {items[active].supertitle}
                </span>}
                <h2 className="mt-4 leading-none font-primary text-gray text-big4">
                  {items[active].title}
                </h2>
                {items[active].ctaLabel && items[active].ctaHref && items[active].text && <div className="mt-10">
                  <Button cta={{label: items[active].ctaLabel, href: items[active].ctaHref }} darkMode={true} />
                </div>}
              </div>}
            </div>
          </div>
        </div>
      </div>
      {loaded && <div className="absolute bottom-0 w-full">
        <div className="relative grid w-full h-full grid-cols-4 md:grid-cols-12">
          <div className="flex items-end col-span-4 col-start-1 md:col-start-9 md:justify-start mb-15vh md:mb-8vh md:-ml-6">
            <div
              className="flex justify-between w-full mt-8 md:w-auto"
            >
              <div
                onClick={prevItem}
                className="mx-4"
                onMouseOver={() => setMouseOverLeft(true)}
                onMouseLeave={() => setMouseOverLeft(false)}
              >
                <Svg
                  name="arrow-left"
                  width={12}
                  height={20}
                  color={mouseOverLeft ? 'primary-brown' : 'gray'}
                />
              </div>
              <div className="flex items-center">
                {items.map((item, index) =>
                <div key={index} className={`w-2 h-2 mx-1 border-solid rounded-full border-gray transition-all duration-150 ease-in-out ${active === index ? "border-4" : "border"}`}/>
                )}
              </div>
              <div
                onClick={nextItem}
                className="mx-4"
                onMouseOver={() => setMouseOverRight(true)}
                onMouseLeave={() => setMouseOverRight(false)}
              >
                <Svg
                  name="arrow-right"
                  width={12}
                  height={20}
                  color={mouseOverRight ? 'primary-brown' : 'gray'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default SliderHome

// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import moment from 'moment'
import { Formik, Form } from 'formik'
import ContainerGrid from './ContainerGrid'
import DropCapText from './DropCapText'
import Calendar from 'react-calendar'
import Svg from './Svg'
import FormField from './FormField'
import '../styles/calendar.css'
import Button from './Button'
import sessionService from '../services/session.service'
import { useGlobalState } from 'store/'

type Props = {
  pageName: string,
  form_titleInfo: string,
  form_titleAddress: string,
  form_titlePassword: string,
  placeholder_name: string,
  placeholder_surname: string,
  placeholder_dni: string,
  placeholder_email: string,
  placeholder_phone: string,
  placeholder_birthdate: string,
  placeholder_password: string,
  placeholder_repassword: string,
  placeholder_addressCountry: string,
  placeholder_addressRegion: string,
  placeholder_addressCity: string,
  placeholder_addressStreet: string,
  placeholder_addressNumber: string,
  placeholder_addressPostalCode: string,
  terms: string,
  text: string,
  ctaLabel: string,
  ctaHref: string,
  literalErrorEmail: string,
  literalErrorPhone: string,
  literalErrorId: string,
  literalErrorPostalCode: string,
  literalErrorPasswordRepeat: string,
}

const EditprofileForm = ({
    form_titleInfo,
    // form_titleAddress,
    form_titlePassword,
    placeholder_name,
    placeholder_surname,
    placeholder_dni,
    placeholder_email,
    placeholder_phone,
    placeholder_birthdate,
    placeholder_password,
    placeholder_repassword,
    // placeholder_addressCountry,
    // placeholder_addressRegion,
    // placeholder_addressCity,
    // placeholder_addressStreet,
    // placeholder_addressNumber,
    // placeholder_addressPostalCode,
    text,
    ctaLabel,
    ctaHref,
    literalErrorEmail,
    literalErrorPhone,
    literalErrorId,
    // literalErrorPostalCode,
    literalErrorPasswordRepeat,
  }: Props) => {

  const [firstLoad, setFirstLoad] = useState(true)
  const [userInfo, setUserInfo] = useState()
  const [session, dispatch] = useGlobalState()
  const [validationErrors, setValidationErrors] = useState()
  const [loading, setLoading] = useState(false)

  const today = new Date
  
  function formatDate(locale, date) {
    const dateES = (date.toLocaleDateString(locale, { weekday: "short" }))
    if (dateES === "mié.") {
      return ("x")
    } else {
      return (
        dateES.charAt(0)
      )
    }
  }

  async function getUserInfo() {
    const response = await sessionService.getUserInfo(session.id)
    setFirstLoad(false)
    setUserInfo(response)
  }

  useEffect(() => {
    if (firstLoad && session) {
      getUserInfo()
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onbeforeunload = () => {
        window.scrollTo(0,0)
      }
    }
  }, [])

  const refresh = () => {
    location.reload()
  }

  // function getFromUserInfo(value) {
  //   return (
  //     typeof value !== "undefined" || 
  //     value !== null || 
  //     value !== "") ? 
  //     value : ""
  // }


  return (
    <Appear>
      <ContainerGrid
        mdCleanLeft={true}
        lgCleanLeft={true}
      >
        <div
        className="grid grid-cols-1 gap-4 mb-48 md:grid-cols-11 md:gap-6 lg:gap-8 text-gray md:mb-84"
        >
          <div data-name="left-column" className="hidden col-span-1 md:col-span-5 md:block">
            <DropCapText text={text} />
          </div>
          {firstLoad === false && typeof userInfo !== "undefined" &&
            <Appear className="col-span-1 md:col-span-6">
              <Formik
                enableReinitialize={true}
                initialValues={ session ? {
                  id: session.id,
                  token: session.token,
                  email: userInfo.email,
                  idNumber: userInfo.idNumber,
                  name: userInfo.name,
                  surname: userInfo.surname,
                  phoneNumber: userInfo.phoneNumber,
                  birthDate: moment(userInfo.birthDate, "YYYY-MM-DD").toDate(),
                  // addressCountry: getFromUserInfo(userInfo.addressCountry),
                  // addressRegion: getFromUserInfo(userInfo.addressRegion),
                  // addressCity: getFromUserInfo(userInfo.addressCity),
                  // addressStreet: getFromUserInfo(userInfo.addressStreet),
                  // addressNumber: getFromUserInfo(userInfo.addressNumber),
                  // addressPostalCode: getFromUserInfo(userInfo.addressPostalCode),
                  password: '',
                  repassword: ''
                } : {}}
                validate={values => {
                  const errors = {}
                  const phoneReg = new RegExp(/^[9|6|7][0-9]{8}$/)
                  const dniReg = new RegExp(/^[0-9XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i)
                  const mailReg = new RegExp(/^([a-z0-9_.+-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/gm)
                  // const postalReg = new RegExp(/^\d{5}$/g)
                  let password

                  Object.entries(values).forEach(field => {
                    if (field[0] === "phoneNumber") {
                      if (
                        typeof field[1] === "string"
                        && field[1] !== ""
                        && !phoneReg.test(field[1])
                      ) errors[field[0]] = true
                    }
                    if(field[0] === "email") {
                      if (
                        typeof field[1] === "string"
                        && field[1] !== ""
                        && !mailReg.test(field[1])
                      ) errors [field[0]] = true
                    }
                    if(field[0] === "idNumber") {
                      if (
                        typeof field[1] === "string"
                        && field[1] !== ""
                        && !dniReg.test(field[1])
                      ) errors [field[0]] = true
                    }
                    // if(field[0] === "addressPostalCode") {
                    //   if (
                    //     typeof field[1] === "string"
                    //     && field[1] !== ""
                    //     && !postalReg.test(field[1])
                    //   ) errors [field[0]] = true
                    // }
                    if (field[0] === "password") {
                      password = field[1]
                    }
                    if (field[0] === "repassword") {
                      if (field[1] !== "" && field[1] !== password) errors[field[0]] = true
                    }
                  })
                  setValidationErrors(errors)
                  return errors
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setLoading(true)
                  delete values.repassword
                  values.birthDate = moment(values.birthDate).format("YYYY-MM-DD")
                  const response = await sessionService.updateUser(values)
                  setSubmitting(false)
                  if (response) {
                    session.name = values.name.toUpperCase()
                    dispatch({ type: "login", payload: session })
                    setLoading(false)
                    resetForm()
                    refresh()
                  }
                }}
              >
                {({ setFieldValue }) => {
                return (
                  <Form className="grid grid-cols-4 md:grid-cols-6 md:gap-6 lg:gap-5">
                    <label className="col-span-4 mt-6 uppercase md:my-0 mb-10px md:col-span-6 font-secondary text-smaller">
                      {form_titleInfo}
                    </label>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="name"
                        placeholder={placeholder_name}
                        required={true}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="surname"
                        placeholder={placeholder_surname}
                        required={true}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="email"
                        name="email"
                        placeholder={placeholder_email}
                        error={validationErrors && validationErrors.email}
                        literalError={literalErrorEmail}
                        label={true}
                        required={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="tel"
                        name="phoneNumber"
                        placeholder={placeholder_phone}
                        error={validationErrors && validationErrors.phoneNumber}
                        literalError={literalErrorPhone}
                        required={true}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="idNumber"
                        placeholder={placeholder_dni}
                        error={validationErrors && validationErrors.idNumber}
                        literalError={literalErrorId}
                        required={true}
                        label={true}
                      />
                    </div>
                    {/* <label className="col-span-4 mt-6 uppercase md:my-0 mb-10px md:col-span-6 font-secondary text-smaller">
                      {form_titleAddress}
                    </label>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="addressCountry"
                        placeholder={placeholder_addressCountry}
                        required={false}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="addressRegion"
                        placeholder={placeholder_addressRegion}
                        required={false}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="addressCity"
                        placeholder={placeholder_addressCity}
                        // error={validationErrors && validationErrors.addressCity}
                        required={false}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="addressStreet"
                        placeholder={placeholder_addressStreet}
                        required={false}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="addressNumber"
                        placeholder={placeholder_addressNumber}
                        required={false}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="text"
                        name="addressPostalCode"
                        placeholder={placeholder_addressPostalCode}
                        error={validationErrors && validationErrors.addressPostalCode}
                        literalError={literalErrorPostalCode}
                        required={false}
                        label={true}
                      />
                    </div> */}
                    <label className="col-span-4 mt-6 uppercase md:my-0 mb-10px md:col-span-6 font-secondary text-smaller">
                      {form_titlePassword}
                    </label>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="password"
                        name="password"
                        placeholder={placeholder_password}
                        error={validationErrors && validationErrors.password}
                        required={false}
                        label={true}
                      />
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <FormField
                        type="password"
                        name="repassword"
                        placeholder={placeholder_repassword}
                        error={validationErrors && validationErrors.repassword}
                        literalError={literalErrorPasswordRepeat}
                        required={false}
                        label={true}
                      />
                    </div>
                    <label className="col-span-4 mt-6 uppercase md:my-0 mb-10px md:col-span-6 font-secondary text-smaller">
                      {placeholder_birthdate}
                    </label>
                    <div className="col-span-4 mb-3 text-center no-underline uppercase md:col-span-3 font-secondary text-small">
                      <Calendar
                        maxDetail={"month"}
                        maxDate={today}
                        defaultView={"month"}
                        minDetail={"decade"}
                        locale={"es"}
                        defaultValue={userInfo.birthDate !== '1900-01-01' ? moment(userInfo.birthDate, "YYYY-MM-DD").toDate() : today}
                        formatShortWeekday={(locale, date) => formatDate(locale, date)}
                        onClickDay={(value) => setFieldValue("birthDate", moment(value).format("YYYY-MM-DD"))}
                        prevLabel={<Svg name="arrow-left" width={16} height={16} color="gray" />}
                        nextLabel={<Svg name="arrow-right" width={16} height={16} color="gray" />}
                      />
                    </div>
                    <div className="col-span-4 mt-8 md:col-start-1 md:col-span-2 md:mt-8 lg:mt-6">
                      <Button submitButton={true} cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} loading={loading} />
                    </div>
                  </Form>
                )}}
              </Formik>
            </Appear>
          }
        </div>
      </ContainerGrid>
    </Appear>
  )
}

export default EditprofileForm

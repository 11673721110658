// @flow

import React, { useState } from 'react'
import Svg from './Svg'

type Props = {
  icon: string,
  href: string,
}

const SocialLink = ({ icon, href }: Props) => {
  const [mouseOver, setMouseOver] = useState(false)

  return (
    <a
    href={href}
    onMouseOver={() => setMouseOver(true)}
    onMouseLeave={() => setMouseOver(false)}
    target="_blank"
    rel="noopener noreferrer"
    >
      <div className="relative flex items-center justify-center">
        {mouseOver ? <Svg name="oval-fill"/> : <Svg name="oval-line"/>}
        <div className="absolute">
          <Svg name={icon} color={mouseOver ? "primary-brown" : "gray"} />
        </div>
      </div>
    </a>
  )
}

export default SocialLink
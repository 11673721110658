// @flow

import React from "react"
import CalendarCell from "./CalendarCell"

type Tag = {
  id: string,
  title: string,
  color?: string,
}

type RoomType = {
  id: string,
  title: string,
}

type Activity = {
  activityId: string,
  id: string,
  day: string,
  dateFrom: string,
  dateTo: string,
  hourFrom: string,
  hourTo: string,
  room: RoomType,
  name: string,
  teacher: string,
  tags: Array<Tag>,
  description: string,
  ctaLabel: string,
  ctaHref: string,
  needsReservation: string,
  exceptionDates: Array<string>
}
type Props = {
  index: number,
  days: Array<string>,
  hour: Object,
  rooms: Array<Object>,
  items: Array<Activity>,
  disableHover?: boolean,
  hoverEffectCallback?: Function,
  hoveredItem?: string,
  showTeachers?: boolean,
  start?: string,
  literals: Object,
  userInfo: Object,
}

const ActivitySubGrid = ({
  index,
  days,
  hour,
  rooms,
  items,
  disableHover,
  hoverEffectCallback,
  hoveredItem,
  showTeachers,
  start,
  literals,
  userInfo,
}: Props) => {
  // Order rooms based on rooms array
  const makeItemsRoom = () => {
    // Get array without duplicated rooms
    const allRooms = []
    items.map(activity => {
      const { room } = activity
      const item = allRooms.find(
        existingRoom => {
          if (typeof room === "object" && typeof existingRoom === "object") {
            return Number(existingRoom.id) === Number(room.id)
          }
        }
      )
      if (!item) {
        typeof room === "object" && allRooms.push(room.id)
      }
    })

    const returnedRooms = []
    rooms.forEach(room => {
      if (allRooms.includes(room.id)) {
        const obj = {
          id: room.id,
          title: room.title,
        }
        returnedRooms.push(obj)
      }
    })

    return returnedRooms
  }

  const itemsRoom = makeItemsRoom()

  // Populate table with empty cells
  const autoPopulate = () => {
    let emptyCellArray = []


    if (itemsRoom.length === 0) {
      days.map((day, index) => {
        emptyCellArray.push({
          col: (index + 1) * 3,
          row: 1,
        })
      })
    }
    for (let dayIndex = 0; dayIndex < days.length; dayIndex++) {
      for (let roomIndex = 0; roomIndex < itemsRoom.length; roomIndex++) {
        emptyCellArray.push({
          col: (dayIndex + 1) * 3,
          row: roomIndex + 1,
        })
      }
    }
    return emptyCellArray
  }

  const roomsNumber = () => {
    if (itemsRoom.length === 0) {
      return 1
    } else {
      return itemsRoom.length
    }
  }

  return (
    <div
      data-name="subtable"
      className="grid col-start-1 row-span-1 gap-0 col-span-23 col-end-24 grid-cols-23"
      style={{ gridRowStart: `${index + 2}` }}
    >
      <div
        data-name="subtable-hour-cell"
        className="relative col-span-2 col-start-1 -mt-px border-b border-l border-r print:border-t-0 border-gray font-primary text-normal"
        style={{ gridRow: `1 / span ${roomsNumber()}` }}
      >
        <div className="sticky px-4 py-4 border-t print:static print:border-none top-75px border-gray">
        <span className="block mt-px print:mt-0">{hour.string}</span>
        </div>
      </div>
      {autoPopulate().map((emptyCell, index) => {
        return (
          <div
            key={index}
            className="col-span-3 border-b border-r border-gray"
            style={{
              gridRowStart: `${emptyCell.row}`,
              gridColumnStart: `${emptyCell.col}`,
            }}
          >
            {items.map(activity => {
              const roomIndex = itemsRoom.findIndex(
                element => {
                  if (typeof element === "object" && typeof activity.room === "object") {
                    const { id } = activity.room
                    return Number(element.id) === Number(id)
                  }
                }
              )
              if (
                parseInt(activity.day) * 3 === emptyCell.col &&
                emptyCell.row === roomIndex + 1
              ) {
                return (
                  <React.Fragment key={activity.id}>
                    <CalendarCell
                      id={activity.id}
                      activityId={activity.activityId}
                      day={activity.day}
                      dateFrom={activity.dateFrom}
                      dateTo={activity.dateTo}
                      hourFrom={activity.hourFrom}
                      hourTo={activity.hourTo}
                      room={activity.room}
                      name={activity.name}
                      exceptionDates={activity.exceptionDates}
                      teacher={activity.teacher}
                      tags={activity.tags}
                      description={activity.description}
                      cta={{ href: activity.ctaHref, label: activity.ctaLabel }}
                      disableHover={disableHover}
                      rooms={itemsRoom}
                      hoverEffectCallback={hoverEffectCallback}
                      hoveredItem={hoveredItem}
                      showTeachers={showTeachers}
                      start={start}
                      literals={literals}
                      userInfo={userInfo}
                      needsReservation={activity.needsReservation}
                    />
                  </React.Fragment>
                )
              }
            })}
          </div>
        )
      })}
    </div>
  )
}

export default ActivitySubGrid

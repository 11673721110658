// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import type { Image } from 'types/'
import { Formik, Form } from 'formik'
import FormField from './FormField'
import FormCheckbox from './FormCheckbox'
import Button from './Button'
import ImageLazy from './ImageLazy'
import sessionService from '../services/session.service'
import HTMLText from './HTMLText'

type Props = {
  title: string,
  text: string,
  inputText: string,
  termsText: string,
  cta: Object,
  image: Image,
  requestSentMessage: string,
  literalErrorEmail: string,
  literalError: string,
}

const Newsletter = ({
  title,
  text,
  inputText,
  termsText,
  cta,
  image,
  requestSentMessage,
  literalErrorEmail,
  literalError,
}: Props) => {
  const [validationErrors, setValidationErrors] = useState()
  const [loading, setLoading] = useState(false)
  const [opacity, setOpacity] = useState(1)
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const opacityTimer = setTimeout(() => {
      setOpacity(0)
    }, 4800)

    const resetTimer = setTimeout(() => {
      setSent(false)
      setOpacity(1)
    }, 5000)

    return () => {
      clearTimeout(opacityTimer)
      clearTimeout(resetTimer)
    }
  }, [sent])

  return (
    <Appear>
      <div
        data-name="Wrapper"
        className="grid grid-cols-4 gap-4 md:grid-cols-10 md:gap-6 lg:gap-8 text-gray print:hidden"
      >
        <div
          data-name="Left col content"
          className="col-span-4 md:col-start-1 md:col-span-5"
        >
          <div
            data-name="Left col inner grid"
            className="grid grid-cols-4 gap-4 text-center md:gap-6 lg:gap-8 md:grid-cols-5 md:text-left"
          >
            <h2 className="col-span-4 mb-6 leading-tight md:mb-0 md:leading-none mt-14 md:col-span-4 lg:col-span-3 font-primary text-big1 md:text-big4">
              {title}
            </h2>
            <p className="hidden col-span-4 md:block text-gray font-secondary text-small md:text-normal md:col-span-4 lg:col-span-3">
              {text}
            </p>
            <Formik
              enableReinitialize={true}
              initialValues={{
                email: '',
                terms: false
              }}
              validate={values => {
                const errors = {}
                const mailReg = new RegExp(
                  /^([a-z0-9_.+-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/gm
                )
                Object.entries(values).forEach(field => {
                  if (field[0] === 'email') {
                    if (
                      typeof field[1] === "string"
                      && field[1] !== '' 
                      && !mailReg.test(field[1])
                    )
                      errors[field[0]] = true
                  }
                })
                setValidationErrors(errors)
                return errors
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setLoading(true)
                delete values.terms
                const response = await sessionService.newsletter(values)
                setLoading(false)
                if (response) {
                  setSent(true)
                  resetForm({})
                  return
                }
                setError(true)
                setSubmitting(false)
              }}
            >
              {({ values, setFieldValue }) => {
                return (
                  <Form className="grid grid-cols-4 col-span-4 text-left md:mt-8 lg:mt-6 md:col-span-4 md:gap-6 lg:gap-8">
                    <div className="col-span-4">
                      <FormField
                        type="email"
                        name="email"
                        placeholder={inputText}
                        error={validationErrors && validationErrors.email}
                        literalError={literalErrorEmail}
                        required={true}
                      />
                      <div className="flex items-center justify-start mt-5 text-gray text-tiny">
                        <FormCheckbox
                          name="terms"
                          value={values.terms}
                          required={true}
                          isChecked={values.terms === true}
                          handleCheckbox={value =>
                            setFieldValue('terms', value)
                          }
                        />
                        <HTMLText text={termsText} className="ml-10px font-secondary" />
                      </div>
                    </div>
                    <div className="flex flex-col items-center col-span-4 mt-8 md:flex-row md:col-start-1 md:col-span-4 md:mt-8 lg:mt-6">
                      <div className="w-full md:w-1/2">
                        <Button
                          submitButton={true}
                          cta={cta}
                          darkMode={true}
                          loading={loading}
                        />
                      </div>
                      {sent && (
                        <Appear>
                          <div
                            className="my-4 transition-opacity duration-150 ease-linear text-smaller md:ml-4"
                            style={{ opacity: opacity }}
                          >
                            <span className="text-gray font-secondary">
                              {requestSentMessage}
                            </span>
                          </div>
                        </Appear>
                      )}
                      {error && (
                        <Appear>
                          <div
                            className="my-4 transition-opacity duration-150 ease-linear text-smaller md:ml-4"
                            style={{ opacity: opacity }}
                          >
                            <span className="text-gray font-secondary">
                              {literalError}
                            </span>
                          </div>
                        </Appear>
                      )}
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
        <div
          data-name="Right col content"
          className="col-span-4 row-start-2 md:col-span-5 md:row-start-1 md:col-start-6"
        >
          {image && <ImageLazy className="hidden w-full md:block" image={image} alt="" />}
        </div>
      </div>
    </Appear>
  )
}

export default Newsletter

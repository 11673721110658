// @flow

import React from 'react'
import Appear from './Appear'
import type { Image } from 'types/'
import ContainerGrid from './ContainerGrid'
import Button from './Button'
import useIsMobile from '../hooks/useIsMobile'
import ImageLazy from './ImageLazy'
import HTMLText from './HTMLText'

type Props = {
  supertitle: string,
  title: string,
  text: string,
  image: Image,
  ctaLabel: string,
  ctaHref: string,
  margins?: string,
}

const BookTour = ({ supertitle, title, text, image, ctaLabel, ctaHref, margins }: Props) => {
  const isMobile = useIsMobile()
  return (
    <Appear>
      <div className={`${margins ? margins : "my-32 md:my-72"}`}>
        <ContainerGrid>
          <div className="grid grid-cols-4 gap-4 md:grid-cols-10 md:gap-6 lg:gap-8">
            <div className="col-span-4 col-start-1 md:row-start-1">
              <div className="mb-4 uppercase text-smaller md:text-small text-gray font-secondary">{supertitle}</div>
              <div className="mb-10 leading-none md:mb-12 text-big3 md:text-huge1 text-gray font-primary">{title}</div>
              <HTMLText className="text-normal text-gray font-secondary" text={text}/>
              <div className="w-full mt-10 md:mt-16 md:w-1/2">
                <Button cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} internalLink={true} />
              </div>
            </div>
            {!isMobile && image
            && <div className="col-span-5 col-start-6 row-start-1">
              <Appear>
                <ImageLazy className="w-full h-auto" image={image} alt=""/>
              </Appear>
            </div>
            }
          </div>
        </ContainerGrid>
      </div>
    </Appear>
  )
}

export default BookTour

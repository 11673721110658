import React, { useState } from 'react'

const Reload = () => {
  const [hidden, setHidden] = useState(false)

  return (
    <div onClick={() => setHidden(true)} style={{ display: hidden ? 'none' : 'block', cursor: 'pointer', zIndex: 99, fontSize: '13px', fontFamily: 'Courier', position: 'fixed', bottom: 0, left: 0, right: 0, textAlign: 'center', padding: '8px 0', color: 'white', background: 'rgba(0, 0, 0, 0.7)'}}>
      <strong>Estás en modo preview.</strong> Recuerda hacer <strong>deploy</strong> para aplicar los cambios.
    </div>
  )
}

export default Reload
// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import { Formik, Form } from 'formik'
import ContainerGrid from './ContainerGrid'
import FormField from './FormField'
import FormTextArea from './FormTextArea'
import FormCheckbox from './FormCheckbox'
import Button from './Button'
import sessionService from '../services/session.service'
import HTMLText from './HTMLText'
import FormDropFile from "./FormDropFile"

type Props = {
  title: string,
  text: string,
  placeholders: Record<string, string>,
  ctaLabel: string,
  ctaText: string,
  literalSent: string,
  literalErrorEmail: string,
  literalErrorRequired: string,
  literalTitleInformant: string,
  literalTitleComunication: string,
  literalTitleComunicationFacts: string,
  literalTitleInvolvedPerson: string,
  literalTitleDocuments: string,
  literalDropFiles: string,
  literalSelectFiles: string,
  literalSelectedFiles: string,
  literalFileType: string,
  termsText: string,
}

const ComplaintForm = ({
  title,
  placeholders,
  ctaLabel,
  literalSent,
  literalErrorEmail,
  literalErrorRequired,
  literalTitleInformant,
  literalTitleComunication,
  literalTitleComunicationFacts,
  literalTitleInvolvedPerson,
  literalTitleDocuments,
  literalDropFiles,
  literalSelectFiles,
  literalSelectedFiles,
  literalFileType,
  termsText
}: Props) => {
  const [opacity, setOpacity] = useState(1)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const [formError, setFormError] = useState(undefined)

  useEffect(() => {
    const opacityTimer = setTimeout(() => {
      setOpacity(0)
      }, 4800)

    const resetTimer = setTimeout(() => {
        setSent(false)
        setOpacity(1)
      }, 5000)
    
    return () => { 
      clearTimeout(opacityTimer)
      clearTimeout(resetTimer)
    }
  }, [sent])

  return (
      <ContainerGrid>
          <Appear className="col-span-4 md:col-span-12 mb-60 md:mb-100">
            <Formik
              enableReinitialize={true}
              initialValues={{
                informantName: "", 
                informantAddress: "", 
                informantEmail: "",
                criminalOffense: "",
                administrativeViolation: "",
                rightInfringement: "",
                internalInfo: "",
                securityBreach: "",
                communication: "",
                description: "",
                involvedPersonName: "", 
                involvedPersonAddress: "", 
                involvedPersonEmail: "",
                documents: undefined,
                terms: false,
              }}
              validate={values => {
                const errors = {}
                const mailReg = new RegExp(/^([a-z0-9_.+-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/gm)
                Object.entries(values).forEach(field => {
                  if(field[0] === "email") {
                    if (
                      typeof field[1] === "string"
                      && field[1] !== ""
                      && !mailReg.test(field[1])
                      ) errors[field[0]] = true
                    }
                    if (field[0] === "terms" && field[1] === false) {
                      errors[field[0]] = true
                    }
                  })
                return errors
              }}
              onSubmit={async (values, { resetForm }) => {
                setLoading(true)
                try {
                  const response = await sessionService.complaint(values)
                  if (response.valid) {
                    setFormError(undefined)
                    setSent(true)
                    setLoading(false)
                    resetForm({})
                  } else {
                    if (response.response === "The file type is not allowed. Please try again with a different file.") {
                      setFormError("El tipo de archivo no está permitido. Por favor, vuelve a intentarlo con uno diferente (png, jpg, jpeg, pdf, zip).")
                    } else {
                      setFormError(response.response)
                    }
                  }
                } catch(err) {
                  setFormError('El tamaño de archivo es demasiado grande. Por favor, vuelve a intentarlo con uno más pequeño (máximo 10MB).')
                }
                setLoading(false)
              }}
              validateOnBlur={false}
            >
              {({values, setFieldValue, errors, isSubmitting}) => {
                return (
                  <div id="complaint-form">
                    <Form>
                      <h2 className="uppercase font-bold text-bigger2">{title}</h2>
                      <h3 className="uppercase font-bold mt-5 mb-3 text-normal" >{literalTitleInformant}</h3>
                      <div className="md:grid md:grid-cols-2 gap-x-10px">
                        <div className="md:col-span-2">
                          <FormField
                            type="text"
                            name="informantName"
                            placeholder={placeholders.name}
                            error={errors && errors.informantName}
                            literalError={literalErrorRequired}
                            required={true}
                          />
                        </div>
                        <FormField
                          type="text"
                          name="informantAddress"
                          placeholder={placeholders.address}
                          error={errors && errors.informantAddress}
                          literalError={literalErrorRequired}
                          required={true}
                        />
                        <FormField
                          type="email"
                          name="informantEmail"
                          placeholder={placeholders.email}
                          error={errors && errors.informantEmail}
                          literalError={literalErrorEmail}
                          required={true}
                        />
                      </div>
                      <h3 className="uppercase font-bold mt-5 mb-3 text-normal" >{literalTitleComunication}</h3>
                      <div className="md:grid md:grid-cols-2 gap-x-10px">
                        <FormField
                          type="text"
                          name="criminalOffense"
                          placeholder={placeholders.criminalOffense}
                          error={errors && errors.criminalOffense}
                          literalError={literalErrorRequired}
                          required={true}
                        />
                        <FormField
                          type="text"
                          name="administrativeViolation"
                          placeholder={placeholders.administrativeViolation}
                          error={errors && errors.administrativeViolation}
                          literalError={literalErrorRequired}
                          required={true}
                        />
                        <FormField
                          type="text"
                          name="rightInfringement"
                          placeholder={placeholders.rightInfringement}
                          error={errors && errors.rightInfringement}
                          literalError={literalErrorRequired}
                          required={true}
                        />
                        <FormField
                          type="text"
                          name="internalInfo"
                          placeholder={placeholders.internalInfo}
                          error={errors && errors.internalInfo}
                          literalError={literalErrorRequired}
                          required={true}
                        />
                        <FormField
                          type="text"
                          name="securityBreach"
                          placeholder={placeholders.securityBreach}
                          error={errors && errors.securityBreach}
                          literalError={literalErrorRequired}
                          required={true}
                        />
                        <FormField
                          type="text"
                          name="communication"
                          placeholder={placeholders.communication}
                          error={errors && errors.communication}
                          literalError={literalErrorRequired}
                          required={true}
                        />
                      </div>
                      <h3 className="uppercase font-bold mt-5 mb-3 text-normal" >{literalTitleComunicationFacts}</h3>
                      <div>
                        <FormTextArea
                          name="description"
                          placeholder={placeholders.description}
                          error={errors && errors.description}
                          literalError={literalErrorRequired}
                          required={true}
                        />
                      </div>
                      <h3 className="uppercase font-bold mt-5 mb-3 text-normal" >{literalTitleInvolvedPerson}</h3>
                      <div className="md:grid md:grid-cols-2 gap-x-10px">
                        <div className="md:col-span-2">
                          <FormField
                            type="text"
                            name="involvedPersonName"
                            placeholder={placeholders.name}
                            error={errors && errors.involvedPersonName}
                            literalError={literalErrorRequired}
                            required={true}
                          />
                        </div>
                        <FormField
                          type="text"
                          name="involvedPersonAddress"
                          placeholder={placeholders.address}
                          error={errors && errors.involvedPersonAddress}
                          literalError={literalErrorRequired}
                          required={true}
                        />
                        <FormField
                          type="email"
                          name="involvedPersonEmail"
                          placeholder={placeholders.email}
                          error={errors && errors.involvedPersonEmail}
                          literalError={literalErrorEmail}
                          required={true}
                        />
                      </div>
                      <div className="mt-5 mb-3">
                        <h3 className="uppercase font-bold text-normal">{literalTitleDocuments}</h3>
                        <span className="text-smaller text-gray">({literalFileType})</span>
                      </div>
                      <div>
                      <FormDropFile
                        labelDrop={literalDropFiles}
                        labelSelect={literalSelectFiles}
                        labelSelected={literalSelectedFiles}
                        onValueChange={(e) => setFieldValue('documents', e)}
                        value={values.documents}
                      />
                      </div>
                      <div className="flex items-center py-6">
                        <FormCheckbox 
                          name="terms"
                          value={values.terms}
                          required={true}
                          isChecked={values.terms === true}
                          handleCheckbox={(value) => setFieldValue("terms", value)}
                          error={errors && errors.terms}
                          literalError={literalErrorRequired}
                        />
                          <HTMLText text={placeholders.terms} className="font-secondary mt-1" />
                      </div>
                        <HTMLText text={termsText} className="font-secondary text-small pb-12 pl-6 text-gray" />
                      <div>
                        <div>
                          <Button type="submit" submitButton={true} cta={{ label: ctaLabel }} darkMode={true} loading={loading}  disabled={errors.terms || !values.terms || isSubmitting}/>
                        </div>
                        {sent && <Appear>
                          <div
                            className="my-4 transition-opacity duration-150 ease-linear text-small text-center"
                            style={{ opacity: opacity }}
                          >
                            <span className="text-gray font-secondary">{literalSent}</span>
                          </div>
                        </Appear>}
                        {formError && <Appear>
                          <div
                            className="my-4 transition-opacity duration-150 ease-linear text-small text-center"
                          >
                            <span className="text-red-500 font-secondary">{formError}</span>
                          </div>
                        </Appear>}
                      </div>
                    </Form>
                  </div>
                )
              }}
            </Formik>
          </Appear>
      </ContainerGrid>
  )
}

export default ComplaintForm

// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import type { Image } from 'types/'
import ContainerGrid from './ContainerGrid'
import Button from './Button'
import ButtonSecondary from './ButtonSecondary'
import DropCapText from './DropCapText'
import useIsMobile from '../hooks/useIsMobile'
import ImageLazy from './ImageLazy'
import HTMLText from './HTMLText'

type Props = {
  reverse?: number,
  title: string,
  text: string,
  list?: Array<string>,
  image: Image,
  verticalImage?: number,
  ctaLabel?: string,
  ctaHref?: string,
  pageName?: string
}

const TitleCapTextImgList = ({
  reverse,
  title,
  text,
  list,
  image,
  verticalImage,
  ctaLabel,
  ctaHref,
  pageName }: Props) => {
  const [useDropCap, setUseDropCap] = useState()
  const [useAltColumns, setUseAltColumns] = useState()
  const hasList = (typeof list !== 'undefined' && Array.isArray(list) && list.length > 0 && list[0] !== "")

  const isMobile = useIsMobile()
  let firstHalfList = []
  let secondHalfList = []
  let shortList = []
  
  if (hasList && list) {
    const halfList = Math.ceil(list.length / 2)
    firstHalfList = list.slice(0, halfList)
    secondHalfList = list.slice(halfList)
    shortList = list.slice(0, 7)
  }
    
  function shouldUseDropCap() {
    if (pageName === "home") {
      if (typeof list !== "undefined" && list.length > 0) {
        setUseDropCap(true)
      } else {
        setUseDropCap(false)
      }
    } else if (pageName === "about") {
      setUseDropCap(isMobile)
    }
  }

  function shouldUseAltColumns() {
    if (verticalImage === 1 || (image.dir === "" && (list && list.length !== 0))) {
      setUseAltColumns(true)
    } else {
      setUseAltColumns(false)
    }
  }

  function handleColumns(column) {
    if (column === "primary") {
      if (reverse === 1) {
        if (useAltColumns === true) {
          // Alt columns reverse
          return ""
        } else {
          // Normal columns reverse
          return "col-span-4 md:col-start-7 md:row-start-1"
        }
      } else {
        if (useAltColumns === true) {
          // Alt columns no reverse
          return "col-span-4  md:col-span-5"
        } else {
          // Normal columns columns no reverse
          return "col-span-4"
        }
      }
      
    } else if (column === "secondary") {
      if (reverse === 1) {
        if (useAltColumns === true) {
          // Alt columns reverse
          return ""
        } else {
          // Normal columns reverse
          return "col-span-4 md:col-span-5 md:row-start-1 md:col-start-1"
        }
      } else {
        if (useAltColumns === true) {
          // Alt columns no reverse
          return "col-span-4 md:col-start-7"
        } else {
          // Normal columns columns no reverse
          return "col-span-4 md:col-span-5 md:col-start-6"
        }
      }
    }
  }

  const handleDarkMode = () => {
    if (reverse === 1 && pageName === "about") {
      return true
    } else return false
  }
  
  useEffect(() => {
    shouldUseDropCap()
    shouldUseAltColumns()
  }, [isMobile])

  return (
    <Appear>
      <div className="mt-32 mb-12 md:my-56">
        <ContainerGrid>
          {(isMobile === true || image.dir === "") && (
            <div className="grid grid-cols-4 gap-4 mb-10 leading-none md:grid-cols-10 md:gap-6 md:mb-12 lg:mb-16 lg:gap-8 text-big3 md:text-big4 text-gray font-primary">
              <h3 className="col-span-4">{title}</h3>
            </div>
          )}
          <div className="grid grid-cols-4 gap-4 md:grid-cols-10 md:gap-6 lg:gap-8">
            <div
              data-name="title-text-column"
              className={handleColumns("primary")}
            >
              {(image.dir !== "") && (
                <h3 className="hidden mb-16 leading-none md:block text-big4 text-gray font-primary">{title}</h3>
              )}
              {useDropCap ? <>
                  <DropCapText text={text}/>
                  {hasList && pageName === "home" &&
                    <div className="grid grid-cols-4 gap-4 mt-4 md:hidden">
                      <div className="col-span-3 col-start-2">
                        <ul className="ml-4 list-disc list-outside">
                          {list && list.map((item, index) => <li
                          key={index}
                          className={`${index + 1 !== list.length ? "pb-2" : ""} text-gray font-secondary text-small`}
                          >{item}</li>)}
                        </ul>
                      </div>
                    </div>
                  }
                  {hasList && pageName === "about" &&
                    <div className="grid grid-cols-4 gap-4 mt-4 md:hidden">
                      <div className="col-span-3 col-start-2">
                        <ul className="mb-10 ml-4 list-disc list-outside md:mb-12">
                          {shortList.map((item, index) => <li
                          key={index}
                          className={`${index + 1 !== shortList.length ? "pb-2" : ""} text-gray font-secondary text-small`}
                          >{item}</li>)}
                        </ul>
                        {ctaHref && ctaLabel && (
                          <ButtonSecondary cta={{ href: ctaHref, label: ctaLabel }}/>
                        )}
                      </div>
                    </div>
                  }
                </>
              :
                <div className="text-normal text-gray font-secondary">
                  <HTMLText className="md:mb-12" text={text}/>
                  {hasList &&
                    <div className="items-start justify-start hidden mt-10 md:flex md:mt-0 text-normal text-gray font-secondary">
                      <ul className="w-1/2 h-auto ml-4 list-disc list-outside">
                        {firstHalfList.map((item, index) => <li
                        key={index}
                        className="pb-2 text-gray font-secondary text-normal"
                        >{item}</li>)}
                      </ul>
                      <ul className="w-1/2 h-auto ml-12 list-disc list-outside">
                        {secondHalfList.map((item, index) => <li
                        key={index}
                        className="pb-2 text-gray font-secondary text-normal"
                        >{item}</li>)}
                      </ul>
                    </div>                      
                  }
                </div>}
              {ctaLabel && pageName === "home" && <div className={`mt-10 md:mb-0 md:w-3/4 md:mt-12 ${hasList && pageName === "home" ? 'md:ml-20%' : ''}`}>
                  <Button cta={{ href: ctaHref, label: ctaLabel }} darkMode={handleDarkMode()} internalLink={true}/>
                </div>}
            </div>
            <div
              data-name="img-list-column"
              className={handleColumns("secondary")}
            >
              {image.dir !== "" && <Appear>
                  <ImageLazy className="w-full h-auto mt-6 md:mt-0" image={image} alt="" />
                </Appear>}
              {image.dir === "" && <Appear>
                  {hasList &&
                    <div className="items-start justify-start hidden mt-10 md:flex md:mt-0 text-normal text-gray font-secondary">
                      <ul className="w-1/2 h-auto ml-4 list-disc list-outside">
                        {firstHalfList.map((item, index) => <li
                        key={index}
                        className="pb-2 text-gray font-secondary text-normal"
                        >{item}</li>)}
                      </ul>
                      <ul className="w-1/2 h-auto ml-12 list-disc list-outside">
                        {secondHalfList.map((item, index) => <li
                        key={index}
                        className="pb-2 text-gray font-secondary text-normal"
                        >{item}</li>)}
                      </ul>
                    </div>
                  }
                </Appear>}
            </div>
          </div>
        </ContainerGrid>
      </div>
    </Appear>
  )
}

export default TitleCapTextImgList

// @flow

import React, { useState, useRef } from 'react'
import { navigate } from 'gatsby'
import ButtonSecondary from './ButtonSecondary'
import Button from './Button'
import ActivityForm from './ActivityForm'
import { useGlobalState } from 'store/'
import moment from 'moment'

type Props = {
  cta: Object,
  day: string,
  hourFrom: string,
  hourTo: string,
  room: Object,
  color: string,
  name: string,
  description: string,
  hovered: boolean,
  start?: string,
  setShouldHideOnMouseLeave: Function,
  id: string,
  literals: Object,
  handleCalendarHover: Function,
  activityId: string,
  userInfo: Object,
  isBookable: boolean,
  userIsMember: boolean,
  capacityControl: boolean,
}

const CalendarHover = ({
  cta,
  day,
  hourFrom,
  hourTo,
  room,
  color,
  name,
  description,
  hovered,
  start,
  setShouldHideOnMouseLeave,
  literals,
  handleCalendarHover,
  activityId,
  userInfo,
  isBookable,
  userIsMember,
  capacityControl,
}: Props) => {
  const envCityMadrid = process.env.GATSBY_city === 'Madrid'
  const container = useRef({})
  // const [session, dispatch] = useGlobalState()
  const [open, setOpen] = useState(false)
  // Handle selected day literal
  const getDay = () => {
    const daysInMonth = moment(start).daysInMonth()
    const number = moment(start).date() + (Number(day) - 1)
    const result = number > daysInMonth ? number - daysInMonth : number
    return result
  }
  const handleClick = () => {
    window.tileOpen = true
    setShouldHideOnMouseLeave(false)
    setOpen(true)
  }

  return (
    <div
      ref={container}
      className={`z-40 absolute cursor-default mt-5 bg-white border text-gray opacity-0 border-gray transition-opacity duration-150 ease-linear ${hovered === true ? 'opacity-100' : ''} `}
      style={{
        minWidth: '348px',
        maxWidth: '348px',
        top: '82%',
        left: day !== '7' ? -1 : undefined,
        right: day === '7' ? -1 : undefined,
      }}
    >
      <div
        style={{ backgroundColor: color }}
        className={`w-10px absolute top-0 left-0 h-full border-r border-gray`}
      />
      <div className="p-5 pb-6">
        <div className="flex flex-row items-center justify-between">
          <div className="font-secondary text-smaller">
            {hourFrom} - {hourTo} h | {room.title}
          </div>
          <div className="font-secondary text-smaller">
            {moment(`${moment(start).year()}-${moment(start).month() + 1}-${getDay()}`).format('DD/MM/YYYY')}
          </div>
        </div>
        <h6 className="my-4 font-primary text-bigger2">{name}</h6>
        <p className="mr-4 leading-snug font-secondary text-small">
          {description}
        </p>
        <div className="pt-6 cursor-pointer">
          {!open ? (
            <>
              {capacityControl && !envCityMadrid &&
              // (user login hidden)
              // {capacityControl && userIsMember && !envCityMadrid &&
              // <> { session ? (
                <div className="mb-4">
                  {isBookable ? (
                    <Button
                      darkMode={true}
                      actionButton={true}
                      actionButtonOnClick={handleClick}
                      cta={{ label: literals.book_activity }}
                    />
                  ) : (
                    <div className="mr-6 leading-snug font-secondary text-small">{literals.literalClosedBooking}</div>
                  )}
                </div>
                // ) : (
                //   <div className="mb-3">
                //   {isBookable ? (
                //     <>
                //       <div className="mb-3 mr-6 leading-snug font-secondary text-small">{literals.literalLoginToBook}</div>
                //       <Button
                //         darkMode={true}
                //         actionButton={true}
                //         actionButtonOnClick={() => navigate("/es/iniciar-sesion/")}
                //         cta={{ label: literals.literalLogin }}
                //       />
                //     </>
                //   ) : (
                //     <div className="mr-6 leading-snug font-secondary text-small">{literals.literalClosedBooking}</div>
                //   )}
                //   </div>
                // )} </>
                }
              <div>
                <ButtonSecondary cta={cta} internalLink={true} />
              </div>
            </>
          ) : (
            <div className="flex flex-col w-full">
              <ActivityForm
                literals={literals}
                item={{
                  hourFrom: `${hourFrom}:00`,
                  hourTo: `${hourTo}:00`,
                  room: room.id,
                  name,
                  activity: activityId,
                  date: moment(`${moment(start).year()}-${moment(start).month() + 1}-${getDay()}`).format('YYYY-MM-DD')
                }}
                close={() => {
                  setOpen(false)
                  setShouldHideOnMouseLeave(true)
                  handleCalendarHover(false)
                  window.tileOpen = false
                }}
                userInfo={userInfo}
              />
              <div className="self-end">
                <ButtonSecondary
                  cta={{ label: literals.cancel }}
                  actionButton={true}
                  actionButtonOnClick={() => {
                    setOpen(false)
                    setShouldHideOnMouseLeave(true)
                    handleCalendarHover(false)
                    window.tileOpen = false
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CalendarHover

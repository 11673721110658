// @flow

import React, { useEffect, useState } from 'react'
import Page from 'components/Page'
import Reload from 'components/Reload'
import type { Module, Meta } from 'types/'
import 'intersection-observer'
import { GlobalState } from 'store/'
import { session, sessionReducer } from 'store/session.reducer'
import sessionService from '../services/session.service'
const { getPage } = require('../lib/buildHelper')

// PROVISIONAL DEV
const GATSBY_enableReload = '1'
const GATSBY_reloadParam = 'reload'

const DefaultTemplate = ({
  pageContext
}: {
  pageContext: {}
}) => {

  const [data, setData] = useState(pageContext)
  const [isReloadingEnabled, setIsReloadingEnabled] = useState(false)

  // if ((Number(process.env.GATSBY_enableReload))) {
  if ((Number(GATSBY_enableReload))) {

    useEffect(() => {
      processLoadPage()
    }, [])

    const processLoadPage = async () => {
      if (typeof window !== 'undefined') {
        const urlParams = new URLSearchParams(window.location.search)
        // if (urlParams.has(process.env.GATSBY_reloadParam)) {
        if (urlParams.has(GATSBY_reloadParam)) {
          setIsReloadingEnabled(true)
          await loadPage()
        }
      }
    }

    const loadPage = async () => {
      const { buildInfo, language } = data
      const page = await getPage(buildInfo.endpoint, buildInfo.params, language)
      if (Array.isArray(page)) {
        const updatedData = {...data}
        updatedData.structure = page[0].structure
        setData(updatedData)
      }
    }
  }

  return (
    <GlobalState initialState={JSON.parse(sessionService.getSession())} reducer={sessionReducer}>
      {isReloadingEnabled && <Reload />}
      <Page
        {...data}
      />
    </GlobalState>
  )
}

export default DefaultTemplate

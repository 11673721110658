// @flow

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import localization from 'moment/locale/es'
import ContainerGrid from './ContainerGrid'
import CollapsibleContainer from './CollapsibleContainer'
import Appear from './Appear'
import BookingInfo from './BookingInfo'
import Button from './Button'
import sessionService from '../services/session.service'
import { useGlobalState } from 'store/'
import BookingInfoListSection from './BookingInfoListSection'

type Props = {
  pageName: string,
  aLas: string,
  con: string,
  of: string,
  ctaAddToCalendar?: string,
  ctaModify?: string,
  ctaCancel?: string,
  noBookingFound: string,
  ctaHrefConfirmBooking?: string,
  ctaHrefBookings?: string,
  ctaHrefCancel?: string,
  ctaHref?: string,
  ctaLabel?: string,
  totalPrice?: string,
  ctaConfirmBooking?: string,
  ctaCancelBooking?: string,
  ctaBookingAll?: string,
  supertitle?: string,
  alertCancelBooking: string,
  alertCancelActivity: string,
}

const BookingInfoList = ({ 
  pageName,
  aLas,
  of,
  ctaAddToCalendar,
  ctaCancel,
  noBookingFound,
  ctaHrefConfirmBooking,
  ctaHrefBookings,
  ctaHrefCancel,
  ctaHref,
  ctaLabel,
  totalPrice,
  ctaConfirmBooking,
  ctaCancelBooking,
  ctaBookingAll,
  supertitle,
  alertCancelBooking,
  alertCancelActivity,
 }: Props) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [loaderOpacity, setLoaderOpacity] = useState(1)
  const [itemListOpacity, setItemListOpacity] = useState(0)
  const [session, dispatch] = useGlobalState()
  const [confirmationData, setConfirmationData] = useState(null)
  const [activities, setActivities] = useState([])
  const [pastActivities, setPastActivities] = useState([])
  const [services, setServices] = useState([])
  const [pastServices, setPastServices] = useState([])
  const [activePrimaryDropdown, setActivePrimaryDropdown] = useState(['0', '1'])
  const [activeSecondaryDropdown, setActiveSecondaryDropdown] = useState('undefined')

  moment.updateLocale("es", localization)

  const needsConfirmation = pageName === "bookingConfirmation"
  const isConfirmed = pageName === "bookingConfirmed"
  const isCancelled = pageName === "bookingCancelled"

  async function getUserActivities(clientId, token, language = "es") {
    const response = await sessionService.getUserActivities(clientId, token, language)
    const pastItems = []
    const futureItems = []
    if (response && response.valid && response.data.length > 0) {
      response.data.forEach(item => {
        if (moment(`${item.date} ${item.hourFrom}`).isBefore()) {
          pastItems.push(item)
        } else {
          futureItems.push(item)
        }
      })
      if (pastItems.length > 1) {
        pastItems.sort((a, b) => {
          const aDate = new Date(`${a.date} ${a.hourFrom}`)
          const bDate = new Date(`${b.date} ${b.hourFrom}`)
          const aMonth = aDate.getMonth()
          const bMonth = bDate.getMonth()
          const aDay = aDate.getDate()
          const bDay = bDate.getDate()
          const aHour = (aDate.getHours() * 100) + aDate.getMinutes()
          const bHour = (bDate.getHours() * 100) + bDate.getMinutes()

          if (aMonth !== bMonth) return bMonth - aMonth
          if (aDay !== bDay) return bDay - aDay
          if (aHour !== bHour) return bHour - aHour
          return 0
        })
      }
      setActivities(futureItems)
      setPastActivities(pastItems)
    }
  }

  async function getUserBookings(clientId) {
    const response = await sessionService.getUserBookings(clientId)
    const pastItems = []
    const futureItems = []
    if (response) {
      response.forEach(item => {
        if (moment(item.startDate).isBefore()) {
          pastItems.push(item)
        } else {
          futureItems.push(item)
        }
      })
      if (pastItems.length > 1) {
      pastItems.sort((a, b) => {
        const aDate = new Date(a.startDate)
        const bDate = new Date(b.startDate)
        const aMonth = aDate.getMonth()
        const bMonth = bDate.getMonth()
        const aDay = aDate.getDate()
        const bDay = bDate.getDate()
        const aHour = (aDate.getHours() * 100) + aDate.getMinutes()
        const bHour = (bDate.getHours() * 100) + bDate.getMinutes()

        if (aMonth !== bMonth) return bMonth - aMonth
        if (aDay !== bDay) return bDay - aDay
        if (aHour !== bHour) return bHour - aHour
        return 0
      })
    }
      setServices(futureItems)
      setPastServices(pastItems)
      }
  }

  useEffect(() => {
    if (
      typeof activities !== "undefined"
      && typeof services !== "undefined"
    ) {
      setFirstLoad(false)
      setLoaderOpacity(0)
      setItemListOpacity(1)
    }
  }, [activities, services])

  useEffect(() => {
    if (session && firstLoad) {
      if (pageName === "bookingAll") {
        getUserActivities(session.id, session.token)
        getUserBookings(session.id)
      } else if (pageName === "bookingConfirmation" || pageName === "bookingConfirmed") {
          if (typeof window !== 'undefined') {
          setConfirmationData(window.history.state)
          setItemListOpacity(1)
        }
      }
    }
  }, [session, services, activities])

  useEffect(() => {
    if (!firstLoad) {
      setItemListOpacity(1)
    }
  }, [])

  const formattedCta = (name) => {
    switch (name) {
      case 'calendar':
      return { label: ctaAddToCalendar, href: '#' }
      case 'bookings':
      return { label: ctaBookingAll, href: ctaHrefBookings }
      case 'confirmBooking':
      return { label: ctaConfirmBooking, href: ctaHrefConfirmBooking }
      case 'cancelBooking':
      return { label: ctaCancelBooking, href: ctaHrefBookings }
      case 'cancel':
      return { label: ctaCancel, href: ctaHrefCancel }
      case 'default':
      return null
    }
  }

  const literals = {
      aLas: aLas,
      of: of,
      totalPrice: totalPrice,
  }

  // Booking confirmation & booking confirmed
  if (confirmationData) {
    return (
      <Appear>
        <ContainerGrid>
          <div className="grid grid-cols-4 gap-4 md:grid-cols-10 md:gap-6 lg:gap-8 mb-75vh md:mb-48">
            <div
              className="col-span-4 transition-opacity duration-150 ease-linear md:col-span-10"
              style={{opacity: itemListOpacity}}
            >
              {supertitle && <div className="mb-4 uppercase text-smaller md:text-small text-gray font-secondary">{supertitle}</div>}
                <BookingInfo
                  bookingId={confirmationData.bookingId}
                  price={needsConfirmation ? confirmationData.price : false}
                  title={confirmationData.title}
                  date={confirmationData.startDate}
                  literals={literals}
                  ctaBookings={formattedCta('bookings')}
                  ctaConfirmBooking={needsConfirmation ? formattedCta('confirmBooking') : false}
                  ctaCancelBooking={needsConfirmation ? formattedCta('cancelBooking') : false}
                  ctaCalendar={isConfirmed ? formattedCta('calendar') : false}
                  alertCancelBooking={alertCancelBooking}
                />
            </div>
          </div>
        </ContainerGrid>
      </Appear>
    )
  }

  // Booking cancelled
  if (isCancelled) {
    return (
      <Appear>
      <ContainerGrid>
        <div className="grid grid-cols-4 gap-4 mt-16 md:grid-cols-10 md:gap-6 lg:gap-8 mb-55vh md:mb-48">
          {ctaHref && ctaLabel
            ? <div key="button-wrapper" className="flex items-center justify-center col-span-4 md:col-span-10">
              <div className="w-full md:w-1/3 lg:w-1/5">
                <Button cta={{label: ctaLabel, href: ctaHref}} darkMode={true} internalLink={true} />
              </div>
            </div>
            : null
          }
        </div>
      </ContainerGrid>
    </Appear>
    )
  }

  function changeActivePrimaryDropdown(id) {
    const actives = [...activePrimaryDropdown]
    if (actives.includes(id)) {
      const index = actives.indexOf(id)
      actives.splice(index, 1)
    } else {
      actives.push(id)
    }
    setActivePrimaryDropdown(actives)
    setActiveSecondaryDropdown('undefined')
  }

  function changeActiveSecondaryDropdown(id) {
    if (id !== activeSecondaryDropdown) {
      setActiveSecondaryDropdown(id)
    } else {
      setActiveSecondaryDropdown('undefined')
    }
  }

  // Booking list
  return (
    <Appear>
      <ContainerGrid>
        <div className="grid grid-cols-4 gap-4 mb-24 md:grid-cols-10 md:gap-6 lg:gap-8 md:mb-56">
          {firstLoad ? (
            <div 
                className="flex items-center justify-center col-span-4 text-center transition-opacity duration-150 ease-linear md:col-span-10"
                style={{opacity: loaderOpacity}}
            >
              <div className="loader"></div>
            </div>
          ) : (
            <div className="col-span-10">
              <CollapsibleContainer
                id="0"
                title="Actividades"
                callback={changeActivePrimaryDropdown}
                active={activePrimaryDropdown.includes("0")}
                primary
                first
              >
                <BookingInfoListSection
                  itemListOpacity={itemListOpacity}
                  items={activities}
                  pastItems={pastActivities}
                  noBookingFound={noBookingFound}
                  handleDropdown={changeActiveSecondaryDropdown}
                  activeDropdown={activeSecondaryDropdown}
                  literals={literals}
                  formattedCta={formattedCta}
                  type="activities"
                  alertCancelActivity={alertCancelActivity}
                  alertCancelBooking={alertCancelBooking}
                />
              </CollapsibleContainer>
              <CollapsibleContainer
                id="1"
                title="Servicios"
                callback={changeActivePrimaryDropdown}
                active={activePrimaryDropdown.includes("1")}
                primary
              >
                <BookingInfoListSection
                  itemListOpacity={itemListOpacity}
                  items={services}
                  pastItems={pastServices}
                  noBookingFound={noBookingFound}
                  handleDropdown={changeActiveSecondaryDropdown}
                  activeDropdown={activeSecondaryDropdown}
                  literals={literals}
                  formattedCta={formattedCta}
                  type="services"
                  alertCancelActivity={alertCancelActivity}
                  alertCancelBooking={alertCancelBooking}
                />
              </CollapsibleContainer>
            </div>
          )}
        </div>
      </ContainerGrid>
    </Appear>
  )
}

export default BookingInfoList

// @flow

import React, { useEffect, useState } from 'react'
import HTMLText from './HTMLText'

type Props = {
  text: string
}

const DropCapText = ({ text }: Props) => {
  const [dropCap, setDropCap] = useState(text.charAt(0))
  const [textBody, setTextBody] = useState(text.substring(1))

  useEffect(() => {
    if (text.charAt(0) === '*' && text.charAt(1) === '*') {
      setDropCap(text.charAt(2))
      const replacedCharText = text.replace(`${text.charAt(2)}`, '*')
      setTextBody(replacedCharText.substring(1))
    }
  }, [])

  return (
    <div className="grid grid-cols-4 gap-4 md:grid-cols-5 md:gap-6 lg:gap-8">
      <div className="col-span-1 col-start-1 leading-none text-center uppercase md:text-right font-primary text-gray text-huge2 md:text-huge3">
        {dropCap}
      </div>
      <HTMLText 
        className="col-span-3 col-start-2 text-gray md:col-span-4 font-secondary text-small md:text-normal"
        text={textBody}
      />
    </div>
  )
}

export default DropCapText

// @flow

import React from 'react'

const ReactMarkdown = require('react-markdown/with-html')

type Props = {
  className?: string,
  text: string,
}

const HTMLText = ({ className = "", text }: Props) => {
  return (
    <ReactMarkdown
    className={`markdown ${className}`}
    source={text}
    escapeHtml={false}
    linkTarget="_blank"
    />
  )
}

export default HTMLText

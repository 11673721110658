// @flow

import React, { useEffect, useRef, useState } from 'react'
import type { Ref, Image as ImageType } from 'types/'
import WebP from 'lib/WebP'
import useEvent from 'hooks/useEvent'
import throttle from '../lib/throttle'
import addObserver from '../lib/addObserver'
import 'intersection-observer'

type Props = {
  className?: string,
  image: ImageType,
  alt: string,
  imgLink?: boolean,
  imgClassName?: string,
  ignorePlaceholder?: boolean
}

const Image = ({
  className = '',
  image = {
    dir: '',
    extension: 'jpg',
    name: {
      normal: '',
      lazy: '',
    },
    sizes: [],
    webp: true,
  },
  alt = '',
  imgLink = false,
  imgClassName = '',
  ignorePlaceholder = false,
}: Props): React$Node => {
  const mainRef: Ref = useRef(null)
  const [imageSrc, setImageSrc] = useState(false)
  const [lastSize, setLastSize] = useState(-1)
  const [showLazy, setShowLazy] = useState(false)
  const [animate, setAnimate] = useState(false)

  const wp = new WebP()

  if (!image || !image.extension) {
    return null
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadImage()
          observer.unobserve(mainRef.current)
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.25
      }
    )
    if (mainRef.current) {
      observer.observe(mainRef.current)
    }
  }, [])

  const handleResize = () => {
    if (imageSrc) {
      loadImage()
    }
  }

  useEvent('resize', handleResize)
  image.extension = image.extension ? image.extension.replace("png", "jpg") : image.extension = "jpg"


  const loadImage = () => {
    if (image && mainRef.current) {
      let imageToLoadUrl = `${image.dir}${image.name.normal}.${image.extension}`
    let newSize = 0
    wp.isSupported(supported => {
      if (image.webp) {
        imageToLoadUrl = `${image.dir}${image.name.normal}-${image.sizes[0]}.${
          supported ? 'webp' : 'jpg'
        }`
        image.sizes
          .sort((a, b) => Number(a) - Number(b))
          .forEach((size, i) => {
            if (Number(mainRef.current.offsetWidth * window.devicePixelRatio) > Number(size)) {
              const s =
                typeof image.sizes[i + 1] !== 'undefined'
                  ? image.sizes[i + 1]
                  : size
              imageToLoadUrl = `${image.dir}${image.name.normal}-${s}.${
                supported ? 'webp' : 'jpg'
              }`
              newSize = s
            }
          })
      }
      if (imageSrc !== imageToLoadUrl && Number(newSize) > Number(lastSize)) {
        const newImage = new window.Image()
        newImage.onload = function() {
          setImageSrc(`${imageToLoadUrl}`)
          setLastSize(newSize)
        }
        newImage.src = `${imageToLoadUrl}`
      }
    })
    }
  }

  useEvent('resize', throttle(loadImage, 1000))

  useEffect(() => {
    addObserver(mainRef.current, '300%', 0, () => {
      setShowLazy(true)
    })
    if (mainRef.current !== null) {
      addObserver(mainRef.current, '200%', 0, () => {
        loadImage()
      })
    }
    addObserver(mainRef.current, '100%', 0, () => {
      setAnimate(true)
    })
  }, [mainRef])

  return (
    <div
      ref={mainRef}
      className={`${className ? className : ''} overflow-visible transition-opacity duration-normal relative ${showLazy ? 'opacity-100' : 'opacity-0'}`}
    >
      <picture>
        {image.webp && (
          <source
            srcSet={`${image.dir}${image.name.lazy}.webp`}
            type="image/webp"
          />
        )}
        <img
          className={`w-full transition-all duration-500 ${ignorePlaceholder ? 'invisible' : 'visible'}`}
          src={`${image.dir}${image.name.lazy}.${image.extension}`}
          alt={alt}
        />
      </picture>
      {imageSrc !== false && (
        <img
          className={`absolute top-0 left-0 h-full w-full transition-opacity duration-500 ${imgClassName}
            ${animate ? "opacity-100" : "opacity-0"}
            ${imgLink ? 'cursor-none' : ""}
          `}
          onLoad={() => setAnimate(true)}
          src={imageSrc}
          alt={alt}
          data-img-link={imgLink}
        />
      )}
    </div>
  )
}

export default Image

// @flow

import React, { useState, useEffect }  from 'react'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import Appear from './Appear'
import ContainerGrid from './ContainerGrid'
import CollapsibleContainer from './CollapsibleContainer'

type ContainerType = {
  id: string,
  title: string,
  callback: (id: string) => void,
  className?: string,
  active: boolean,
  primary?: boolean,
  variants: Object,
  arrowVariants: Object,
  transition: Object,
  children: React$Node,
  first?: boolean,
  last?: boolean,
}
type Props = {
  title: string,
  items: Array<Object>,
  dropdownTitle: string,
  dropdownPlaceholder: string,
}

const ServicesAll = ({ title, items }: Props) => {

  const [activePrimaryDropdown, setActivePrimaryDropdown] = useState()
  const [activeSecondaryDropdown, setActiveSecondaryDropdown] = useState()

  useEffect(() => {
    setActiveSecondaryDropdown(undefined)
  }, [activePrimaryDropdown])

  function changeActivePrimaryDropdown(id) {
    if (id === activePrimaryDropdown) {
      setActivePrimaryDropdown(undefined)
    } else setActivePrimaryDropdown(id)
  }

  function changeActiveSecondaryDropdown(id) {
    if (id === activeSecondaryDropdown) {
      setActiveSecondaryDropdown(undefined)
    } else setActiveSecondaryDropdown(id)
  }

  return (
    <ContainerGrid>
      <div className="grid grid-cols-4 gap-4 mb-32 md:gap-6 lg:gap-8 md:grid-cols-10 text-gray border-gray">
        <div className="col-span-3 mb-12 md:col-span-5 md:mb-10 lg:mb-8">
          <Appear>
            <h2 className="leading-none font-primary text-big1 md:text-big4">{title}</h2>
          </Appear>
        </div>
        <div className="grid grid-cols-4 col-span-4 col-gap-4 md:col-span-10 md:col-gap-6 lg:col-gap-8 md:grid-cols-10 text-gray font-primary">
          {items.map((item, index) => (
              <div key={item.id} className="col-span-10">
                <Appear>
                  <CollapsibleContainer
                    id={item.id}
                    title={item.title}
                    callback={changeActivePrimaryDropdown}
                    active={item.id === activePrimaryDropdown}
                    first={index === 0}
                    primary
                  >
                    {item.groups.map((group, index) => (
                      <CollapsibleContainer
                        key={group.id}
                        id={group.id}
                        className="grid grid-cols-4 gap-4 md:col-span-10 md:gap-6 lg:gap-8 md:grid-cols-10 text-gray"
                        title={group.title}
                        callback={changeActiveSecondaryDropdown}
                        active={group.id === activeSecondaryDropdown}
                        first={index === 0}
                        last={index + 1 === item.groups.length || false}
                      >
                        <ul className="col-span-4 pt-4 pb-10 md:pb-12 md:pt-0 md:col-start-2 md:col-span-9 text-bigger2">
                          {group.items.map(groupItem => (
                            <li key={groupItem.id}>
                              <Link to={groupItem.ctaHref}>
                                <div className="relative flex items-center justify-between py-4 pr-8 transition-colors duration-200 border-b border-gray hover:text-primary-brown">
                                  {groupItem.title}
                                  <div className="absolute bottom-0 right-0 mb-2 md:mb-5">
                                    <svg className="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M0 16v-1.004h14.292L.06.72.766.01 15 14.288V0h1v16z"/></svg>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </CollapsibleContainer>
                    ))}
                  </CollapsibleContainer>
                </Appear>
              </div>
            )
          )}
        </div>
      </div>
    </ContainerGrid>
  )
}

export default ServicesAll

// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import ContainerGrid from './ContainerGrid'
import useIsMobile from '../hooks/useIsMobile'

type Props = {
  title: string,
  pageName?: string
}

const HeadingTitleOnly = ({ title, pageName }: Props) => {
  const isMobile = useIsMobile()
  const [windowHeight, setWindowHeight] = useState()
  const [textSize, setTextSize] = useState(100)
  const [margins, setMargins] = useState('md:mt-36 md:mb-28')

  const handleResize = () => {
    const height = document.documentElement.clientHeight || document.body.clientHeight
    setWindowHeight(height)
  }

  const handleTitleStyleDesktop = () => {
    if  (windowHeight) {
      if (windowHeight > 800) {
        setTextSize(100)
        setMargins('md:mt-36 md:mb-28')
      }
      if (windowHeight < 800) {
        setTextSize(90)
        setMargins('md:mt-32 md:mb-20')
      }
      if (windowHeight < 700) {
        setTextSize(80)
        setMargins('md:mt-28 md:mb-16')
      }
      if (windowHeight < 600) {
        setTextSize(70)
        setMargins('md:mt-20 md:mb-10')
      }
      if (windowHeight < 500) {
        setTextSize(60)
        setMargins('md:mt-16 md:mb-8')
      }
      if (windowHeight < 400) {
        setTextSize(50)
        setMargins('md:mt-12 md:mb-6')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    handleTitleStyleDesktop()
  }, [windowHeight])

  const noMargins = pageName === "calendar"
  const customMargins = pageName === "legal" ? `mt-16 mb-10 ${margins}` : `mt-16 mb-6 ${margins} print:my-0`
  function customColumns(page) {
    switch(page) {
      default: return "col-span-3 md:col-span-5 lg:col-span-3"
      case "calendar": return "col-span-3 md:col-span-6 lg:col-span-3"
      case "bookingSignup": return "col-span-4 md:col-span-8 md:col-start-2 lg:col-span-6 lg:col-start-3 text-center"
      case "recoverPassword":
      case "bookingAll":
      case "bookingLogin": return "col-span-4 md:col-span-6 md:col-start-3 lg:col-span-4 lg:col-start-4 text-center"
      case "bookingCancelled": return "col-span-4 md:col-span-10 text-center"
      case "bookingForm":
      case "bookingConfirmation":
      case "bookingConfirmed": return "col-span-4 md:col-span-7 lg:col-span-7"
    }
  }

  return (
    <Appear>
      <div className={customMargins}>
        <ContainerGrid
        cleanLeft={noMargins}
        cleanRight={noMargins}
        mdCleanLeft={noMargins}
        mdCleanRight={noMargins}
        lgCleanLeft={noMargins}
        lgCleanRight={noMargins}
        >
          <div className={`grid gap-4 md:gap-6 print:hidden lg:gap-8 ${noMargins ? "mx-4 md:mx-6 lg:mx-8 grid-cols-4 md:grid-cols-12" : "grid-cols-4 md:grid-cols-10"}`}>
            <div className={`${customColumns(pageName)}`}>
              <h1
                className={`leading-none font-primary text-gray`}
                style={{
                  fontSize: isMobile ? 50 : textSize
                }}
              >
                {title}
              </h1>
            </div>
          </div>
        </ContainerGrid>
      </div>
    </Appear>
  )
}

export default HeadingTitleOnly

// @flow

import React, { useState, useRef, useEffect } from 'react'
import type { Ref } from 'types/'
import { Link } from 'gatsby'
import useIsMobile from '../hooks/useIsMobile'
import animations from '../animations/header'
import Button from './Button'
import ButtonSecondary from './ButtonSecondary'
import Svg from './Svg'
import { useGlobalState } from 'store/'

type ExternalLink = {
  id: string,
  ctaLabel: string,
  ctaHref: string
}
type Navigation = {
  id: string,
  ctaLabel: string,
  ctaHref: string,
  active: boolean,
}
type Props = {
  city: string,
  url: string,
  navigation: Array<Navigation>,
  links: Array<ExternalLink>,
  language: string,
  languages: Array<string>,
  background: string,
  greetings: string,
  ctaEditProfile: string,
  ctaBookings: string,
  ctaLogin: string,
  ctaLogout: string,
  hrefEditProfile: string,
  hrefBookings: string,
  hrefLogin: string,
}

const Burger = ({ handler, top, middle, bottom }) => (
  <div
    className="z-40 flex items-center justify-end h-80px"
    onClick={handler}
  >
    <div className="flex flex-col">
      <span
        style={{
          width: "1.5rem",
          height: "1px",
          transition: "transform 1s, margin .3s"
        }}
        className="inline-block rounded-sm bg-gray my-1/2"
        ref={top}
      />
      <span
        style={{
          width: "1.5rem",
          height: "1px",
          transition: "transform 1s, margin .3s"
        }}
        className="inline-block rounded-sm bg-gray my-1/2"
        ref={middle}
      />
      <span
        style={{
          width: "1.5rem",
          height: "1px",
          transition: "width .3s, transform 1s, margin .3s"
        }}
        className="inline-block rounded-sm bg-gray my-1/2"
        ref={bottom}
      />
    </div>
  </div>
)

const Header = ({ 
  city,
  url,
  navigation,
  links,
  background,
  greetings,
  ctaEditProfile,
  ctaBookings,
  ctaLogin,
  ctaLogout,
  hrefEditProfile,
  hrefBookings,
  hrefLogin }: Props) => {

  const envCityMadrid = process.env.GATSBY_city === 'Madrid'

  // const [session, dispatch] = useGlobalState()
  const isMobile = useIsMobile()
  const [firstLoad, setFirstLoad] = useState(true)
  
  const [mouseOver, setMouseOver] = useState('-1')
  const [translate, setTranslate] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [onTransition, setOnTransition] = useState(false)

  const top: Ref = useRef({})
  const middle: Ref = useRef({})
  const bottom: Ref = useRef({})
  const mobile: Ref = useRef({})
  const menu: Ref = useRef({})
  const menuContent: Ref = useRef({})
  const desktop: Ref = useRef({})

  const handleMobileMenu = async () => {
    setOnTransition(true)
    if (!onTransition) {
      if (!mobileMenu) {
        await animations.burgerIn(top.current, middle.current, bottom.current, mobile.current, menu.current, menuContent.current)
        setMobileMenu(true)
        setOnTransition(false)
        return
      }
      await animations.burgerOut(top.current, middle.current, bottom.current, mobile.current, menu.current, menuContent.current)
      setMobileMenu(false)
      setOnTransition(false)
    }
  }
  
  const scrollCallback = () => {
    if (window.pageYOffset > 0 && desktop.current !== null) {
      setTranslate(true)
    }
   if (window.pageYOffset <= 0 && desktop.current !== null) {
     setTranslate(false)
   }
  }

  useEffect(() => {
    setFirstLoad(false)
    if (isMobile === false) {
      window.addEventListener('scroll', scrollCallback)
      return () => {
      window.removeEventListener('scroll', scrollCallback)
      }
    } else {
      const body: any = document.body
      body.style.position = 'initial'
    }
  }, [])

  useEffect(() => {
    if (!firstLoad) {
      document.body.style.position = 'initial'
    }
  }, [isMobile])

  // (user login hidden)
  // useEffect(() => {
  // }, [session])

  const switchBackgroundColor = (background) => {
    switch (background) {
      case 'primary-beige':
        return '#F1EBDE'
      case 'primary-pink':
        return '#E6D9D7'
      case 'primary-brown':
        return '#C7B7AF'
    }
  }

  // mobile version
  if (isMobile) {
    return (
      <div className={`md:hidden z-40 w-screen fixed top-0 bg-${background}`}>
        <div className="grid grid-cols-4 gap-4 h-80px">
          <div className="flex items-center justify-start col-span-2 col-start-1 ml-4">
            <Link className="z-10 w-full" to={url}>
              <Svg name="logo" color="gray" width="100%" height="100%"/>
            </Link>
          </div>
          <div className='flex items-center justify-end col-span-1 col-start-4 mr-4'>
            {/* (user login hidden) */}
            {/* {!session && !envCityMadrid && <div className="z-40 mb-px mr-6">
              <Link to={hrefLogin}>
                <Svg name="User" color="gray" height={32} />
              </Link>
            </div>} */}
            <Burger handler={handleMobileMenu} top={top} middle={middle} bottom={bottom} />
          </div>
          <div
          style={{
            transform: "translateY(-100vh)",
            transition: `transform 1s`,
            height: "100vh",
            backgroundColor: switchBackgroundColor(background)
          }}
          ref={mobile}
          className="relative col-span-4 -mt-4"
          >
            <div
              ref={menu}
              style={{
                backgroundColor: switchBackgroundColor(background),
                transition: 'background-color 2s ease'
              }}
              className='relative w-screen h-full px-4 pb-6 overflow-y-auto bg-primary-brown pt-14'
            >
              <div
                ref={menuContent}
                style={{
                  opacity: 0,
                  transition: 'opacity .5s ease-in-out'
                }}
                className={`relative flex flex-col justify-between h-full ${mobileMenu ? "" : "pointer-events-none"}`}
              >
                <div key="top-bg-provider" className="fixed inset-x-0 top-0 z-30 py-10 bg-primary-brown" />
                <div>
                  <span className="font-secondary text-gray text-small">{city}</span>
                  <nav className="pt-8">
                    {/* (user login hidden) */}
                    {/* {session && !envCityMadrid && <div className="mb-12">
                        <span className="uppercase font-secondary text-small text-gray">{`${greetings} ${session.name}`}</span>
                        <span className="mx-2 font-secondary text-small text-gray">|</span>
                        <ButtonSecondary
                          cta={{ label: ctaEditProfile, href: hrefEditProfile }}
                          internalLink={true}
                          hideArrow={true}
                          hoverEffect={true}
                        />
                        <span className="mx-2 font-secondary text-small text-gray">|</span>
                        <ButtonSecondary
                          cta={{ label: ctaBookings, href: hrefBookings }}
                          internalLink={true}
                          hideArrow={true}
                          hoverEffect={true}
                        />
                        <span className="mx-2 font-secondary text-small text-gray">|</span>
                        <ButtonSecondary 
                          cta={{ label: ctaLogout, href: "#" }} 
                          hoverEffect={true} 
                          actionButton={true} 
                          actionButtonOnClick={() => {
                            dispatch({ type: "logout" })
                          }}
                        /> 
                      </div>
                    } */}
                    {navigation.map(item => <Link
                    to={item.ctaHref}
                    onClick={() => {
                      const body: any =  document.body
                      body.style.position = "initial"
                    }}
                    onMouseOver={() => setMouseOver(item.id)}
                    onMouseLeave={() => setMouseOver("-1")}
                    key={item.id}
                    className="flex items-center justify-between mt-3 border-b border-gray"
                    >
                      <span className="font-primary text-bigger3 text-gray">
                        {item.ctaLabel}
                      </span>
                      <Svg name="arrow-down-right" color="gray" width={16} height={16} />
                    </Link>)}
                  </nav>
                </div>
                <div className="mt-4 mb-8">
                  {links && links.map(link => <div key={link.id} className="pb-2">
                    <Button cta={{ label: link.ctaLabel, href: link.ctaHref }} openNewWindow={true} disabled={!mobileMenu ? true : false} />
                  </div>)}
                  {/* LANGUAGE OPTIONS HIDDEN */}
                  {/* <div
                  className="flex justify-center mt-6">
                    {languages.map((lang, index) => <a key={index} className="px-2">
                      <span
                      className={`uppercase text-gray text-small ${lang === language ? 'border-gray border-b' : ''}`}
                      >
                        {lang}
                      </span>
                    </a>)}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // desktop version
  return (
    <div
    ref={desktop}
    className={`hidden md:block z-40 fixed top-0 right-0 print:hidden left-0 h-150px transform bg-${background} ${translate ? "md:-translate-y-1/2" : "md:translate-y-0"} transition-transform ease-out duration-300`}>
      <div className="grid grid-cols-12 gap-6 mx-6 border-b lg:gap-8 h-1/2 py-auto border-gray">
        <div className="flex items-center col-span-2 col-start-1">
          <Link to={url} className='w-full'>
            <Svg name="logo" color="gray" height={32}/>
          </Link>
        </div>
        <div className="flex items-center justify-end col-span-10 col-end-13">
          <span className="font-secondary text-small text-gray">{city}</span>
            {/* (user login hidden) */}
            {/* {!envCityMadrid && <>
              {session ? <>
                {city && <span className="mx-2 font-secondary text-small text-gray">|</span>}
                <span className="uppercase font-secondary text-small text-gray">{`${greetings} ${session.name}`}</span>
                <span className="mx-2 font-secondary text-small text-gray">|</span>
                <ButtonSecondary
                  cta={{ label: ctaEditProfile, href: hrefEditProfile }}
                  internalLink={true}
                  hideArrow={true}
                  hoverEffect={true}
                /> 
                <span className="mx-2 font-secondary text-small text-gray">|</span>
                <ButtonSecondary
                  cta={{ label: ctaBookings, href: hrefBookings }}
                  internalLink={true}
                  hideArrow={true}
                  hoverEffect={true}
                /> 
                <span className="mx-2 font-secondary text-small text-gray">|</span>
                <ButtonSecondary 
                  cta={{ label: ctaLogout, href: "#" }} 
                  hoverEffect={true} 
                  actionButton={true} 
                  actionButtonOnClick={() => {
                    dispatch({ type: "logout" })
                  }} 
                /> 
                </>
                : <>
                  {city && <span className="mx-2 font-secondary text-small text-gray">|</span>}
                  <ButtonSecondary
                    cta={{ label: ctaLogin, href: hrefLogin }}
                    internalLink={true}
                    hoverEffect={true}
                  />
                </>
              }
            </>} */}
        </div>
      </div>
      <nav className={`w-full bg-${background} flex h-1/2 py-auto justify-center items-center`}>
        {navigation.map((item, index) => <div
        onMouseOver={() => setMouseOver(item.id)}
        onMouseLeave={() => setMouseOver("-1")}
        key={item.id}
        className="font-secondary text-gray text-normal"
        >
          {index !== 0 && <span className="mx-4">|</span>}
            <Link
              to={item.ctaHref}
              className={`${mouseOver === item.id || item.active ? "border-primary-brown border-b text-primary-brown" : "text-gray"}`}
            >
              {item.ctaLabel}
            </Link>
          </div>)}
      </nav>
    </div>
  )
}

export default Header

// @flow

import React from "react"
import ModuleIterator from "components/ModuleIterator"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import type { Meta, Module } from "types/"

type Props = {
  meta: Meta,
  structure: Array<Module>,
  literals: {},
  media: {},
  language: string,
  location: string,
}

const Page = ({ meta, structure, language, location }: Props) => {
  const {
    title,
    description,
    keywords,
    favicon,
    canonical,
    author,
  }: Meta = meta

  return (
    <>
      <Helmet title="es">
        <html lang={language} />
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="language" content={language} />
        <link rel="icon" type="image/gif" href={favicon} />
        <meta
          name="author"
          content={author || "Fragment Agency, hello@fragment.agency"}
        />
        <meta name="canonical" content={canonical} />
        {process.env.GATSBY_city === "Madrid" && (
          <meta
            name="google-site-verification"
            content="l5RovkMoNugxqnhA1s2isI2UOiZaWkTqdOTgMZKTWGM"
          />
        )}
        <script type="text/javascript">
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '2474337246037657');
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1"
          src="https://www.facebook.com/tr?id=2474337246037657&ev=PageView&noscript=1"
          />`}
        </noscript>
      </Helmet>
      <Layout>
        <ModuleIterator modules={structure} />
      </Layout>
    </>
  )
}

export default Page

// @flow

import React, { useState } from 'react'
import Svg from './Svg'
import { Link } from 'gatsby'

type Props = {
  cta: Object,
  darkMode?: boolean,
  submitButton?: boolean,
  internalLink?: boolean,
  actionButton?: boolean,
  actionButtonOnClick?: Function,
  openNewWindow?: boolean,
  rotate?: boolean,
  rotationDeg?: string,
  disabled?: boolean,
  loading?: boolean,
}

const Button = ({
  cta,
  darkMode,
  submitButton,
  internalLink,
  actionButton,
  actionButtonOnClick,
  openNewWindow,
  rotate,
  rotationDeg,
  disabled,
  loading,
}: Props) => {
  const [mouseOver, setMouseOver] = useState(false)

  if (submitButton) {
    return (
      <button
        href={disabled ? '' : cta.href}
        type="submit"
        className={`${disabled ? "opacity-50 pointer-events-none" : "pointer-events-auto opacity-100"} w-full items-end p-3 border text-gray flex focus:outline-none ${
          loading ? 'justify-center pointer-events-none' : 'justify-between'
        } border-gray ${
          darkMode
            ? `${mouseOver ? 'bg-transparent' : 'bg-gray'}`
            : `${mouseOver ? 'bg-gray' : 'bg-transparent'}`
        }`}
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        disabled={disabled}
      >
        {loading ? (
          <div
            className={`loader button ${
              darkMode
                ? `${mouseOver ? '' : 'dark-mode'}`
                : `${mouseOver ? 'dark-mode' : ''}`
            }`}
          ></div>
        ) : (
          <>
            <span
              className={`text-bigger2 font-primary ${
                darkMode
                  ? `${mouseOver ? 'text-gray' : 'text-white'}`
                  : `${mouseOver ? 'text-white' : 'text-gray'}`
              }`}
            >
              {cta.label}
            </span>
            <Svg
              name="arrow-down-right"
              color={
                darkMode
                  ? `${mouseOver ? 'gray' : 'white'}`
                  : `${mouseOver ? 'white' : 'gray'}`
              }
              width={16}
              height={16}
            />
          </>
        )}
      </button>
    )
  }

  if (actionButton) {
    return (
      <div
        onClick={disabled ? () => {} : actionButtonOnClick}
        className={`cursor-pointer items-end p-3 border text-gray flex justify-between border-gray ${
          darkMode
            ? `${mouseOver ? 'bg-transparent' : 'bg-gray'}`
            : `${mouseOver ? 'bg-gray' : 'bg-transparent'}`
        }`}
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <span
          className={`text-bigger2 font-primary ${
            darkMode
              ? `${mouseOver ? 'text-gray' : 'text-white'}`
              : `${mouseOver ? 'text-white' : 'text-gray'}`
          }`}
        >
          {cta.label}
        </span>
        <Svg
          name="arrow-down-right"
          color={
            darkMode
              ? `${mouseOver ? 'gray' : 'white'}`
              : `${mouseOver ? 'white' : 'gray'}`
          }
          width={16}
          height={16}
          className={`transition-transform duration-500 ease-in-out origin-center transform ${
            rotate && rotationDeg ? `rotate-${rotationDeg}` : 'rotate-0'
          }`}
        />
      </div>
    )
  }

  if (internalLink) {
    return (
      <Link
        to={cta.href}
        className={`cursor-pointer items-end p-3 border text-gray flex justify-between border-gray ${disabled ? 'pointer-events-none' : ''} ${
          darkMode
            ? `${mouseOver ? 'bg-transparent' : 'bg-gray'}`
            : `${mouseOver ? 'bg-gray' : 'bg-transparent'}`
        }`}
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        disabled={disabled}
      >
        <span
          className={`text-bigger2 font-primary ${
            darkMode
              ? `${mouseOver ? 'text-gray' : 'text-white'}`
              : `${mouseOver ? 'text-white' : 'text-gray'}`
          }`}
        >
          {cta.label}
        </span>
        <Svg
          name="arrow-down-right"
          color={
            darkMode
              ? `${mouseOver ? 'gray' : 'white'}`
              : `${mouseOver ? 'white' : 'gray'}`
          }
          width={16}
          height={16}
        />
      </Link>
    )
  }

  return (
    <a
      href={disabled ? '' : cta.href}
      className={`cursor-pointer items-end p-3 border text-gray flex justify-between border-gray ${
        darkMode
          ? `${mouseOver ? 'bg-transparent' : 'bg-gray'}`
          : `${mouseOver ? 'bg-gray' : 'bg-transparent'}`
      }`}
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      target={openNewWindow ? '_blank' : null}
    >
      <span
        className={`text-bigger2 font-primary ${
          darkMode
            ? `${mouseOver ? 'text-gray' : 'text-white'}`
            : `${mouseOver ? 'text-white' : 'text-gray'}`
        }`}
      >
        {cta.label}
      </span>
      <Svg
        name="arrow-down-right"
        color={
          darkMode
            ? `${mouseOver ? 'gray' : 'white'}`
            : `${mouseOver ? 'white' : 'gray'}`
        }
        width={16}
        height={16}
      />
    </a>
  )
}

export default Button

// @flow

import React, { useState } from 'react'
import { Link } from 'gatsby'
import Svg from './Svg'


type Tag = {
  id: string,
  title: string,
  color?: string,
  disabled?: boolean,
}
type Props = {
  tags: Array<Tag>,
  name: string,
  itemLink: string,
  url: string,
  calendarUrl: string,
  viewSchedule: string,
}

const ActivitiesItem = ({
  tags,
  name,
  url,
  calendarUrl,
  viewSchedule
}: Props) => {
  const [mouseOver, setMouseOver] = useState(false)
  const [mouseOverCalendar, setMouseOverCalendar] = useState(false)
  const color = tags.length > 0 ? tags[0].color : ''
  return (
    <div className="flex items-center justify-between col-span-1 py-4 border-b border-gray">
      <Link
        to={url}
        className="flex items-center justify-start pr-4"
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <div className={`flex-shrink-0 w-5 h-5`} style={{ backgroundColor: `#${color || ""}` }} />
        <span className={`mx-5 font-primary leading-tight ${mouseOver ? "text-primary-brown" : "text-gray"} text-bigger2`}>{name}</span>
        <Svg name="arrow-down-right" color={mouseOver ? "primary-brown" : "gray"} width={16} height={16} />
      </Link>
      <Link
        to={calendarUrl}
        className="flex items-center justify-end"
        onMouseOver={() => setMouseOverCalendar(true)}
        onMouseLeave={() => setMouseOverCalendar(false)}
      >
        <span className={`hidden mt-1 font-secondary ${mouseOverCalendar ? "text-primary-brown" : "text-gray"} text-small md:inline`}>{viewSchedule}</span>
        <div className="md:-mt-px ml-10px">
          <Svg name="calendar" color={mouseOverCalendar ? "primary-brown" : "gray"} width={20} height={20} />
        </div>
      </Link>
    </div>
  )
}

export default ActivitiesItem
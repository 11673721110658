// @flow

import React from 'react'

type Props = {
  cleanLeft?: boolean,
  mdCleanLeft?: boolean,
  lgCleanLeft?: boolean,
  cleanRight?: boolean,
  mdCleanRight?: boolean,
  lgCleanRight?: boolean,
  children: React$Node,
}

// Grid 12 columns (desktop) - 4 columns (mobile)
// margin left: 1-2 (desktop)
// margin right: 12-13 (desktop)
// content w/margins: 10 columns (desktop) - 4 columns (mobile)
// content w/margin one side: 11 columns (desktop) - 4 columns (mobile)
// content w/no margins: 12 columns (desktop) - 4 columns (mobile)

const ContainerGrid = ({ cleanLeft, mdCleanLeft, lgCleanLeft, cleanRight, mdCleanRight, lgCleanRight, children }: Props) => {
  return (
    <div className={`overflow-hidden grid grid-cols-4 gap-4 md:grid-cols-12 md:gap-6 lg:gap-8
    ${cleanLeft ? 'ml-0' : 'ml-4'}
      ${mdCleanLeft ? 'md:ml-0' : 'md:ml-6'}
      ${lgCleanLeft ? 'lg:ml-0' : 'lg:ml-8'}
      ${cleanRight ? 'mr-0' : 'mr-4'}
      ${mdCleanRight ? 'md:mr-0' : 'md:mr-6'}
      ${lgCleanRight ? 'lg:mr-0' : 'lg:mr-8'}
    `}>
      <div className={`
      ${cleanLeft ? 'col-start-1' : 'col-start-1'}
      ${mdCleanLeft ? 'md:col-start-1' : 'md:col-start-2'}
      ${lgCleanLeft ? 'lg:col-start-1' : 'lg:col-start-2'}
      ${cleanRight ? 'col-end-5' : 'col-end-5'}
      ${mdCleanRight ? 'md:col-end-13' : 'md:col-end-12'}
      ${lgCleanRight ? 'lg:col-end-13' : 'lg:col-end-12'}
      `}>
        {children}
      </div>
    </div>
  )
}

export default ContainerGrid

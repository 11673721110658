// @flow

import React, { useState, useEffect, useRef } from 'react'
import type { Ref, Image } from 'types/'
import Svg from './Svg'

type Item = {
  id: string,
  title: string,
  color?: string,
  disabled?: boolean,
}

type Props = {
  id?: string,
  image?: Image,
  placeholder: string,
  title?: string,
  items: Array<Item>,
  dropdownValue: Function,
  dropdownClick?: Function,
  forceClose?: string,
  urlFilter?: string,
  bgColor?: string,
  firstLoad?: boolean,
  activeItems?: Array<Object> | string,
}

const FilterDropdown = ({ 
  id,
  title,
  placeholder,
  items,
  dropdownValue,
  dropdownClick,
  forceClose,
  urlFilter,
  bgColor,
  firstLoad,
  activeItems
 }: Props) => {
  function normalize(string) {
    const normalized = string
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .replace(/[()¡!¿?[];:]/g,'')
      .replace(/[ .]/g,'-')
    const lastChar = normalized.slice(normalized.length - 1)
    if (lastChar == "-") return normalized.slice(0, -1)
    return normalized
  }

  // Optional prop
  // Force closed state
  // Used with dropdownClick when there's more than one dropdown
  useEffect(() => {
    if (forceClose !== id) {
      setOpen(false)
    }
  }, [forceClose])

  // Open & close dropdown
  const [open, setOpen] = useState(false)
  const placeholderObj = { id: "-1", title: placeholder }

  // Set selected item
  // Defaults to placeholder
  // URL search string overrides it
  const defaultValue = (force = false) => {
    if (urlFilter && !force) {
      const item = items.find(item => (
        item.id === urlFilter
        || normalize(item.title) === urlFilter
      ))
      if (typeof item !== "undefined") return item
    }
    return placeholderObj
  }
  
  const [selectedItem, setSelectedItem] = useState(placeholderObj)
  const [dropdownItems, setDropdownItems] = useState(items)

  useEffect(() => {
    setSelectedItem(defaultValue())
  }, [firstLoad])

  // Animations
  const [listHeight, setListHeight] = useState(0)
  const [opacity, setOpacity] = useState(1)

  // Click on dropdown head
  const handleHeadClick = () => setOpen(!open)

  // Click on dropdown item & lift value to parent
  const handleClick = (item) => {
    if (item !== selectedItem) {
      // Wait for opacity transition end
      setOpacity(0)
      setTimeout(() => {
        setSelectedItem(item)
      }, 100)
    }
    setOpen(!open)
  }

  // Dropdown list height
  useEffect(() => {
    if (open === true) {
      setListHeight(352)
    } else {
      setListHeight(0)
    }

    // Optional prop
    // Lifts id & open value to parent.
    // Used with forceClose when there's more than one dropdown
    if(dropdownClick) {
      dropdownClick(id, open)
    }
  }, [open])

  // On item change
  useEffect(() => {
    // Animate opacity
    setTimeout(() => {
      setOpacity(1)
    }, 100)
    // Lift dropdown value to parent el
    dropdownValue(id, selectedItem)
  }, [selectedItem])

  // Dropdown list position
  const headEl: Ref = useRef(null)
  const listPos = useRef({value: 56})
  useEffect(() => {
    if(headEl.current.offsetHeight < 56) {
      listPos.current.value = headEl.current.offsetHeight
    }
  }, [])
  
  useEffect(() => {
    if (activeItems && activeItems !== "all") {
      let newItems = []
      items.forEach(item => {
        if (!activeItems.includes(item.title)) {
          item.disabled = true
        } else item.disabled && delete item.disabled
        newItems.push(item)
      })
      setDropdownItems(newItems)
    } else setDropdownItems(items)
  }, [activeItems])

  return (
    <div className="text-gray">
      <span className="uppercase text-smaller font-secondary">{title}</span>
      <div
        data-name="dropdown-head"
        ref={headEl}
        className="relative w-full mt-4 cursor-pointer font-secondary focus:outline-none"
        onClick={handleHeadClick}
      >
        <div className="flex items-center justify-between py-4 border border-gray px-10px">
          <span
            className="mt-px leading-tight text-left transition-opacity duration-100 ease-linear"
            style={{opacity: `${opacity}`}}
          >
            {typeof selectedItem !== "undefined" && selectedItem.title}
          </span>
          <div className={`transform transition-transform duration-200 ease-in-out ${open === true ? "-rotate-180" : "rotate-0"}`}>
            <Svg name="arrow-dropdown" height={20} width={20} />
          </div>
        </div>
        <div
          data-name="dropdown-list"
          className={`absolute z-20 h-auto left-0 w-full border-l border-r border-gray overflow-y-auto ${bgColor ? `bg-${bgColor}` : "bg-primary-pink"} transition-all duration-200 ease-in-out ${open ? "border-b" : ""}`}
          style={{maxHeight:`${listHeight}px`, top:`${listPos.current.value}px`}}
        >
          <button
            type="button"
            className="block w-full py-4 text-left border-b px-10px border-gray font-secondary text-small"
            onClick={() => handleClick(defaultValue(true))}
          >
            {placeholder}
          </button>
          {dropdownItems.map((item, index) => {
            const hasDisable = typeof item.disabled !== "undefined"
            return (
              <button
                type="button"
                key={index}
                className={`
                  block w-full py-4 text-left px-10px border-gray font-secondary text-small text-gray
                  ${hasDisable && item.disabled ? "text-opacity-25" : "text-opacity-100"}
                  ${index !== items.length - 1 ? "border-b" : ""}
                `}
                onClick={(event) => {
                  if (hasDisable && item.disabled) {
                    event.stopPropagation()
                    event.preventDefault()
                  } else handleClick(item)}
                }
              >
                {item.title}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FilterDropdown

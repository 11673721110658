// @flow

import React from 'react'
import { Field } from 'formik'

type Props = {
  name: string,
  placeholder: string,
  required?: boolean,
  rows?: number
}

const FormTextArea = ({ name, placeholder, required, rows }: Props) => {
  return (
    <Field 
      className="w-full px-5 pt-4 pb-3 bg-transparent border rounded-none min-h-51px mb-10px border-gray placeholder-gray font-secondary text-small focus:outline-none" 
      component="textarea"
      name={name} 
      placeholder={placeholder} 
      required={required}
      rows={rows ? rows : 6}
      style={{ resize: 'none' }}
    />
  )
}

export default FormTextArea

// @flow
import React from 'react'
import type { Module } from 'types/'

const components = {}

function importAll(r) {
  r.keys().forEach(key => {
    const filename = key.match(/([a-zA-Z0-9]+)\.js$/)
    if (filename) {
      components[filename[1]] = r(key).default
    }
  })
}

importAll(require.context('components', true))

const ModuleIterator = ({ modules }: { modules: Array<Module> }) => {
  return (
    <>
      {modules.map((m: Module, index: number) => {
        const Component = components[m.name]
        return <Component {...m.props} key={index} />
      })}
    </>
  )
}

export default ModuleIterator

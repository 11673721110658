// @flow

import React from 'react'
import { motion } from 'framer-motion'

type Props = {
  id: string,
  title?: string,
  callback: (id: string) => void,
  className?: string,
  active: boolean,
  primary?: boolean,
  children: React$Node,
  first?: boolean,
  last?: boolean,
  textStyle?: string,
}
const CollapsibleContainer = ({
  id,
  title,
  callback,
  className = "",
  active,
  primary = false,
  children,
  first = false,
  last = false,
  textStyle,
  }: Props) => {
    const variants = {
      show: {
        opacity: 1,
        height: "auto",
      },
      hide: {
        opacity: 0,
        height: "0"
      },
    }

    const arrowVariants = {
      show: {
        rotate: 180,
      },
      hide: {
        rotate: 0,
      },
    }

    const transition = {
      type: "tween",
      ease: [0.4, 0, 0.2, 1],
      duration: 0.3,
    }

    function disableUppercase(title) {
      return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()
    }

    return (
      <div className="flex flex-col">
        <div
          tabIndex="0"
          onClick={() => callback(id)}
          className={`
            focus:outline-none cursor-pointer leading-none flex items-center justify-between
            text-gray border-gray
            ${primary
              ? "text-bigger5 md:text-big2 py-5 md:pb-8 md:pt-6 md:mb-2 border-b font-primary"
              : `${ textStyle ? textStyle : "text-bigger2 md:text-big1 pt-4 pb-3 md:py-4 mb-px font-primary"}`}
            ${!first && !primary ? "border-t" : ""}
            ${last && !primary ? "border-b" : ""}
          `}
        >
          {title && <h4 className="pr-4">{disableUppercase(title)}</h4>}
          <motion.span
            initial={false}
            animate={active ? "show" : "hide"}
            variants={arrowVariants}
            transition={transition}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" className="w-5 h-5 fill-current" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.946 19.972a.424.424 0 00.29-.115l.018-.012 9.554-9.54a.437.437 0 00.001-.615.435.435 0 00-.616-.001l-8.808 8.797.052-18.078a.436.436 0 00-.87-.003l-.05 18.084-8.79-8.8a.435.435 0 00-.614.615l9.526 9.54c.005.005.01.005.014.01a.43.43 0 00.127.084c.05.02.101.03.154.031.005 0 .008.003.012.003z"/></svg>
          </motion.span>
        </div>
        <motion.div
          initial={false}
          className={`overflow-hidden ${className}`}
          animate={active ? "show" : "hide"}
          variants={variants}
          transition={transition}
        >
          {children}
        </motion.div>
      </div>
    )
}

export default CollapsibleContainer

// @flow

import React from 'react'
import Button from "./Button"
import ContainerGrid from './ContainerGrid'

type File = {
  file: string
}

type Props = {
  title?: string,
  text?: string,
  file?: File,
  ctaLabel?: string,
}

const CalendarPDF = ({ title, text, file, ctaLabel }: Props) => {
  return (
    <ContainerGrid>
      <div className="p-2 border md:mb-16 border-gray text-gray">
        <div className="px-6 py-4 border-2 md:px-12 md:py-6 border-primary-brown">
          <div className="mb-4 font-bold text-center font-primary text-bigger5">{title}</div>
          <div className="text-center md:w-2/3 md:mx-auto text-normal font-secondary">{text}</div>
          {file && file.file && file.file !== "" && ctaLabel && <div className="mt-8 w-full md:mx-auto md:w-1/3">
            <Button
              cta={{ label: ctaLabel, href: file.file }}
              darkMode={true}
              openNewWindow
            />
          </div>}
        </div>
      </div>
    </ContainerGrid>
  )
}

export default CalendarPDF
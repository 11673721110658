// @flow

import React from 'react'
import type { Image } from 'types/'
import useIsMobile from '../hooks/useIsMobile'

type Position = {
  desktop?: string,
  mobile?: string,
}

type Props = {
  desktop?: Image,
  mobile?: Image,
  imagePosition: Position,
}

const BackgroundImage = ({ desktop, mobile, imagePosition }: Props) => {

  const isMobile = useIsMobile()
  // $FlowFixMe
  const desktopUrl = typeof desktop !== 'undefined' ? `${desktop.dir}${desktop.name.normal}.${desktop.extension}` : ''

  // $FlowFixMe
  const mobileUrl = typeof mobile !== 'undefined' ? `${mobile.dir}${mobile.name.normal}.${mobile.extension}` : ''

  const positionString = isMobile ? imagePosition.mobile : imagePosition.desktop
  const marginTop = isMobile ? -80 : -150

  if (!desktop && !isMobile) {
    return null
  }

  if (!mobile && isMobile) {
    return null
  }

  return (
    <div style={{
      overflow: 'visible',
      position: 'absolute',
      height: "auto",
      width: "100vw",
      marginTop: parseInt(positionString) === 0 ? marginTop : 0,
      zIndex: -10,
      bottom: positionString === "100" ? 0 : 'initial',
      top: positionString === "100" ? 'initial' : `${positionString}%`,
      left: 0,
      right: 0,
    }}
    className="inset-x-0 pointer-events-none select-none print:hidden"
    data-element="backgroundImage"
    >
    <img src={isMobile ? mobileUrl : desktopUrl} className="w-full h-full" />
    </div>
  )
}

export default BackgroundImage

// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import { navigate } from 'gatsby'
import { Formik, Form } from 'formik'
import moment from 'moment'
import ContainerGrid from './ContainerGrid'
import DropCapText from './DropCapText'
import FormField from './FormField'
import FormCheckbox from './FormCheckbox'
import '../styles/calendar.css'
import Calendar from 'react-calendar'
import Button from './Button'
import Svg from './Svg'
import sessionService from '../services/session.service'
import { useGlobalState } from 'store/'
import HTMLText from './HTMLText'


type Props = {
  pageName: string,
  terms: string,
  text: string,
  ctaLabel: string,
  ctaHref: string,
  selectDateMessage: string,
  noResultsMessage: string,
  familyTitle: string,
  serviceTitle: string,
  treatmentTitle: string,
  calendarTitle: string,
  hourTitle: string,
}

const Label = ({title}) => <label className="block col-span-4 mt-6 text-left uppercase md:col-start-1 md:mt-0 mb-10px md:col-span-6 font-secondary text-smaller">{title}</label>

const BookingForm = ({
  text,
  terms,
  ctaLabel,
  ctaHref,
  selectDateMessage,
  noResultsMessage,
  familyTitle,
  serviceTitle,
  treatmentTitle,
  calendarTitle,
  hourTitle
}: Props) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [formLoaded, setFormLoaded] = useState(false)
  const [loadingHours, setLoadingHours] = useState(false)
  const [hoursLoaded, setHoursLoaded] = useState(false)
  const [param, setParam] = useState("")
  const [allServices, setAllServices] = useState([])
  const [availableHours, setAvailableHours] = useState([])
  const [familyOptions, setFamilyOptions] = useState([])
  const [serviceOptions, setServiceOptions] = useState([])
  const [treatmentOptions, setTreatmentOptions] = useState([])
  const [activeFamily, setActiveFamily] = useState()
  const [activeService, setActiveService] = useState()
  const [activeTreatment, setActiveTreatment] = useState()
  const [finalSelection, setFinalSelection] = useState()
  const [price, setPrice] = useState()
  const [session] = useGlobalState()

  // Sets calendar minimum date to select
  const nextAvailableBookingDate = new Date
  nextAvailableBookingDate.setDate(nextAvailableBookingDate.getDate() + 1)
  
  // Capture url param
  useEffect(() => {
    if (typeof window !== "undefined") {
      const currentParams = new URLSearchParams(window.location.search)
      currentParams.has("id") && setParam(currentParams.get("id"))
    }
  }, [])

  function filterItemsByParam(){
    if (param !== "" && allServices.length !== 0) {
      allServices.forEach(family => 
        family.items.forEach(service => {
          service.items.find(treatment => {
            if (treatment.id === param) {
              setActiveFamily(family.id)
              setServiceOptions(family.items)
              setActiveService(service.id)
              setTreatmentOptions(service.items)
              setActiveTreatment(treatment.id)
            }
          })
        })
      )
    }
  }

  async function loadServices() {
    const response = await sessionService.servicesList()
    setFirstLoad(false)
    setAllServices(response)
  }

  function getFamilyOptions(){
    const familyItems = []

    if (allServices.length > 0) {
      allServices.map(family => {
        familyItems.push({ id: family.id, title: family.title })
      })
      setFamilyOptions(familyItems)
    }
  }

  function getServicesOptions(value){
    const servicesItems = []
    const selectedFamily = allServices.find(family => family.id === value)
    if (typeof selectedFamily === "object") {
      selectedFamily.items.map(service => {
        servicesItems.push({ id: service.id, title: service.title })
      })
      setServiceOptions(servicesItems)
    }
  }

  function getTreatmentOptions(value){
    if (!value) {
      setTreatmentOptions([])
    } else {
      const treatmentItems = []
      const selectedFamily = allServices.find(family => family.id === activeFamily)
      const selectedService = () => {
        if (typeof selectedFamily === "object") {
          return selectedFamily.items.find(service => (
            service.id === value
          ))
        }
      }

      selectedService().items.map(treatment => {
        treatmentItems.push({ id: treatment.id, title: treatment.title })
      })
      setTreatmentOptions(treatmentItems)
    }
  }

  async function loadAvailability(serviceId, date) {
    setLoadingHours(true)
    const response = await sessionService.servicesAvailability(session.id, serviceId, date)
    setAvailableHours(response)
    setLoadingHours(false)
    setHoursLoaded(true)
  }
  
  useEffect(() => {
    if (firstLoad) {
      loadServices()
    } else {
      filterItemsByParam()
      getFamilyOptions()
      setFormLoaded(true)
    }
  }, [allServices])
  
  // Format date
  // Gets first letter of weekday
  function formatDate(locale, date) {
    if (allServices.length !== 0) {
      const dateES = (date.toLocaleDateString(locale, { weekday: "short" }))
      if (dateES === "mié.") {
        return ("x")
      } else {
        return (
          dateES.charAt(0)
        )
      }
    }
  }

  return (
    <Appear>
      <ContainerGrid
        mdCleanLeft={true}
        lgCleanLeft={true}
      >
        <div className="grid grid-cols-1 gap-4 mb-48 md:grid-cols-11 md:gap-6 lg:gap-8 text-gray md:mb-84">
          <div data-name="left-column" className="hidden col-span-1 md:col-span-5 md:block">
            <DropCapText text={text} />
          </div>
          <Appear className="col-span-1 transition-opacity duration-150 ease-linear md:col-span-6">
            {formLoaded ? <Formik
                enableReinitialize={true}
                initialValues={{
                  family: activeFamily,
                  service: activeService,
                  serviceId: activeTreatment,
                  startDate: finalSelection,
                  terms: false
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  delete values.terms
                  delete values.family
                  delete values.service
                  values.clientId = session.id
                  values.price = price
                  const response = await sessionService.newBooking(values)
                  setSubmitting(false)
                  if (response.valid) {
                    const option = treatmentOptions.find(option => option.id === values.serviceId)
                    if (typeof option === "object") {
                      values.title = option.title
                    } else throw new Error("error on bookingform find result. Should be an object")
                    values.bookingId = Number(response.bookingId)
                    values.price = response.price
                    navigate(ctaHref, { state: values })
                  }
                }}
              >
                {({values, setFieldValue}) => {
                return (
                  <Form className="grid grid-cols-4 md:grid-cols-6 md:gap-6 lg:gap-5">
                    <div className="col-span-4 mb-4 md:col-span-3 md:mb-0">
                      <Label title={familyTitle} />
                      <FormField
                        type="select"
                        name="family"
                        options={familyOptions}
                        placeholder="Selecciona una opción"
                        onChangeCallback={value => {
                          setActiveFamily(value)
                          getServicesOptions(value)
                          getTreatmentOptions()
                          setFieldValue("family", value)
                        }}
                        required={true}
                      />
                    </div>
                    <div className="col-span-4 mb-4 md:col-span-3 md:mb-0">
                      <Label title={serviceTitle} />
                      <FormField
                        type="select"
                        name="service"
                        options={serviceOptions}
                        placeholder="Selecciona una opción"
                        onChangeCallback={value => {
                          setActiveService(value)
                          getTreatmentOptions(value)
                          setFieldValue("service", value)
                        }}
                        required={true}
                      />
                    </div>
                    <div className="col-span-4 mb-4 md:col-span-3 md:mb-0">
                      <Label title={treatmentTitle} />
                      <FormField
                        type="select"
                        name="serviceId"
                        options={treatmentOptions}
                        placeholder="Selecciona una opción"
                        required={true}
                        onChangeCallback={value => {
                          setActiveTreatment(value)
                          setFieldValue("serviceId", value)
                        }}
                      />
                    </div>
                    <div className="col-span-4 mb-3 text-center no-underline uppercase md:col-start-1 md:col-span-3 font-secondary text-small">
                      <Label title={calendarTitle} />
                      <Calendar 
                        maxDetail={"month"}
                        minDetail={"month"}
                        locale={"es"}
                        formatShortWeekday={(locale, date) => formatDate(locale, date)}
                        minDate={nextAvailableBookingDate}
                        onClickDay={value => loadAvailability(activeTreatment, moment(value).format("YYYY-MM-DD"))}
                        prevLabel={<Svg name="arrow-left" width={16} height={16} color="gray" />}
                        nextLabel={<Svg name="arrow-right" width={16} height={16} color="gray" />}
                      />
                    </div>
                    <div key="item-list" className="flex items-start justify-center col-span-4 mb-3 md:col-span-3 md:mt-6">
                      {loadingHours ? <div className="flex items-center justify-center h-full transition-opacity duration-150 ease-linear max-h-270px">
                          <div className="mt-8 loader small md:mt-0"></div>
                        </div>
                      : <div className="w-full my-6 transition-opacity duration-150 ease-linear md:ml-4 md:my-0">
                          {availableHours && availableHours.length > 0 ? <>
                            <Label title={hourTitle} />
                            <div className="overflow-x-auto max-h-270px">
                              {availableHours.map(item =>
                                  <a
                                    href="#"
                                    key={item.bookingStartDate}
                                    className="flex items-center justify-between py-4 pr-3 mb-1 border-b border-gray font-secondary text-gray"
                                    onClick={(event) => {
                                      event.preventDefault()
                                      setFinalSelection(item.bookingStartDate)
                                      setPrice(item.price)
                                    }}
                                  >
                                    <div key="text-wrapper" className="flex items-center justify-start w-full px-3">
                                      <span className="w-1/2 pr-4">{`${moment(item.bookingStartDate).format("HH:mm")} h`}</span>
                                      <span className="w-1/2 pr-4">{item.duration}&apos;</span>
                                    </div>
                                    <div className={`flex-none rounded-full border-gray transition-all duration-100 ease-in-out w-4 h-4 ${item.bookingStartDate === finalSelection ? "border-8" : "border"}`}/>
                                  </a>  
                                )
                              }
                            </div>
                          </>
                          : <div className="text-center mt-8 md:mt-40% font-secondary">{hoursLoaded ? noResultsMessage : selectDateMessage}</div>}
                        </div>}
                    </div>
                    <div className="flex items-center justify-start col-span-6 mt-5 text-gray text-tiny">
                      <FormCheckbox 
                        name="terms"
                        value={values.terms}
                        required={true}
                        isChecked={values.terms === true}
                        handleCheckbox={(value) => setFieldValue("terms", value)}
                      />
                      <HTMLText text={terms} className="font-secondary" />
                    </div>
                    <div className="col-span-4 mt-8 md:col-start-1 md:col-span-2 md:mt-8 lg:mt-6">
                      {/* <div className={`transition-opacity duration-150 ease-linear ${finalSelection && finalSelection !== '' ? "opacity-100" : "opacity-25 pointer-events-none"}`}> */}
                      {/* PROVISIONAL */}
                      <div className="transition-opacity duration-150 ease-linear opacity-25 pointer-events-none">
                        <Button 
                          cta={{ label: ctaLabel, href: ctaHref }} 
                          // submitButton={finalSelection && finalSelection !== ''} 
                          submitButton={false} // PROVISIONAL
                          disabled={true} 
                          darkMode={true} 
                        />
                      </div>
                    </div>
                    {/* PROVISIONAL */}
                    <span className="self-center col-span-4 mt-8 text-center md:text-left lg:mt-6 font-secondary">Las reservas están deshabilitadas temporalmente.</span>
                  </Form>
                )}}
              </Formik>
              : <div className="flex items-center justify-center col-span-6">
                <div className="loader"></div>
              </div>
            }
          </Appear>
        </div>
      </ContainerGrid>
    </Appear>
  )
}

export default BookingForm

// @flow

import React, { useState, useEffect } from 'react'
import Appear from './Appear'
import type { Image } from 'types/'
import ContainerGrid from './ContainerGrid'
import Button from './Button'
import ButtonSecondary from './ButtonSecondary'
import DropCapText from './DropCapText'
import useIsMobile from '../hooks/useIsMobile'
import ImageLazy from './ImageLazy'

type Props = {
  title: string,
  text: string,
  image: Image,
  list?: Array<string>,
  ctaLabel: string,
  ctaHref: string,
  ctaMobileLabel: string,
  margins?: string,
}

const HeadingAbout = ({ title, text, image, list, ctaLabel, ctaHref, ctaMobileLabel, margins }: Props) => {
  const isMobile = useIsMobile()
  const [windowHeight, setWindowHeight] = useState()
  const [textSize, setTextSize] = useState(100)
  const [marginBottom, setMarginBottom] = useState('mb-20')
  const [marginTop, setMarginTop] = useState('md:mt-36')

  const handleResize = () => {
    const height = document.documentElement.clientHeight || document.body.clientHeight
    setWindowHeight(height)
  }

  const handleTitleStyleDesktop = () => {
    if  (windowHeight) {
      if (windowHeight > 800) {
        setTextSize(100)
        setMarginBottom('mb-20')
        setMarginTop('md:mt-36')
      }
      if (windowHeight < 800) {
        setTextSize(90)
        setMarginBottom('mb-16')
        setMarginTop('md:mt-28')
      }
      if (windowHeight < 700) {
        setTextSize(80)
        setMarginBottom('mb-12')
        setMarginTop('md:mt-24')
      }
      if (windowHeight < 600) {
        setTextSize(70)
        setMarginBottom('mb-10')
        setMarginTop('md:mt-20')
      }
      if (windowHeight < 500) {
        setTextSize(60)
        setMarginBottom('mb-8')
        setMarginTop('md:mt-16')
      }
      if (windowHeight < 400) {
        setTextSize(50)
        setMarginBottom('mb-8')
        setMarginTop('md:mt-12')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    handleTitleStyleDesktop()
  }, [windowHeight])

  let firstHalfList = []
  let secondHalfList = []
  let shortList = []

if (list && list.length > 0) {
  const halfList = Math.ceil(list.length / 2)
  firstHalfList = list.slice(0, halfList)
  secondHalfList = list.slice(halfList)
  shortList = list.slice(0, 7)
}

  return (
    <Appear>
      <div className={`${margins ? margins : `mt-16 mb-20 ${marginTop} md:mb-56`}`}>
        {isMobile
        ? <ContainerGrid>
          <div className="grid grid-cols-4 gap-4 md:hidden">
            <h1 className="col-span-3 row-start-1 mb-6 leading-none md:mb-8 text-gray font-primary text-big3">{title}</h1>
            <div className="col-span-4 row-start-2">
              <DropCapText text={text} />
            </div>
            {list &&
            <div className="col-span-3 col-start-2 my-4 font-secondary">
              {isMobile
              ? <ul className="ml-4 list-disc list-outside md:hidden">
                {shortList.map((item, index) => <li
                key={index}
                className={`${index + 1 === shortList.length ? "" : "pb-2"} text-gray text-secondary text-small`}
                >{item}</li>)}
              </ul>
              : <div className="items-start justify-start hidden md:flex">
                <ul className="w-1/2 h-auto ml-4 list-disc list-outside">
                  {firstHalfList.map((item, index) => <li
                  key={index}
                  className="pb-2 text-gray text-secondary text-normal"
                  >{item}</li>)}
                </ul>
                <ul className="w-1/2 h-auto ml-12 list-disc list-outside">
                  {secondHalfList.map((item, index) => <li
                  key={index}
                  className="pb-2 text-gray text-secondary text-normal"
                  >{item}</li>)}
                </ul>
              </div>
              }
            </div>
            }
            <div className="col-span-3 col-start-2">
              <ButtonSecondary cta={{ href: ctaHref, label: ctaMobileLabel }} internalLink={true} />
            </div>
            <div className="col-span-4 mt-6">
              <Button cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} internalLink={true} />
            </div>
          </div>
        </ContainerGrid>
        : <ContainerGrid mdCleanLeft lgCleanLeft>
          <div className="hidden grid-cols-11 gap-6 ml-6 md:grid lg:ml-8 lg:gap-8">
            <h1
              className={`col-span-4 col-start-2 row-start-1 ${marginBottom} leading-none text-gray font-primary`}
              style={{ fontSize: textSize }}
            >{title}</h1>
            <div className="col-span-5 col-start-1 row-start-2">
              <DropCapText text={text} />
              {list &&
              <div className="ml-20% mt-12 mb-4">
                {isMobile
                ? <ul className="ml-4 list-disc list-outside md:hidden">
                  {shortList.map((item, index) => <li
                  key={index}
                  className="pb-2 text-gray font-secondary text-small"
                  >{item}</li>)}
                </ul>
                : <div className="items-start justify-start hidden md:flex">
                  <ul className="w-1/2 h-auto ml-4 list-disc list-outside">
                    {firstHalfList.map((item, index) => <li
                    key={index}
                    className="pb-2 text-gray font-secondary text-normal"
                    >{item}</li>)}
                  </ul>
                  <ul className="w-1/2 h-auto ml-12 list-disc list-outside">
                    {secondHalfList.map((item, index) => <li
                    key={index}
                    className="pb-2 text-gray font-secondary text-normal"
                    >{item}</li>)}
                  </ul>
                </div>
                }
              </div>
              }
              <div className="mt-16 ml-20% w-3/5">
                <Button cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} internalLink={true} />
              </div>
            </div>
            <div className="col-span-5 col-start-7 row-start-2">
              {image && <Appear>
                <ImageLazy className="w-full h-auto" image={image} alt="" />
              </Appear>}
            </div>
          </div>
        </ContainerGrid>
        }
      </div>
    </Appear>
  )
}

export default HeadingAbout

// @flow

import React from 'react'
import Appear from './Appear'
import ContainerGrid from './ContainerGrid'
import type { Image } from 'types/'
import Button from './Button'
import ButtonSecondary from './ButtonSecondary'
import useIsMobile from '../hooks/useIsMobile'
import ImageLazy from './ImageLazy'
import HTMLText from './HTMLText'

type FirstBlock = {
  text: string,
  ctaLabel: string,
  ctaHref: string,
}
type Block = {
  title: string,
  text: string,
}
type Blocks = {
  one?: FirstBlock,
  two?: Block,
  three?: Block,
  four?: Block,
  five?: Block,
}
type Props = {
  title?: string,
  image: Image,
  ctaLabel: string,
  ctaHref: string,
  blocks: Blocks,
  pageName?: string
}

const Contact = ({ title, image, ctaLabel, ctaHref, blocks, pageName }: Props) => {
  const isMobile = useIsMobile()

  const customMargins = pageName === "contact" ? "mb-24 md:mb-72" : "mt-32 mb-8 md:mt-80 md:mb-64"

  return (
    <Appear>
      <div className={customMargins}>
        <ContainerGrid>
          {isMobile
          ? <div className="grid grid-cols-4 gap-4 md:hidden">
              {pageName !== "contact" &&
                <div className="col-span-3 col-start-1">
                  {title && <span className="text-gray font-primary text-big3">{title}</span>}
                </div>
              }
              {blocks.one && <div className="col-span-3 col-start-2">
                <HTMLText className={`${pageName !== "contact" ? "mt-6" : ""} mb-4 md:mt-8 text-gray font-secondary text-small`} text={blocks.one.text} />
                <div>
                  <ButtonSecondary cta={{ label: blocks.one.ctaLabel, href: blocks.one.ctaHref }} openNewWindow={true} />
                </div>
              </div>}
              {blocks.two && <div className="col-span-3 col-start-2">
                <div className="my-4 text-gray font-primary text-bigger1">{blocks.two.title}</div>
                <HTMLText className="text-gray font-secondary text-small" text={blocks.two.text} />
              </div>}
              {blocks.three && <div className="col-span-3 col-start-2">
                <div className="my-4 text-gray font-primary text-bigger1">{blocks.three.title}</div>
                <HTMLText className="text-gray font-secondary text-small" text={blocks.three.text} />
              </div>}
              {blocks.four && <div className="col-span-3 col-start-2">
                <div className="my-4 text-gray font-primary text-bigger1">{blocks.four.title}</div>
                <HTMLText className="text-gray font-secondary text-small" text={blocks.four.text} />
              </div>}
              {blocks.five && <div className="col-span-3 col-start-2">
                <div className="my-4 text-gray font-primary text-bigger1">{blocks.five.title}</div>
                <HTMLText className="text-gray font-secondary text-small" text={blocks.five.text} />
              </div>}
              {(ctaLabel && pageName !== "contact") && <div className="col-span-4 mt-6 md:mt-12">
                <Button cta={{ label: ctaLabel, href: ctaHref}} darkMode={true} internalLink={true} />
              </div>}
            </div>
            : <div className="hidden grid-cols-10 gap-6 md:grid lg:gap-8">
              <div className="col-span-5 col-start-1 row-start-1">
                <Appear>
                  {blocks.one ? (
                    <a href={blocks.one.ctaHref} target="_blank" rel="noopener noreferrer">
                      <ImageLazy className="w-full h-auto" image={image} alt="" imgClassName="cursor-pointer" />
                    </a>
                  ) : (
                    <ImageLazy className="w-full h-auto" image={image} alt="" imgClassName="cursor-pointer" />
                  )}
                </Appear>
              </div>
              <div className="col-span-4 col-start-7 row-start-1">
                {(title && pageName !== "contact") && <div className="mb-10 text-gray font-primary text-huge1">{title}</div>}
                <div className="flex items-start justify-start">
                  <div className="w-1/2 mr-6">
                    {blocks.one && <div className="mb-6">
                      <HTMLText className="text-gray font-secondary text-normal" text={blocks.one.text}/>
                      <ButtonSecondary cta={{ label: blocks.one.ctaLabel, href: blocks.one.ctaHref }} openNewWindow={true} />
                    </div>}
                    {blocks.two && <div>
                      <div className="mb-4 text-gray font-primary text-bigger2">{blocks.two.title}</div>
                      <HTMLText className="mb-12 text-gray font-secondary text-normal" text={blocks.two.text}/>
                    </div>}
                    {(ctaLabel && pageName !== "contact") && <Button cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} internalLink={true} />}
                  </div>
                  <div className="w-1/2 ml-6">
                    {blocks.three && <div>
                      <div className="mb-4 text-gray font-primary text-bigger2">{blocks.three.title}</div>
                      <HTMLText className="mb-6 text-gray font-secondary text-normal" text={blocks.three.text}/>
                    </div>}
                    {blocks.four && <div>
                      <div className="mb-4 text-gray font-primary text-bigger2">{blocks.four.title}</div>
                      <HTMLText className="mb-6 text-gray font-secondary text-normal" text={blocks.four.text}/>
                    </div>}
                    {blocks.five && <div>
                      <div className="mb-4 text-gray font-primary text-bigger2">{blocks.five.title}</div>
                      <HTMLText className="mb-6 text-gray font-secondary text-normal" text={blocks.five.text}/>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          }
        </ContainerGrid>
      </div>
    </Appear>
  )
}

export default Contact

const fetch = require('node-fetch')
const { processAll } = require('../../prebuild/process-images')

// PROVISIONAL DEV
// const GATSBY_baseURL = 'https://madridmanager.arsenalbarcelona.com/api/public/api'
// const GATSBY_configEndpoint = 'config'

const buildUrl = (endpoint, params, language = false) => {
  const strParams = Object.entries(params)
    .map(
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
    )
    .join('&')
  return `${process.env.GATSBY_baseURL}${endpoint}?${strParams}${language ? `&language=${encodeURIComponent(language)}` : ''}`
  // return `${GATSBY_baseURL}${endpoint}?${strParams}${language ? `&language=${encodeURIComponent(language)}` : ''}`
}

const getBuildConfig = async () => {
  const finalUrl = `${process.env.GATSBY_baseURL}/${process.env.GATSBY_configEndpoint}?cache=0&env=default&now=${Date.now()}`
  // const finalUrl = `${GATSBY_baseURL}/${GATSBY_configEndpoint}?cache=0&env=default&now=${Date.now()}`
  try {
    const buildConfig = await fetch(finalUrl)
    return buildConfig.json()
  } catch (err) {
    console.log(err)
    throw new Error('Something went wrong getting the build config', err)
  }
}

const getPage = async (endpoint, params, language) => {
  const apiURL = buildUrl(endpoint, params, language)
  const buildInfo = {
    endpoint,
    params,
  }
  try {
    const rawData = await fetch(apiURL)
    let data = await rawData.json()
    if (Array.isArray(data)) {
      const promises = await data.map(async item => {
        item.buildInfo = buildInfo
        item.structure = await processAll(item.structure)
        item.path = Array.isArray(item.path) ? item.path : [item.path]
        return item
      })
      return Promise.all(promises)
    } else {
      data.buildInfo = buildInfo
      data.structure = await processAll(data.structure)
      data.path = Array.isArray(data.path) ? data.path : [data.path]
      return new Promise((resolve) => resolve(data))
    }
  } catch (err) {
    throw new Error(
      `Something went wrong on ${endpoint}?language=${language}`,
      err
    )
  }
}


module.exports = {
  buildUrl,
  getBuildConfig,
  getPage,
}
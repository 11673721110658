// @flow

import React from 'react'
import Button from './Button'
import Appear from './Appear'

type Props = {
  title: string,
  error: string,
  text: string,
  ctaLabel: string,
  ctaHref: string
  
}

const Error404 = ({ title, error, text, ctaLabel, ctaHref }: Props) => {

  return (
    <Appear>
      <div className="grid grid-cols-4 gap-4 mx-4 mt-16 md:grid-cols-12 md:gap-6 lg:gap-8 md:mx-6 lg:mx-8 mb-60 md:mt-32 md:mb-100">
        <h1 className="col-span-4 col-start-1 leading-none text-center md:col-start-4 md:col-span-6 text-gray font-primary text-big3 md:text-huge3">{title}</h1>
        <div className="mx-20% md:mx-0 col-start-1 col-span-4 md:col-start-5 md:col-span-4 text-gray font-secondary text-small md:text-normal my-8 md:my-10">
          <div className="text-center">{error}</div>
          <div className="text-center">{text}</div>
        </div>
        <div className="col-span-4 col-start-1 md:col-start-6 md:col-span-2">
          <Button cta={{ label: ctaLabel, href: ctaHref }} darkMode={true} internalLink={true} />
        </div>
      </div>
    </Appear>
  )
}

export default Error404

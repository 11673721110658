// @flow

import React, { useState, useEffect } from 'react'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import Svg from './Svg'

type Props = {
  items: Array<Object>
}

const SliderTestimonialsMobile = ({ items }: Props) => {
  const [swiper, setSwiper] = useState(null)
  const [active, setActive] = useState(0)

  useEffect(() => {
  }, [active])

  const params: any = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    slidesPerView: 'auto',
    centeredSlides: true,
    speed: 1000,
    watchSlidesVisibility: true,
    updateOnWindowResize: true,
    runCallbacksOnInit: true,
    onInit: (swiper) => {setSwiper(swiper)},
  }

  const prevItem = () => {
    if (swiper !== null) {
      swiper.slidePrev()
      setActive(swiper.realIndex)
    }
  }

  const nextItem = () => {
    if (swiper !== null) {
      swiper.slideNext()
      setActive(swiper.realIndex)
    }
  }

  return (
    <>
      <Swiper
        {...params}
        slideClass="swiper-slide-testimonial"
        containerClass="swiper-container-testimonials"
        slideActiveClass="swiper-slide-testimonial-active"
        ref={node => {node ? setSwiper(node.swiper) : setSwiper(null)}}
      >
      {items.map(item => <div
          key={item.id}
          className="md:px-3"
        >
          <div className="h-full px-12 py-10 border md:py-12 md:px-10 border-gray font-secondary">
            <p className="mb-8 leading-tight md:mb-12 font-primary text-bigger2 text-gray">{`“${item.text}”`}</p>
            <span className="block leading-snug font-secondary text-gray text-small md:text-normal">{item.author}</span>
          </div>
        </div>)}
      </Swiper>
      <div className="relative z-10 flex justify-between -mt-8">
        <div
        onClick={prevItem}
        className="px-4"
        >
          <Svg name="arrow-left" width={12} height={20} color="gray" />
        </div>
        <div
        onClick={nextItem}
        className="px-4"
        >
          <Svg name="arrow-right" width={12} height={20} color="gray" />
        </div>
      </div>
    </>
  )
}

export default SliderTestimonialsMobile
